<script>
  import { account } from './../../stores/Account.js'
  import Button from '../../components/buttons/Button.svelte'
  import FormPanel from '../../components/form/FormPanel.svelte'
  import Input from '../../components/form/Input.svelte'
  import InlineNotification from '../../components/notifications/InlineNotification.svelte'
  import Main from '../../layout/Main.svelte'
  import { kycContracts, uuidToBytes, tierReport, getContractMessageTranslation } from '../../utils/kyc'
  import { provider, signer } from '../../stores/Account'
  import { ethers } from 'ethers'

  let approveAddress = ''
  let banAddress = ''
  let removeAddress = ''
  let reportAddress = ''

  let approveStatus, banStatus, removeStatus, reportStatus
  let approveError, banError, removeError, reportError
  let reportResult
  let kycContract, kycTier

  $: if ($account) {
    [kycContract, kycTier] = kycContracts($signer)
  }

  const approve = async () => {
    kycContract
      .approve(approveAddress)
      .then(async (result) => {
        approveStatus = 'tx-verifying'
        await $provider.waitForTransaction(result.hash, 1)
        approveStatus = 'tx-verified'
      })
      .catch((error) => {
        approveError = error.message
        if (error.data) {
          approveError = error.data.message
        }
        approveStatus = 'error'
        approveError = getContractMessageTranslation(approveError);
      })
  }

  const ban = async () => {
    kycContract
      .ban(banAddress)
      .then(async (result) => {
        banStatus = 'tx-verifying'
        await $provider.waitForTransaction(result.hash, 1)
        banStatus = 'tx-verified'
      })
      .catch((error) => {
        banError = error.message
        if (error.data) {
          banError = error.data.message
        }
        banStatus = 'error'
        banError = getContractMessageTranslation(banError);
      })
  }

  const remove = async () => {
    kycContract
      .remove(removeAddress)
      .then(async (result) => {
        removeStatus = 'tx-verifying'
        await $provider.waitForTransaction(result.hash, 1)
        removeStatus = 'tx-verified'
      })
      .catch((error) => {
        removeError = error.message
        if (error.data) {
          removeError = error.data.message
        }
        removeStatus = 'error'
        removeError = getContractMessageTranslation(removeError);
      })
  }

  const report = async () => {
    kycTier
      .report(reportAddress)
      .then(async (result) => {
        if (result.eq(ethers.constants.MaxUint256)) {
          reportResult = 'Address not added, not approved, banned or removed.'
        } else {
          const report = tierReport(result.toHexString())
          console.log(report)
          const {timestamp} = await $provider.getBlock(report[0])
          // console.log(await $provider.getBlock(report[0]))
          console.log(timestamp)
          reportResult = 'Approved since: ' + new Date(timestamp * 1000)
        }
      })
      .catch((error) => {
        reportError = error.message
        if (error.data) {
          reportError = error.data.message
        }
        reportStatus = 'error'
        reportError = getContractMessageTranslation(reportError);
      })
  }
</script>

<div class="w-full grid grid-cols-1 gap-4 mt-10">
  <FormPanel heading="Approve session ID">
    <Input type="text" bind:value={approveAddress}>
      <span slot="label">
        Enter a wallet address to approve.
      </span>
    </Input>
    {#if approveStatus == 'tx-verifying'}
      <Button disabled={true} shrink="true">Verifying transaction...</Button>
    {:else}
      <Button shrink="true" on:click={approve}>Approve</Button>
    {/if}
    {#if approveError}
      <InlineNotification type="error">{approveError}</InlineNotification>
    {/if}
    {#if approveStatus == 'tx-verified'}
      <InlineNotification type="warning">Approved!</InlineNotification>
    {/if}
  </FormPanel>

  <FormPanel heading="Ban session ID">
    <Input type="text" bind:value={banAddress}>
      <span slot="label">
        Enter a wallet address to ban.
      </span>
    </Input>
    {#if banStatus == 'tx-verifying'}
      <Button disabled={true} shrink="true">Verifying transaction...</Button>
    {:else}
      <Button shrink="true" on:click={ban}>Ban</Button>
    {/if}
    {#if banError}
      <InlineNotification type="error">{banError}</InlineNotification>
    {/if}
  </FormPanel>

  <FormPanel heading="Remove KYC">
    <Input type="text" bind:value={removeAddress}>
      <span slot="label">Enter a wallet address to remove.</span>
    </Input>
    {#if removeStatus == 'tx-verifying'}
      <Button disabled={true} shrink="true">Verifying transaction...</Button>
    {:else}
      <Button shrink="true" on:click={remove}>Remove</Button>
    {/if}
    {#if removeError}
      <InlineNotification type="error">{removeError}</InlineNotification>
    {/if}
  </FormPanel>

  <FormPanel heading="Report KYC">
    <Input type="text" bind:value={reportAddress}>
      <span slot="label">Enter an address to check</span>
    </Input>
    {#if reportStatus == 'tx-verifying'}
      <Button disabled={true} shrink="true">Verifying transaction...</Button>
    {:else}
      <Button shrink="true" on:click={report}>Report</Button>
    {/if}
    {#if reportError}
      <InlineNotification type="error">{reportError}</InlineNotification>
    {/if}
    {#if reportResult}
      <InlineNotification type="warning">{reportResult}</InlineNotification>
    {/if}
  </FormPanel>
</div>
