<script>
import { getContext } from 'svelte'

// modules

import { ethers, utils } from 'ethers'
import { initClient, operationStore, query } from '@urql/svelte'

// util
import { CurrencyAmount, Price, Token } from '@uniswap/sdk-core'

// abi
import RESERVE_TOKEN from '../../abi/ERC20.json'

// stores
import { provider, account } from '../Account.js'
import { tick } from '../Tick'
import { constants } from '../../constants/Constants'

import { BN } from '../../utils/utils';

// stores
$: ({
    contractsReadOnly : {
        trust: trust,
        redeemableToken: redeemableToken,
        reserveToken: reserveToken,
    },
    redeemBalances,
    redeemAddresses,
    redeemTokens,
    myRedeemBalances,
    redeemCurrencies,
    redeemableSupply,
    redeemShares,
    getRedeemables,
    creator,
    redeemableCurrency
} = getContext('trustStores'))


const getAddress = ethers.utils.getAddress

const client = initClient({
  url: constants.GRAPH_API_URL,
});


let trustAddress, callers
let treasuryAssets = operationStore(`
query ($trustAddress: Bytes!, $callers: [Bytes]!) {
  treasuryAssetCallers(where: {trustAddress: $trustAddress, caller_in: $callers}) {
    caller
    trustAddress
    treasuryAsset {
      address
      decimals
      name
      symbol
      balance
      redemptionRatio
      redeems {
        caller
        treasuryAssetAmount
      }
    }
  }
}
`,
{trustAddress, callers},
{
    pause: true,
    requestPolicy: "network-only"
})


query(treasuryAssets)

$: if ($trust.address && $creator && $tick) {
    _getTreasuryAssets()
}

$: $getRedeemables = _getTreasuryAssets

function _getTreasuryAssets() {
    $treasuryAssets.variables.trustAddress = $trust.address
    $treasuryAssets.variables.callers = [$creator, $trust.address]
    $treasuryAssets.context.pause = false
    $treasuryAssets.reexecute()
}

$: if ($treasuryAssets.data?.treasuryAssetCallers?.length && $reserveToken && $redeemableCurrency) {
   filterRedeemables()
}

async function filterRedeemables() {
    let uniqueTreasuryAssets = []
    for (const caller of $treasuryAssets.data.treasuryAssetCallers) {
        if (!uniqueTreasuryAssets.find(asset => asset.address == caller.treasuryAsset.address)) {
            uniqueTreasuryAssets.push(caller.treasuryAsset)
        }
    }

    uniqueTreasuryAssets = uniqueTreasuryAssets.filter(asset => parseFloat(asset.balance))

    $redeemAddresses = uniqueTreasuryAssets.map(asset => asset.address)

    $redeemTokens = $redeemAddresses.map((address) => {
        return new ethers.Contract(address, RESERVE_TOKEN.abi).connect($provider)
    })

    $redeemCurrencies = uniqueTreasuryAssets.map((token)=>{
        return new Token(constants.CHAIN_ID, token.address, token.decimals, token.symbol, token.name)
    })

    $redeemBalances = uniqueTreasuryAssets.map((token, index) => {
        return CurrencyAmount.fromRawAmount($redeemCurrencies[index], BN.from(token.balance))
    })

    $redeemShares = uniqueTreasuryAssets.map((token, index) => {
        return new Price(
            {
                baseAmount: CurrencyAmount.fromRawAmount($redeemableCurrency, BN.from(1)), 
                quoteAmount: CurrencyAmount.fromFractionalAmount($redeemCurrencies[index], BN.from(token.redemptionRatio), BN.from(10).pow(18))
            })
    })
}

$: if ($redeemTokens && $redeemCurrencies && $account && $tick) {
    getMyRedeemBalances()
}

async function getMyRedeemBalances() {
    $myRedeemBalances = await Promise.all($redeemTokens.map(async (token, index) => {
        return CurrencyAmount.fromRawAmount($redeemCurrencies[index], await token.balanceOf($account))
    }))
}

</script>