<script>
import GenericSkeletonLoader from './../../../components/loader/GenericSkeletonLoader.svelte'
import { getContext } from 'svelte'

let stores = getContext('trustStores')
let metadata = stores.metadata
</script>

<GenericSkeletonLoader show={$metadata?.name}>
    <span class="text-2xl font-semibold text-gray-900">
        {$metadata?.name}
    </span>
</GenericSkeletonLoader>
