<script>
import { getContext } from 'svelte'
import User from '../../../components/user/User.svelte'

let stores = getContext('trustStores')
$: ({
    creator,
    metadata
} = stores)

</script>


<User name={$metadata?.creator} avatar={$metadata?.creatorAvatar} address={$creator} label="CREATOR"/>
