import merge from 'deepmerge';
import * as defaults from './defaults.ts';
import * as polygen from './polygen.ts';
import * as polygenProd from './polygen-prod.ts';
import * as horse from './horse.ts';
import * as polinate from './polinate.ts';
import * as polinateProd from './polinate-prod.ts';
import * as commgen from './commgen.ts';
import * as goldfinch from './goldfinch.ts';
import * as ff3 from './ff3-prod.ts';
const sites = {
    defaults,
    polygen,
    polygenProd,
    horse,
    polinate,
    commgen,
    goldfinch,
    polinateProd,
    ff3
};
export const constants = process.env.SITE ? merge(defaults, sites[process.env.SITE]) : defaults;
