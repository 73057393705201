<script>
import PageWidth from "../PageWidth.svelte";


</script>
    
<div class="bg-gray-100">
<PageWidth>
    <slot></slot>
</PageWidth>
</div>