<script>
import marked from 'marked'
import GenericSkeletonLoader from '../../../components/loader/GenericSkeletonLoader.svelte'

let descMd, descHtml
export let src

$: fetch(src).then(r=>r.text()).then(data => {
    descMd = data
})

$: if (descMd) { descHtml = marked(descMd) }

</script>

<GenericSkeletonLoader show={descHtml}>
    <div class="prose m-auto max-w-none mt-4">
        {@html descHtml}
    </div>
</GenericSkeletonLoader>