<script>
import StatRow from './../../../components/stats/StatRow.svelte';
import { CurrencyAmount, Price } from '@uniswap/sdk-core';
import { account, signer } from './../../../stores/Account.js'

// svelte
import { getContext } from 'svelte'
import Modal from '../../../components/modals/Modal.svelte';
import ConfirmRedeemSeed from './ConfirmRedeemSeed.svelte';
import Button from '../../../components/buttons/Button.svelte';
import GenericSkeletonLoader from '../../../components/loader/GenericSkeletonLoader.svelte'


$: ({
    contractsWithSigner : {
            seeder : seederContract
    },
    seederUnitSupply,
    seederBalance,
    seederCurrency,
    seedERC20Balance,
    projectState,
    seedFeePerUnit,
    seederUnitPrice,
    reserveCurrency,
    seederUnitSupply
} = getContext('trustStores'))

let totalRefund, modal, showPanel

$: if ($signer && $seederContract && $seederCurrency && $seederUnitSupply && $seedERC20Balance) { getSeedInfo() }

const getSeedInfo = async () => {
    // if there are no seed tokens left in supply, avoid dividing by zero
    if (parseFloat($seederUnitSupply.toExact())) {
        const pricePerSeedToken = new Price({baseAmount: $seederUnitSupply, quoteAmount: $seederBalance})
        totalRefund = pricePerSeedToken.quote($seedERC20Balance)
    }
    showPanel = true
}

</script>

{#if parseFloat($seedERC20Balance?.toExact())}
<div class="bg-white p-5 shadow-md border border-gray-200 relative rounded-2xl flex flex-col gap-y-4">
    <div class="text-xl font-semibold text-gray-800">Redeem seed tokens</div>
    <div class="text-sm">
        {#if $projectState == 4}
            Redeem your seed tokens to receive a refund, plus the seed fee.
        {:else if $projectState == 5}
            This raise failed, but you can redeem your seed tokens to refund the amount seeded.
        {/if}
    </div>
    <GenericSkeletonLoader show={showPanel}>
        <div class="flex flex-col gap-y-4">
            <div class="space-y-2">
                <StatRow label="Seed token balance" value="{$seedERC20Balance?.toSignificant(4)}"/>
                <StatRow label="Seed amount per token" value="{$seederUnitPrice?.toSignificant(8)} {$reserveCurrency.symbol}"/>
                {#if $projectState == 4}
                <StatRow label="Seed fee per token" value="{$seedFeePerUnit?.toSignificant(8)} {$reserveCurrency.symbol}"/>
                {/if}
            </div>
            <div class="border-t border-gray-200 pt-2">
                <StatRow label="Total refund (less dust)" value="{totalRefund?.toSignificant(8)} {$reserveCurrency.symbol}"/>
            </div>
            <Button on:click={()=>{modal = true}}>Redeem</Button>
        </div>
    </GenericSkeletonLoader>
</div>
{/if}


<!-- Redeem confirmation modal -->
{#if modal}
<Modal on:close={()=>{modal = false}}>
    <ConfirmRedeemSeed
        on:close={()=>{modal = false}}
        tokenAmt={$seedERC20Balance}
        tokenAmtDisplay={$seedERC20Balance.toSignificant(4)}
        {totalRefund}
    />
</Modal>
{/if}