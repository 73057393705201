<script>
    import SubHeader from './../../layout/header/SubHeader.svelte';
    import Main from '../../layout/Main.svelte'
    import {constants} from '../../constants/Constants'

    </script>
    
    <SubHeader>
        <div class="w-full pt-12">
            <div class="text-4xl font-medium text-gray-900 pb-8">
                User Guide
            </div>
            {#if constants.HEADER_CONTENT?.guide}
                <div class="text-1xl font-regular text-gray-700 pb-8 prose">
                    {constants.HEADER_CONTENT.guide}
                </div> 
            {/if}
        </div>
    </SubHeader>
    
    <Main>
        <div style="text-align: left;">
            <br/>
            <div>
                Provided below is the link to our Gitbook where you can access useful information about our project, our Litepaper, the KYC process and steps for participating in a Polygen IDO.
                <br/><br/>
                <a href="https://polygen.gitbook.io/polygen-launchpad" target="_blank" style="text-decoration: underline;">Click here to access our Gitbook</a>
            </div>
        </div>
    </Main>
    