import "constants";
import "ethers";
import 'tailwindcss/colors';
// factory address is to be updated
//export const FACTORY_ADDRESS = '0x8f72c8dd9a16a86519497d57fa7123268f95d9d1' // factory - old with KYC sell back issue
//export const FACTORY_ADDRESS = '0xaDb1efb43D0e5Dad2BA68F3750652493e1Ca8859' // new factory - with fix for KYC users to sell back rTKN
export const FACTORY_ADDRESS = '0x19B5aCe714b7231Fa6596e25e4E15CcDc5dc09eA'; // new factory - with fix for Balancer issue - check on token balance on exit
// graphQL api
//export const GRAPH_API_URL = "https://api.thegraph.com/subgraphs/name/beehive-innovation/rain-protocol-staging"
export const GRAPH_API_URL = "https://api.thegraph.com/subgraphs/name/beehive-innovation/rain-protocol";
// kyc
export const VERIFY_ADDRESS = '0xd0ce9e4cb06d63cb02a9a71a070d3b0be7db94d1'; // admin - 0x6646Bf1b4538E5124c96BF82f3f533bD64FFc1E2
export const VERIFY_TIER_DEFAULT = '0xa1a36d3537bbe375cc9694795f663ddc8d516db9'; // Polinate Social Hive Tier Contract
// tier
export const TIER_DEFAULT = '0xa1a36d3537bbe375cc9694795f663ddc8d516db9'; // Polinate Social Hive Tier Contract
export const MIN_TIER_MESSAGE = 'You need to be part of the Polinate Social Hive with X tier to participate in this raise';
// block explorer base path
export const BLOCK_EXPLORER_TRANSACTION = 'https://polygonscan.com/tx/';
export const BLOCK_EXPLORER_ADDRESS = 'https://polygonscan.com/address/';
export const RESERVE_DEFAULT = '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'; // USDC
// chain
export const CHAIN_ID = 137; // mumbai
export const NETWORK_NAME = "Matic";
// rpc
export const PROVIDER_RPC = 'https://polygon-mainnet.g.alchemy.com/v2/yporAKUGL4p8k-6Q84oqf06oeTSGWQx9';
// covalent api key
export const COVALENT_KEY = 'ckey_7e3942e77eb947f7ab1ad48a3ca';
export const PAGE_LENGTH = 999999; // historical log pagination - number of blocks
// prestige - for v1 audited trusts
export const PRESTIGE_ADDRESS = '0xb09AEe3fECd81fd90876dE4abDE988806c308EFA';
export const logo = "./images/polinate/polinate-logo-beta.svg";
export const logoReversed = "./images/polinate/polinate-logo.svg";
export const favicon = "./images/polinate/favicon.png";
export const webclip = "./images/polinate/webclip.png";
export const siteName = "Polinate";
export const siteSub = "";
export const KYC_BASE_URL = 'https://synaps-proxy-polygen.jupiter7108.workers.dev/';
export const KYC_CLIENT_NAME = 'polinate'; // 
export const KYC_ENABLED = false;
export const KYC_ENVIRONMENT = 'production'; // sandbox|production
export const KYC_LOCAL_STORAGE_KEY = 'polinate';
//export const curationContent = './curation/polinate-prod.json?v=2'
export const curationContent = 'https://dapp-assets.s3.eu-west-1.amazonaws.com/json_datasrouce/polinate/production/polinate-prod.json';
// theme for tailwind
export const theme = {
    extend: {
        colors: {
            primary: {
                100: '#FEEDD2',
                200: '#FDD391',
                300: '#FCC369',
                400: '#F7B855',
                500: '#FBB03B',
                600: '#F0980F',
                700: '#D4860C',
                800: '#A66A0C',
                900: '#774C09'
            }
        }
    }
};
// header content
export const HEADER_CONTENT = {
    seed: "To seed a pool is to add liquidity to a pool, only once liquidity has been added can the pool then be traded and rewards be purchased.",
    fund: "To fund a pool is to purchase rewards or trade the pTKNs. At the end of the trading period anyone left with the tokens has access to the rewards via their pTKNs.",
    claim: "This is where you can claim your airdrops and rewards. The claim button will highlight when a claim is available."
};
