<script>
    import { CurrencyAmount } from '@uniswap/sdk-core';
    import { ethers } from "ethers"
    import { getContext, onMount, createEventDispatcher } from "svelte"
    
    // components
    import Button from "../../../components/buttons/Button.svelte"
    import InlineNotification from "../../../components/notifications/InlineNotification.svelte"
    import Steps from "../../../components/steps/Steps.svelte"
    import LoadingAnim from "../../../components/util/LoadingAnim.svelte"
    import DisplayToken from "../../../components/tokenfield/DisplayToken.svelte"
    
    // stores
    import { signer, account, provider } from '../../../stores/Account'
    import StatRow from "../../../components/stats/StatRow.svelte"
    import { toRaw } from '../../../utils/utils';
    
    $: ({
        contractsReadOnly : {
            seeder : seederContractReadOnly,
        },
        contractsWithSigner : {
            seeder : seederContract,
        },
        seeder,
        seedTokenBalance,
        seederCurrency
    } = getContext('trustStores'))
    
    // aliasing BigNumber
    const BN = ethers.BigNumber
    
    // calculate the spend
    export let amount
    let spend
    
    let txStatus
    let errorMsg
    let seederAllowance
    
    // close modal event
    const dispatch = createEventDispatcher()
    function closeModal() {
        dispatch('close')
    }
    
    // check the allowance as the modal opens
    onMount( () => {
        checkAllowance()
    })
    
    // async function that checks allowance
    const checkAllowance = async () => {
        seederAllowance = CurrencyAmount.fromRawAmount($seederCurrency, await $seederContract.allowance($account, $seeder))
        txStatus = amount.greaterThan(seederAllowance) ? "exceeds-allowance" : "allowance-ok"
    }
    
    // approve allowance
    async function approve() {
        txStatus = "waiting-on-signature"
        let tx = await $seederContract.approve($seederContract.address, toRaw(amount)).then(async (result)=>{
          txStatus = "verifying"
          await $signer.provider.waitForTransaction(result.hash, 2)
          checkAllowance()
        }).catch(error => {
            errorMsg = error.message
            if (error.data) {errorMsg = error.data.message}
            txStatus = "error"
        })
    }
    
    // confirm seed
    async function confirmSeed() {
        txStatus = "waiting-on-signature"
        let tx = await $seederContract.unseed(toRaw(amount)).then(async (result) => {
            txStatus = "verifying"
            await $provider.waitForTransaction(result.hash, 2)
            // get the updated balance
            $seedTokenBalance = CurrencyAmount.fromRawAmount($seederCurrency, await $seederContractReadOnly.balanceOf($account))
            txStatus = "seed-complete"
            // dispatch a complete event to clear the token field
            dispatch('complete')
        }).catch(error => {
            errorMsg = error.message
            if (error.data) {errorMsg = error.data.message}
            txStatus = "error"
        })
    }
    
    </script>
    
    {#if !txStatus}
    <div class="w-full flex flex-col items-center">
        <LoadingAnim />
        Checking allowance...
      </div>
    {:else if txStatus=="exceeds-allowance"}
        <div class="max-w-md flex flex-col space-y-5">
            <span class="text-lg font-semibold">Confirm seed</span>
            <Steps steps={['Approve', 'Confirm', 'Seed complete']} activeStep=0 />
            <InlineNotification type="warning">
                Your swap exceeds your approved allowance for {$seederCurrency.symbol}
            </InlineNotification>
            <div class="space-y-4 p-4 border border-gray-200 rounded-2xl">
                <StatRow label="Current allowance" value="{seederAllowance.toSignificant(8)}"/>
                <StatRow label="Required allowance" value="{amount.toSignificant(8)} {$seederCurrency.symbol}"/>
            </div>
            <Button on:click={approve} label="Approve"/>
        </div>
    
    
    {:else if txStatus=="allowance-ok"}
        <div class="max-w-md flex flex-col space-y-5">
            <span class="text-lg font-semibold">Confirm refund</span>
            <Steps steps={['Approve', 'Confirm', 'Refund complete']} activeStep=1 />
            <DisplayToken tokenVal={amount.toSignificant(8)} tokenSymbol="seedTKN" label="You are refunding" {amount}/>
            <Button on:click={confirmSeed} label="Confirm transaction" />
        </div>
    
    {:else if txStatus == "waiting-on-signature" || txStatus == "waiting-on-signature-poke"}
      <div class="w-full flex flex-col items-center">
        <LoadingAnim />
        Waiting on signature...
      </div>
    {:else if txStatus == "verifying"}
      <div class="w-full flex flex-col items-center">
        <LoadingAnim />
        Confirming Transaction...
      </div>
    {:else if txStatus == "seed-complete"}
        <div class="max-w-md flex flex-col space-y-4">
            <span class="text-lg font-semibold">Confirm refund</span>
            <Steps steps={['Approve', 'Confirm', 'Seed complete']} activeStep=2 />
            <div class="text-gray-600 font-light pt-5 space-y-4">
                <div>
                    Your transaction has been confirmed!
                </div>
            </div>
            <DisplayToken amount={$seedTokenBalance} tokenVal={$seedTokenBalance.toSignificant(8)} tokenSymbol="seedTKN" label="Your new balance"/>
            <Button on:click={closeModal} label="Ok" />
        </div>
    {:else if txStatus == "error"}
      <span class="tx-lg font-medium">Error</span>
      <InlineNotification type="error">{errorMsg}</InlineNotification>
    {/if}