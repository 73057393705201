<script>
import { CurrencyAmount } from '@uniswap/sdk-core';
import { ethers } from "ethers"
import { getContext, onMount, createEventDispatcher } from "svelte"

// components
import Button from "../../../components/buttons/Button.svelte"
import InlineNotification from "../../../components/notifications/InlineNotification.svelte"
import Steps from "../../../components/steps/Steps.svelte"
import LoadingAnim from "../../../components/util/LoadingAnim.svelte"
import DisplayToken from "../../../components/tokenfield/DisplayToken.svelte"

// stores
import { signer, account, provider } from '../../../stores/Account'
import StatRow from "../../../components/stats/StatRow.svelte"
import { toRaw } from '../../../utils/utils';

$: ({
    contractsReadOnly : {
        seeder : seederContractReadOnly,
        reserveToken : reserveTokenReadOnly,
    },
    contractsWithSigner : {
        reserveToken : reserveToken,
        seeder : seederContract,
    },
    reserveAllowance,
    seederUnitPrice,
    seeder,
    seedTokenBalance,
    reserveCurrency,
    seederCurrency,
    seedFeePerUnit
} = getContext('trustStores'))

// aliasing BigNumber
const BN = ethers.BigNumber

// calculate the spend
export let amount
let spend

let txStatus
let errorMsg

// close modal event
const dispatch = createEventDispatcher()
function closeModal() {
    dispatch('close')
}

// check the allowance as the modal opens
onMount( () => {
    checkAllowance()
})

// async function that checks allowance
const checkAllowance = async () => {
    spend = $seederUnitPrice.quote(amount)
    $reserveAllowance = CurrencyAmount.fromRawAmount($reserveCurrency, await $reserveTokenReadOnly.allowance($account, $seeder))
    txStatus = spend.greaterThan($reserveAllowance) ? "exceeds-allowance" : "allowance-ok"
}

// approve allowance
async function approve() {
    txStatus = "waiting-on-signature"
    let tx = await $reserveToken.approve($seederContract.address, toRaw(spend)).then(async (result)=>{
      txStatus = "verifying"
      await $signer.provider.waitForTransaction(result.hash, 2)
      checkAllowance()
    }).catch(error => {
        errorMsg = error.message
        if (error.data) {errorMsg = error.data.message}
        txStatus = "error"
    })
}

// confirm seed
async function confirmSeed() {
    txStatus = "waiting-on-signature"
    let tx = await $seederContract.seed(toRaw(amount), toRaw(amount)).then(async (result) => {
        txStatus = "verifying"
        await $provider.waitForTransaction(result.hash, 2)
        // get the updated balance
        $seedTokenBalance = CurrencyAmount.fromRawAmount($seederCurrency, await $seederContractReadOnly.balanceOf($account))
        txStatus = "seed-complete"
        // dispatch a complete event to clear the token field
        dispatch('complete')
    }).catch(error => {
        errorMsg = error.message
        if (error.data) {errorMsg = error.data.message}
        txStatus = "error"
    })
}

</script>

{#if !txStatus}
<div class="w-full flex flex-col items-center">
    <LoadingAnim />
    Checking allowance...
  </div>
{:else if txStatus=="exceeds-allowance"}
    <div class="max-w-md flex flex-col space-y-5">
        <span class="text-lg font-semibold">Confirm seed</span>
        <Steps steps={['Approve', 'Confirm', 'Seed complete']} activeStep=0 />
        <!-- <InlineNotification type="warning"> -->
        <span class="my-4">
            Your swap exceeds your approved allowance for {$reserveCurrency.symbol}
        </span>
        <!-- </InlineNotification> -->
        <div class="space-y-4 p-4 border border-gray-200 rounded-2xl">
            <StatRow label="Current allowance" value="{$reserveAllowance.toSignificant(8)}"/>
            <StatRow label="Required allowance" value="{spend.toSignificant(8)} {$reserveCurrency.symbol}"/>
        </div>
        <Button on:click={approve} label="Approve"/>
    </div>


{:else if txStatus=="allowance-ok"}
    <div class="max-w-md flex flex-col space-y-5">
        <span class="text-lg font-semibold">Confirm seed</span>
        <Steps steps={['Approve', 'Confirm', 'Seed complete']} activeStep=1 />
        <DisplayToken tokenVal={amount.toSignificant(8)} tokenSymbol="seedTKN" label="You are buying" {amount}/>
        <div class="space-y-1">
            <StatRow label="Price" value="{spend.toSignificant(8) + ' ' + $reserveCurrency.symbol}"/>
            <StatRow label="Fees after a successful raise" value={($seedFeePerUnit.quote(amount).toSignificant(8)) + ' ' + $reserveCurrency.symbol}/>
        </div>
        <Button on:click={confirmSeed} label="Confirm transaction" />
    </div>

{:else if txStatus == "waiting-on-signature" || txStatus == "waiting-on-signature-poke"}
  <div class="w-full flex flex-col items-center">
    <LoadingAnim />
    Waiting on signature...
  </div>
{:else if txStatus == "verifying"}
  <div class="w-full flex flex-col items-center">
    <LoadingAnim />
    Confirming Transaction...
  </div>
{:else if txStatus == "seed-complete"}
    <div class="max-w-md flex flex-col space-y-4">
        <span class="text-lg font-semibold">Confirm seed</span>
        <Steps steps={['Approve', 'Confirm', 'Seed complete']} activeStep=2 />
        <div class="text-gray-600 font-light pt-5 space-y-4">
            <div>
                Your transaction has been confirmed! After a successful raise you can redeem your tokens for your seed amount plus any seed reward.
            </div>
            <div>
                You can refund these tokens before the raise starts. Your ability to refund will be unlocked in 1d 13hrs 10m 33s.
            </div>
        </div>
        <DisplayToken amount={$seedTokenBalance} tokenVal={$seedTokenBalance.toSignificant(8)} tokenSymbol="seedTKN" label="Your new balance"/>
        <Button on:click={closeModal} label="Ok" />
    </div>
{:else if txStatus == "error"}
  <span class="tx-lg font-medium">Error</span>
  <InlineNotification type="error">{errorMsg}</InlineNotification>
{/if}