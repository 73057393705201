<script>
import { getContext } from "svelte"
import SocialsList from './../../../components/socials/SocialsList.svelte'

let stores = getContext('trustStores')
$: ({
    metadata
} = stores)

</script>

<div class="grid grid-cols-3 gap-x-4 gap-y-8 mt-4">
    {#each $metadata.advisors as advisor}
        <div class="space-y-4 flex flex-col">
            <div class="w-full aspect-w-16 aspect-h-12">
                <img class="object-cover w-full rounded-md" src={advisor.image} />
            </div>
            <div>
                <div class="font-medium">
                    {advisor.name}
                </div>
            </div>
            {#if advisor.bio}
                <div class="font-base text-gray-800">
                    {advisor.bio}
                </div>
            {/if}
            {#if advisor.socials}
            <div class="flex flex-row">
                <SocialsList socials={advisor.socials} />
            </div>
            {/if}
        </div>
    {/each}
</div>