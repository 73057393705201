<script>
import { site } from './../../stores/Site.js';
import Pill from "../Pill.svelte"
import RewardItem from "./RewardItem.svelte"

export let rewards
export let tier
export let balance
export let myTier
export let currency

$: isMyTier = $myTier?.id === tier.id
</script>

{#if ($site == 'ff3' || $site == 'goldfinch')}
<div class={isMyTier ? "shadow-md p-5 border-2 bg-white rounded-lg space-y-5 border-primary-500" : "p-5 border bg-white rounded-lg space-y-5 border-gray-200"}>
    <div class="flex flex-row gap-x-4">
        <div class="w-1/2 flex-none">
            <div class="w-full">
                {#if tier?.animation}
                    <video autoplay loop>
                        <source src={tier.animation} type="video/mp4">
                    </video>
                {:else}
                    <img alt='tier' class="object-contain rounded-md w-full max-h-96" src={tier.image} />
                {/if}
            </div>
        </div>
        <div class="flex flex-col space-y-2">
            {#if isMyTier}
                <Pill color="primary">YOUR TIER</Pill>
            {/if}
            <div>
                <div class="text-2xl font-semibold text-gray-900">{tier.title}</div>
                <div class="text-lg font-medium text-gray-800">
                    {tier.tokens_to_hold}+ {currency.symbol} 
                    {#if balance && isMyTier}
                        (You have {balance.toSignificant(4)})
                    {/if}
                </div>
            </div>
            <div class='font-light pt-1'>{tier.description}</div>
            <ul class="flex flex-col gap-y-2 list-disc pl-6">
                {#each tier.items as id}
                <li>
                    {rewards.items.filter(item=>item.id == id)[0].title}
                </li>
                {/each}
            </ul>
        </div>
    </div>
</div>
{:else}
<div class={isMyTier ? "shadow-md p-5 border-2 bg-white rounded-lg space-y-5 border-primary-500" : "p-5 border bg-white rounded-lg space-y-5 border-gray-200"}>
    <div class="flex flex-row gap-x-4">
        <div class="w-48 flex-none">
            <div class="aspect-w-1 aspect-h-1">
                {#if tier?.animation}
                    <video autoplay loop>
                        <source src={tier.animation} type="video/mp4">
                    </video>
                {:else}
                    <img alt='tier' class="object-cover rounded-md" src={tier.image} />
                {/if}
            </div>
            </div>
        <div class="flex flex-col space-y-2">
            {#if isMyTier}
                <Pill color="primary">YOUR TIER</Pill>
            {/if}
            <div>
                <div class="text-2xl font-semibold text-gray-900">{tier.title}</div>
                <div class="text-lg font-medium text-gray-800">
                    {tier.tokens_to_hold}+ {currency.symbol} 
                    {#if balance && isMyTier}
                        (You have {balance.toSignificant(4)})
                    {/if}
                </div>
            </div>
            <div class='text-sm font-light text-gray-900 pt-1'>{tier.description}</div>
        </div>
    </div>
    
    <div class="grid grid-cols-3 gap-5">
        {#each tier.items as id}
            <RewardItem items={rewards.items} {id}/>
        {/each}
    </div>
</div>
{/if}
