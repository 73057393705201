<script>
	import { writable } from 'svelte/store';
// components
import ProjectName from '../components/ProjectName.svelte'
import ProjectCreator from '../components/ProjectCreator.svelte'
import ProjectCoverImageVideo from '../components/ProjectCoverImageVideo.svelte'
import ProjectBodyTabs from '../components/ProjectBodyTabs.svelte'
import Spacer from '../../../components/util/Spacer.svelte'
import { setContext } from 'svelte'
import { curation } from '../../../stores/Site'
import Main from '../../../layout/Main.svelte'
import UpcomingProjectInfo from './UpcomingProjectInfo.svelte';

import { site } from '../../../stores/Site'

export let params = {}
let projects = []

$: if ($curation) {
    projects = $curation.comingSoonProjects?.filter(project => project.id == params.id)
    const stores = {
        metadata: writable(projects[0]),
        redeemableCurrency: writable({
            symbol: projects[0].raise_info?.project_token_parameters.token_symbol
        })
    }
    setContext('trustStores', stores)
}

</script>
{#if projects.length}
<Main>
    <div class="w-full grid grid-cols-5 gap-4 py-5 mx-auto">
        <div class="col-span-3 flex flex-col space-y-3">
            <ProjectName />
            <ProjectCreator />
            <Spacer height=2 />
            <ProjectCoverImageVideo />
            <Spacer height=4 />
            <ProjectBodyTabs />
        </div>
        <div class="col-span-2 space-y-6">
            <UpcomingProjectInfo project={projects[0]}/>
        </div>
    </div>
</Main>
{/if}