import "ethers";
import 'tailwindcss/colors';
// factory
export const FACTORY_ADDRESS = '0x56CdCBcc1aaB520964f9D24fa3BFAa97258fDdC1'; // new factory - with fix for KYC users to sell back rTKN
// block explorer base path
export const BLOCK_EXPLORER_TRANSACTION = 'https://mumbai.polygonscan.com/tx/';
export const BLOCK_EXPLORER_ADDRESS = 'https://mumbai.polygonscan.com/address/';
// tier default
export const VERIFY_TIER_DEFAULT = '0x80b7223d1eEC343904d062455d4ce8262b3806d2'; // a default VerifyTier for Polygen
export const TIER_DEFAULT = '0x80b7223d1eEC343904d062455d4ce8262b3806d2'; // a default VerifyTier for Polygen
export const RESERVE_DEFAULT = '0x25a4Dd4cd97ED462EB5228de47822e636ec3E31A'; // USDC
// rpc
export const PROVIDER_RPC = 'https://polygon-mumbai.g.alchemy.com/v2/94Lqen0XXp2HNSDlUZ_nu8cbkDUJXJpJ';
// chain
export const CHAIN_ID = 80001; // mudmbai
export const NETWORK_NAME = "Mumbai";
// covalent api key
export const COVALENT_KEY = 'ckey_7e3942e77eb947f7ab1ad48a3ca';
export const PAGE_LENGTH = 999999; // historical log pagination - number of blocks
export const logo = "./images/goldfinch/ff-logo.png";
export const logoReversed = "./images/goldfinch/ff-logo-on-black.png";
export const favicon = "./images/goldfinch/favicon.png";
export const webclip = "./images/goldfinch/webclip.png";
export const siteName = "First Flights";
export const siteSub = "Film launchpad";
export const curationContent = './curation/goldfinch.json';
// theme for tailwind
export const theme = {
    extend: {
        colors: {
            primary: {
                100: '#F6F6FE',
                200: '#E8E8FD',
                300: '#D1D1FA',
                400: '#BEBFF8',
                500: '#A5A6F6',
                600: '#7879F1',
                700: '#5D5FEF',
                800: '#4042D4',
                900: '#2D2FB9'
            }
        }
    }
};
// header content
export const HEADER_CONTENT = {
    upcoming: "Upcoming short films you can support!",
    seed: "Provide liquidity for the raise pool of a project you love and earn rewards.",
    fund: "Help fund your favourite short film projects.",
    claim: "Claim your rewards after a successful raise.",
    new: "You can use this interface to know how you can get your project live for funding",
    guide: ""
};
