<script>
import { Tabs, TabList, TabPanel, Tab } from '../../../components/tabs/tabs';
import MyTransactions from './MyTransactions.svelte'
import AllTransactions from './AllTransactions.svelte'
import TopHolders from './TopHolders.svelte'

</script>

<Tabs>
	<TabList>
		<Tab>All transactions</Tab>
		<Tab>My transactions</Tab>
		<Tab>Top holders</Tab>
	</TabList>

	<TabPanel>
		<AllTransactions />
	</TabPanel>

	<TabPanel>
		<MyTransactions />
	</TabPanel>

	<TabPanel>
		<TopHolders />
	</TabPanel>
</Tabs>