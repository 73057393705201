<script>
import { Tabs, TabList, TabPanel, Tab } from '../../../components/tabs/tabs'
import ProjectDescription from './ProjectDescription.svelte'
import ProjectRaiseTab from './ProjectRaiseTab.svelte'
import ProjectTeamTab from './ProjectTeamTab.svelte'
import ProjectAdvisorsTab from './ProjectAdvisorsTab.svelte'
import ProjectPartnersTab from './ProjectPartnersTab.svelte'
import { getContext } from 'svelte'
import RewardsTab from './RewardsTab.svelte';

$: ({
    metadata
} = getContext('trustStores'))

</script>


<Tabs>
	<TabList>
		{#if $metadata?.description || $metadata?.description_inline}
		<Tab>About</Tab>
		{/if}

		{#if $metadata?.rewards}
		<Tab>Rewards</Tab>
		{/if}

		{#if $metadata?.raise}
		<Tab>Raise</Tab>
		{/if}

		{#if $metadata?.team}
		<Tab>Team</Tab>
		{/if}

		{#if $metadata?.advisors}
		<Tab>Advisors</Tab>
		{/if}

		{#if $metadata?.partners}
		<Tab>Partners</Tab>
		{/if}

	</TabList>

	{#if $metadata?.description || $metadata?.description_inline}
	<TabPanel>
		<ProjectDescription />
	</TabPanel>
	{/if}

	{#if $metadata?.rewards}
	<TabPanel>
		<RewardsTab />
	</TabPanel>
	{/if}

	{#if $metadata?.raise}
	<TabPanel>
		<ProjectRaiseTab />
	</TabPanel>
	{/if}

	{#if $metadata?.team}
	<TabPanel>
		<ProjectTeamTab />
	</TabPanel>
	{/if}

	{#if $metadata?.advisors}
	<TabPanel>
		<ProjectAdvisorsTab />
	</TabPanel>
	{/if}

	{#if $metadata?.partners}
	<TabPanel>
		<ProjectPartnersTab />
	</TabPanel>
	{/if}

</Tabs>