<script>
import { getContext } from "svelte"

let stores = getContext('trustStores')
$: ({
    metadata,
    myBalances,
    myTier
} = stores)

$: if ($metadata?.rewards && $myBalances?.redeemableBalance) {
    // sort by token threshold
    let tiers = $metadata?.rewards.tiers.sort((a,b)=>{return a.tokens_to_hold - b.tokens_to_hold})
    let balance = parseFloat($myBalances?.redeemableBalance?.toSignificant(4))
    $myTier = tiers.filter((v, i, arr) => {
        // if it's the last tier in the array, my balance just needs to be higher than or equal
        // otherwise it needs to be higher than or equal to this tier, but less than the next tier
        return i+1 === arr.length ? balance >= v.tokens_to_hold : balance >= v.tokens_to_hold && balance < arr[i+1].tokens_to_hold
    })[0] || -1
}

</script>