<script>
import { tick } from './../Tick.js'
import { getContext } from 'svelte'
import { Token, CurrencyAmount } from '@uniswap/sdk-core'
import { constants } from '../../constants/Constants'

// stores
$: ({
    contractsReadOnly : {
        redeemableToken: redeemableToken,
        reserveToken: reserveToken
    },
    redeemableSupply,
    reserveCurrency,
    redeemableCurrency,
    projectState
} = getContext('trustStores'))

$: if ($reserveToken) {
    getReserveInfo()
}

async function getReserveInfo() {
    // create a new Token for the reserve
    $reserveCurrency = new Token(constants.CHAIN_ID, $reserveToken.address, await $reserveToken.decimals(), await $reserveToken.symbol(), await $reserveToken.name())
}

$: if ($redeemableToken) {
    setRedeemableCurrency()
}

async function setRedeemableCurrency() {
    // create a new Token for the redeemable
    $redeemableCurrency = new Token(constants.CHAIN_ID, $redeemableToken.address, await $redeemableToken.decimals(), await $redeemableToken.symbol(), await $redeemableToken.name())
}

$: if ($redeemableToken && $redeemableCurrency) {
    updateRedeemableSupply()
}

// if the project is in claim state we need to refresh the redeemable supply on tick
$: if ($redeemableToken && $redeemableCurrency && ($projectState === 4 || $projectState === 5) && $tick % 2) {
    updateRedeemableSupply()
}

async function updateRedeemableSupply() {
    // get redeemable total supply
    $redeemableSupply = CurrencyAmount.fromRawAmount($redeemableCurrency, await $redeemableToken.totalSupply())
}

</script>