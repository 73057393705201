import { writable } from 'svelte/store'
import { constants } from '../constants/Constants'

export const site = writable(process.env.SITE)
export const curation = writable(0)

fetch(constants.curationContent, {cache: "no-store"}).then(response=>{
    if (!response.ok) {
           throw new Error("HTTP error " + response.status);
    }
    return response.json();
}).then(json=>{
    setTimeout(()=>{curation.set(json)}, 1000)
}).catch(error=>{
    console.log(error)
})

