<script>
export let left
export let right
export let columns = []
</script>
    
    
{#if left || right}
    <div class="flex-row flex justify-between text-xs uppercase text-gray-400 font-medium mb-3">
        <div>{left}</div>
        <div>{right}</div>
    </div>
{/if}

{#if columns.length}
    <div class="flex-row flex justify-between text-xs uppercase text-gray-400 font-medium mb-3">
        {#each columns as column, index}
            {#if index == 0}
                <div class="flex-1">{column}</div>
            {:else if index == (columns.length - 1)}
                <div class="flex-1 text-right">{column}</div>
            {:else}
                <div class="flex-1 text-left">{column}</div>
            {/if}
        {/each}
    </div>
{/if}