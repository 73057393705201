<script lang="ts">
  const LINE_COLOR = "#2563eb";
  const FONT_FAMILY = `'Poppins', sans-serif`;
  const SUPPLY_SCALE_FACTOR = 4;

  import { constants } from "../../../../../constants/Constants";
  import { ethers } from "ethers";
  import { BigNumber } from "ethers";
  import { getContext, onDestroy, onMount } from "svelte";
  import { bnToFourDecimal } from "../../../../../utils/utils";

  import ApexCharts from 'apexcharts';

  import LegendBottom from "./LegendBottom.svelte";
  
  import SwapData from "../../../../../stores/project/SwapData.svelte";

  let stores = getContext("trustStores") as any;
  $: ({
    swapData,
    reserveCurrency,
    redeemableCurrency,
    market,
    timeRemaining,
    timeSpent,
    finalWeight,
    spotPrice,
    amountRaised,
  } = stores);


  let ctx;
  let w: number;
  let h: number;
  let _chart;

  let _forecastDataPoints = 0;
  let _chartDataSource = [];

  let redeemableToken = stores.contractsReadOnly.redeemableToken;
  let currentSpotPrice: number;
  let projectedFinalSpotPrice: number;
  
  currentSpotPrice = 2
  projectedFinalSpotPrice = 1
  
  $: if ($swapData) { 

    _chartDataSource = [];

    $swapData.forEach((item, index) => {
      //_chartDataSource.push([item.timestamp, parseFloat(item.tokenPrice)])
      _chartDataSource.push({
        'x': item.timestamp,
        'y': parseFloat(item.tokenPrice),
        'timestamp': item.timestamp,
        'tokenPrice': parseFloat(item.tokenPrice),
        'tokenInSymbol': item.tokenInSymbol,
        'tokenOutSymbol': item.tokenOutSymbol,
        'tokenInAmount': item.tokenAmountInNum,
        'tokenOutAmount': item.tokenAmountOutNum,
        'transactionHash': item.transactionHash
      })
    });
    if(_chartDataSource.length> 0){
      drawChart();
    }
  }

  onMount(() => {
    //drawChart();
  });

  onDestroy(() => {
    
  });

  const drawChart = () => {
    
    if(document.querySelector("#_chart") == undefined || document.querySelector("#_chart") == null){
      return;
    }
    //blocking re-rendring due to issue
    //if(_chart == null){

    var _chartObj = {
        chart: {
          height: 450,
          type: 'line',
          zoom : {
            enabled : false
          },
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false
          }
        },
        forecastDataPoints: {
          count: _forecastDataPoints
        },
        series: [{
          name : 'Raise Token Price (rTKN)',
          data: _chartDataSource
        }],
        stroke: {
          width: 2,
          curve: 'smooth'
        },
        legend: {
          show: true,
          onItemHover: {
              highlightDataSeries: false
          },
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 5,
          title: {
            text: "Time in UTC",
            style: {
              color: "#C2172C",
              fontSize: '14px'
            }
          }
        },
        yaxis: 
        {
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false,
            color: "#FF1654"
          },
          title: {
            text: "Raise Token Price (rTKN)",
            style: {
              color: "#C2172C",
              fontSize: '14px'
            }
          }
        },
        tooltip: {
          custom: function ({series, seriesIndex, dataPointIndex, w}) {
            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            var _date = new Date(data.timestamp)

            var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            var year = _date.getUTCFullYear();
            var month = months[_date.getUTCMonth()];
            var dd = String(_date.getUTCDate()).padStart(2, '0');
            var hh = _date.getUTCHours();
            var mm = _date.getUTCMinutes();
            var _time = month + ' ' + dd + ', ' + year + ' ' + hh + ':' + mm + ' UTC';
            
            return "<ul style='padding: 5px;'>" 
                  + '<li><b>Timestamp</b>: ' 
                  //+ _date.toLocaleDateString() + ' ' + _date.toLocaleTimeString()
                  + _time
                  //+ _date.getDate() + '-' + _date.getMonth() + '-' + _date.getFullYear() + ' ' + _date.getHours().toString() + ':' + _date.getMinutes() + ':' + _date.getSeconds() + ' UTC</li>'
                + '<li><b>Raise Token Price (rTKN)</b>: ' + data.tokenPrice + '</li>' +
                '<li><b>Tokens In</b>: ' + data.tokenInAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + data.tokenInSymbol + '</li>' +
                '<li><b>Tokens Out</b>: ' + data.tokenOutAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + data.tokenOutSymbol + '</li>' +
                '</ul>';
          }
        }
      }

    if(_chart){
      _chart.destroy();
    }

    _chart = new ApexCharts(document.querySelector("#_chart"), _chartObj);
    _chart.render();

  }

  function value_projection(start_date, end_date, start_value, end_value, steps = 20) {

    if((end_date > start_date) && steps > 0)
    {
        let date_diff = (end_date - start_date) / steps; // calculating value of x-axis
        let value_diff = (start_value - end_value) / steps; // calculating value of y-axis

        let new_start_date = start_date;
        let projected_value = start_value;
        let xy_values = [];

        for (let i = 0; i < steps; i++) {
            new_start_date = new_start_date + date_diff;
            projected_value = parseFloat((projected_value - value_diff).toFixed(4));

            xy_values.push([new_start_date, projected_value]);
        }
        return xy_values;
    }
    else
    {
        return [];
    }


  }

</script>

<!-- <div class="flex justify-end w-full pb-4 px-8">
  <button class="bg-gray-400 text-white text-xs p-1 rounded-md mx-2 uppercase" on:click={() => chart.resetZoom()}>Reset Zoom</button>
</div>
    
<div
  class="flex justify-center items-center w-full h-full"
  bind:clientWidth={w}
  bind:offsetHeight={h}
>
  <canvas bind:this={ctx} width={w} height={h} />
  <br/>
</div> -->

<div id="_chart" style="height: 600px; width: 100%; min-height: 465px;" >

</div>

<!-- <LegendBottom priceColor={LINE_COLOR} supplyColor="rgba(0, 0, 0, 0.2)" /> -->
