<script>
	import Button from './../../components/buttons/Button.svelte';
	import { account } from './../../stores/Account.js';
  import { constants } from '../../constants/Constants'
  import ApproveKYC from './ApproveKYC.svelte'
  import KYCRoles from './KYCRoles.svelte'
  import Main from '../../layout/Main.svelte'
  import TabList from '../../components/tabs/TabList.svelte'
  import Tabs from '../../components/tabs/Tabs.svelte'
  import TabPanel from '../../components/tabs/TabPanel.svelte'
  import Tab from '../../components/tabs/Tab.svelte'
</script>

{#if constants.KYC_ENABLED === true}
  {#if $account}
  <Main>
    <div class="mt-10 w-full">
      <Tabs>
        <TabList>
          <Tab>KYC Admin</Tab>
          <Tab>Roles</Tab>
        </TabList>
        <TabPanel>
          <ApproveKYC />
        </TabPanel>
        <TabPanel>
          <KYCRoles />
        </TabPanel>
      </Tabs>
    </div>
  </Main>
  {:else}
  <div class="flex flex-col flex-grow justify-center items-center">
      <Button shrink=true on:click={account.connect}>Connect Wallet</Button>
  </div>
  {/if}
{/if}
