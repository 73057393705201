<script>
// modules
import { ethers } from 'ethers'

// svelte
import { onMount, getContext, createEventDispatcher, tick } from 'svelte'

// components
import InlineNotification from './../../../../components/notifications/InlineNotification.svelte';
import Button from './../../../../components/buttons/Button.svelte';
import IconLibrary from './../../../../components/icons/IconLibrary.svelte';
import Steps from './../../../../components/steps/Steps.svelte';
import LoadingAnim from './../../../../components/util/LoadingAnim.svelte';
import StatRow from '../../../../components/stats/StatRow.svelte';
import DisplayToken from './../../../../components/tokenfield/DisplayToken.svelte';

// constants
import { constants } from '../../../../constants/Constants';

// stores
import { account, provider } from './../../../../stores/Account.js';
import { CurrencyAmount, Price } from '@uniswap/sdk-core';
import { toRaw } from '../../../../utils/utils';

$: ({
    contractsReadOnly : {
        bPool: bPoolReadOnly,
        reserveToken: reserveTokenReadOnly,
        redeemableToken: redeemableTokenReadOnly
    },
    contractsWithSigner : {
        bPool: bPool,
        reserveToken: reserveToken,
        redeemableToken: redeemableToken
    },
    reserveAllowance,
    redeemableAllowance,
    myBalances,
    redeemableCurrency,
    reserveCurrency
} = getContext('trustStores'))

const BN = ethers.BigNumber

// pulling together all the data per token
export let tokenIn = {}
export let tokenOut = {}

// local to this component
export let tokenInLocal = {}
export let tokenOutLocal = {}

export let maxPrice
export let slippage = 0

let priceChange = null
export let priceRefreshTimeout
export let txStatus = "exceeds-allowance"
export let errorMsg

// close modal event
const dispatch = createEventDispatcher()
function closeModal() {
    dispatch('close')
}

// check allowances as the modal is opened
onMount( () => {
  if (txStatus !== "waiting-on-signature-poke") {
    checkAllowance()
    clearTimeout(priceRefreshTimeout)
  }
})

async function checkAllowance() {
  txStatus = 'checking-allowance'
  $reserveAllowance = CurrencyAmount.fromRawAmount($reserveCurrency, await $reserveTokenReadOnly.allowance($account, $bPoolReadOnly.address))
  $redeemableAllowance = CurrencyAmount.fromRawAmount($redeemableCurrency, await $redeemableTokenReadOnly.allowance($account, $bPoolReadOnly.address))
  await tick()
  txStatus = tokenInLocal.amount.greaterThan(tokenIn.allowance) ? "exceeds-allowance" : "allowance-increased"
}

async function approve() {
    txStatus = "waiting-on-signature"
    let tx = await tokenIn.tokenWithSigner.approve($bPool.address, toRaw(tokenInLocal.maxIn)).then(async (result)=>{
      txStatus = "verifying"
      await $provider.waitForTransaction(result.hash, 2)
      checkAllowance()
    }).catch(error => {
        errorMsg = error.message
        if (error.data) {errorMsg = error.data.message}
        txStatus = "error"
    })
}

async function swap() {
    txStatus = "waiting-on-signature"
    if (tokenOutLocal.estimated) {
      // console.log($bPool)
      // console.log(tokenIn.token.address,
      // toRaw(tokenInLocal.amount).toString(),
      // tokenOut.token.address,
      // toRaw(tokenOutLocal.minOut).toString(),
      // maxPrice)
      let tx = await $bPool.swapExactAmountIn(
        tokenIn.token.address,
        toRaw(tokenInLocal.amount),
        tokenOut.token.address,
        toRaw(tokenOutLocal.minOut),
        maxPrice
      ).then(async (result)=>{
        txStatus = "verifying"
        await $provider.waitForTransaction(result.hash, 1)
        await updateRedeemableBalance()
        txStatus = "swap-complete"
        // dispatch a complete event to clear the fields
        dispatch('complete')
      }).catch(error => {
        errorMsg = error.message
        if (error.data) {errorMsg = error.data.message}
        errorMsg = errorMsg === 'execution reverted: ERR_LIMIT_OUT' ? errorMsg = 'Your swap would result in too little of the token out. Please try again or try with a higher slippage amount.' : errorMsg
        errorMsg = errorMsg === 'execution reverted: MIN_TIER' ? errorMsg = constants.MIN_TIER_MESSAGE : errorMsg
        txStatus = "error"
      })
    } else {
      let tx = await $bPool.swapExactAmountOut(
        tokenIn.token.address,
        toRaw(tokenInLocal.maxIn),
        tokenOut.token.address,
        toRaw(tokenOutLocal.amount),
        maxPrice
      ).then(async (result)=>{
        txStatus = "verifying"
        await $provider.waitForTransaction(result.hash, 1)
        await updateRedeemableBalance()
        txStatus = "swap-complete"
        // dispatch a complete event to clear the fields
        dispatch('complete')
      }).catch(error => {
        errorMsg = error.message
        if (error.data) {errorMsg = error.data.message}
        errorMsg = errorMsg === 'execution reverted: ERR_LIMIT_IN' ? errorMsg = 'Your swap would spend too much of the token in. Please try again or try with a higher slippage amount.' : errorMsg
        txStatus = "error"
      })
    }
}

async function updateRedeemableBalance() {
  const redeemableBalance = CurrencyAmount.fromRawAmount($redeemableCurrency, await $redeemableTokenReadOnly.balanceOf($account))
  myBalances.update(data => ({
        ...data,
        redeemableBalance,
  }))
}
</script>

{#if txStatus == "checking-allowance"}
  <div class="w-full flex flex-col items-center">
    <LoadingAnim />
    Checking allowance...
  </div>


{:else if txStatus=="exceeds-allowance"}
  <div class="max-w-md flex flex-col space-y-4">
      <span class="text-lg font-semibold">Confirm swap</span>
      <Steps steps={['Approve', 'Confirm', 'Swap Complete']} activeStep=0 />
      <!-- <InlineNotification type="warning"> -->
      <span class="my-4">
        Your swap exceeds your approved allowance for {tokenIn.symbol}.
      </span>
      <!-- </InlineNotification> -->
      <div class="space-y-4 p-4 border border-gray-200 rounded-xl">
        <StatRow label="Current allowance" value="{tokenIn?.allowance?.toSignificant?.(8)} {tokenIn.symbol}"/>
        <StatRow label="Required allowance" value="{tokenInLocal.maxIn.toSignificant(8)} {tokenIn.symbol}"/>
      </div>
      <Button on:click={approve} label="Approve"/>
  </div>


{:else if txStatus=="allowance-increased"}
  <div class="max-w-lg flex flex-col space-y-4">
    <span class="text-lg font-semibold">Confirm swap</span>
    <Steps steps={['Approve', 'Confirm', 'Swap Complete']} activeStep=1 />
    <div class="flex flex-col space-y-4">
      <DisplayToken 
        tokenVal={tokenInLocal.amount.toSignificant(8)}
        tokenSymbol={tokenIn.symbol}
        tokenIcon={tokenIn.icon}
        label={tokenInLocal.estimated ? 'From (estimated)' : 'From'}
        amount={tokenInLocal.amount}
        />
      <div class="self-center">
        <IconLibrary icon="arrows" width=30 />
      </div>
      <DisplayToken
        tokenVal={tokenOutLocal.amount.toSignificant(8)}
        tokenSymbol={tokenOut.symbol}
        tokenIcon={tokenOut.icon}
        label={tokenOutLocal.estimated ? 'To (estimated)' : 'To'}
        amount={tokenOutLocal.amount}
        />
    </div>
    {#if priceChange}
      <div class="price-alert w-clearfix">
        <img src="images/alert-icon.svg" loading="lazy" alt="" class="alert-icon">
        <div class="medium-weight">Price change</div>
        <button class="button outline reverse small">ACCEPT</button>
      </div>
    {/if}
    <InlineNotification type="warning">
      {#if tokenInLocal.estimated}
        {tokenIn.symbol} is estimated, you will sell at most {tokenInLocal.maxIn.toSignificant(8)} {tokenIn.symbol} or the transaction will revert.
      {/if}
      {#if tokenOutLocal.estimated}
        {tokenOut.symbol} is estimated, you will receive at least {tokenOutLocal.minOut.toSignificant(8)} {tokenOut.symbol} or the transaction will revert.
      {/if}
    </InlineNotification>
    <div class="space-y-2 p-4 border border-gray-200 rounded-xl">
      <StatRow 
        label="Price"
        value="{new Price({baseAmount: tokenOutLocal.amount, quoteAmount: tokenInLocal.amount}).toSignificant(8)} {tokenIn.symbol} per {tokenOut.symbol}"
        />
      {#if tokenInLocal.estimated}
        <StatRow 
          label="Maximum sold"
          value={tokenInLocal.maxIn.toSignificant(8) + ' ' + tokenIn.symbol}
          />
      {/if}
      {#if tokenOutLocal.estimated}
        <StatRow
          label="Minimum received"
          value={tokenOutLocal.minOut.toSignificant(8) + ' ' + tokenOut.symbol}
          />
      {/if}
    </div>
    <Button on:click={swap} label="Confirm swap" />
  </div>
{:else if txStatus == "waiting-on-signature" || txStatus == "waiting-on-signature-poke"}
  <div class="w-full flex flex-col items-center">
    <LoadingAnim />
    Waiting on signature...
  </div>
{:else if txStatus == "verifying"}
  <div class="w-full flex flex-col items-center">
    <LoadingAnim />
    Confirming Transaction...
  </div>
{:else if txStatus == "swap-complete"}
  <div class="max-w-md flex flex-col space-y-4">
    <span class="text-lg font-semibold">Confirm swap</span>
    <Steps steps={['Approve', 'Confirm', 'Swap complete']} activeStep=2 />
    <div class="text-gray-600 font-light pt-5 space-y-4">
        <div>
            Your transaction has been confirmed! After a successful raise you can use your {$redeemableCurrency.symbol} to claim rewards, or burn them and receive the locked redemption value.
        </div>
        <div>
            These tokens can be sold back into the pool while the raise is still open.
        </div>
    </div>
    <DisplayToken amount={$myBalances.redeemableBalance} tokenVal={$myBalances.redeemableBalance.toSignificant(8)} tokenIcon={constants.redeemableIcon} tokenSymbol={$redeemableCurrency.symbol} label="Your new balance"/>
    <Button on:click={closeModal} label="Ok" />
  </div>
{:else if txStatus == "weights-updated"}
  <div class="w-full flex flex-col items-center">
    Price updated.
  </div>
{:else if txStatus == "error"}
<div class="max-w-md space-y-3">
  <span class="tx-lg font-medium">Error</span>
  <InlineNotification type="error">{errorMsg}</InlineNotification>
</div>
{/if}