<script>
    import BigLabelledVal from "../../../components/labelledvalues/BigLabelledVal.svelte"
    import { site } from "../../../stores/Site";

    export let project
    $:console.log($site)
    let tknNumber = {}
    let initTknPrice = {}
    let seedAmount = {}
    let seedReward = {}
    let currency = {}
    
    if(project.raise_info){
        tknNumber[project.raise_info.project_token_parameters.token_symbol + ' Tokens For Sale'] = parseInt(project.raise_info.project_token_parameters.number_of_project_tokens).toLocaleString("en-US")
        initTknPrice["Initial r" + project.raise_info.project_token_parameters.token_symbol + " Price" ] = project.raise_info.initial_token_price
        seedAmount['Seed Amount'] = parseInt(project.raise_info.seed_parameters.seed_amount).toLocaleString("en-US")
        seedReward['Seed Reward'] = parseInt(project.raise_info.seed_parameters.seed_reward).toLocaleString("en-US")
        currency = project.raise_info.reserve_token_symbol
    }
    let stats = [
        tknNumber,
        initTknPrice,
        seedAmount,
        seedReward
    ]
    </script>
    
    <div class="w-full gap-y-3 border border-gray-200 bg-white filter rounded-2xl shadow-md p-5 flex flex-col">
    
        <div class="text-gray-900 font-medium text-2xl" style="padding-bottom: 2rem;">Raise details</div>
        {#if project.raise_info}
            <!-- 
            {#if project?.launch_datetime} -->
                <div class="flex flex-row space-x-2">
                    <span class="text-gray-500">
                        Launch date:
                    </span>
                    <span class="text-gray-700 font-medium">
                        {project?.launch_datetime ? project.launch_datetime : "Not confirmed yet"}
                    </span>
                </div>
            <!--  {/if} -->
    
            <div class="flex flex-row space-x-2 border-b border-gray-200 pb-4">
                <span class="text-gray-500">
                    Raise duration:
                </span>
                <span class="text-gray-700 font-medium">
                    {project.raise_info.raise_duration} {project.raise_info.raise_duration_unit}
                </span>
            </div>
    
            <BigLabelledVal label="Raise target" value={parseInt(project.raise_info?.raise_target).toLocaleString("en-US") + ' ' + project.raise_info?.reserve_token_symbol} />
    
            <BigLabelledVal label="Project token" value={project.raise_info?.project_token_parameters.token_name + ' (' + project.raise_info?.project_token_parameters.token_symbol + ')'} />
    
            {#if !($site == 'goldfinch' || $site == 'ff3')}
            
            <div class="flex flex-row space-x-2 border-b border-gray-200"></div>
            
            {$site !== 'goldfinch'}
            <div class="flex flex-row space-x-2 items-stretch justify-between">
                    {#each stats as stat, index}
                        <div class="">
                            <BigLabelledVal label={Object.keys(stat)[0]} value={stat[Object.keys(stat)[0]]} />
                        </div>
                        {#if index !== stats.length - 1}
                            <div class="border-r border-gray-200"></div>
                        {/if}
                    {/each}
            </div>
            {#if currency}
                <div>
                    <!-- <div class="text-gray-500 mb-1">*All currency related values are in {currency}</div> -->
                    <div class="text-gray-500 mb-1">*Raise target, initial {project.raise_info.project_token_parameters.token_symbol} price, seed amount and seed reward are in {currency}</div>
                    
                </div>
            {/if}
            {/if}
        {/if}
    </div>