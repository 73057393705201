<script>
// components
import Button from "../../../components/buttons/Button.svelte";
import Modal from "../../../components/modals/Modal.svelte";
import StartRaiseModal from "./StartRaiseModal.svelte";

// stores
import { account } from './../../../stores/Account.js';


let startRaiseModal
function openStartRaiseModal() { startRaiseModal = true }
function closeModal() { 
    startRaiseModal = false 
}
</script>

<div class="w-full space-y-8 border border-gray-200 bg-white filter rounded-xl shadow-md p-5 flex flex-col">
    <div class="text-gray-900 font-medium text-2xl">Start raise</div>
    <div class="space-y-2 text-gray-600 flex flex-col">
        This raise is ready to start, and can be started by anyone.
    </div>
    {#if $account}
        <Button label="Start raise" on:click={openStartRaiseModal}/>
    {:else}
        <Button label="Connect wallet" on:click={account.connect} variant="secondary"/>
    {/if}
</div>

{#if startRaiseModal}
<Modal on:close={closeModal}>
    <StartRaiseModal />
</Modal>
{/if}