<script>
// svelte
import { getContext } from 'svelte'
import { createEventDispatcher } from 'svelte'

// components
import DisplayToken from '../../../components/tokenfield/DisplayToken.svelte'
import Button from '../../../components/buttons/Button.svelte'
import LoadingAnim from '../../../components/util/LoadingAnim.svelte'
import InlineNotification from '../../../components/notifications/InlineNotification.svelte'
import Steps from '../../../components/steps/Steps.svelte'
import SimpleTableHeading from './../../../components/simpletable/SimpleTableHeading.svelte'
import SimpleTableRow from './../../../components/simpletable/SimpleTableRow.svelte'

// stores
import { signer } from '../../../stores/Account'
import { toRaw } from '../../../utils/utils'
import SimpleTableCell from '../../../components/simpletable/SimpleTableCell.svelte'

$: ({
    contractsWithSigner : {
        redeemableToken: redeemableToken,
    },
    redeemShares,
    myRedeemBalances,
    redeemAddresses
} = getContext('trustStores'))

export let tokenAmt
export let tokenAmtDisplay
export let tokenSymbol
export let tokenIcon

let txStatus = 'confirm'
let errorMsg

// close modal event
const dispatch = createEventDispatcher()
function closeModal() {
    dispatch('close')
}

function redeem() {
    txStatus = "waiting-on-signature"
    let tx = $redeemableToken.redeem($redeemAddresses, toRaw(tokenAmt))
            .then(async (result)=> {
                txStatus = "verifying"
                await $signer.provider.waitForTransaction(result.hash, 1)
                txStatus = "complete"
                // dispatch a complete event so the field can be cleared
                dispatch('complete')
            }).catch(error => {
                errorMsg = error.message
                if (error.data) {errorMsg = error.data.message}
                txStatus = "error"
            })
}
</script>

{#if txStatus == 'confirm'}
    <div class="max-w-md flex flex-col gap-y-4"> 

        <span class="text-lg font-semibold">Confirm redemption</span>

        <Steps steps={['Confirm', 'Redeem Complete']} activeStep=0 />

        <span class="text-sm font-medium">
            You are redeeming:
        </span>

        <DisplayToken amount={tokenAmt} tokenVal={tokenAmtDisplay} tokenIcon={tokenIcon} tokenSymbol={tokenSymbol} />

        <span class="text-sm font-medium">
            You'll receive:
        </span>

        <div class="flex flex-col space-y-2">
            {#each $redeemShares as share, index}
                <SimpleTableRow alt={index % 2}>
                    <SimpleTableCell column=1 of=2>
                        {share.quoteCurrency.symbol}
                    </SimpleTableCell>
                    <SimpleTableCell column=2 of=2>
                        {share.quote(tokenAmt).toSignificant(8)}
                    </SimpleTableCell>
                </SimpleTableRow>            
            {/each}
        </div>

        <InlineNotification type="error">
            Redeeming your tokens will burn them and they cannot be used to claim future rewards.
        </InlineNotification>

        <Button label="Confirm redeem" on:click={redeem} />

    </div>

{:else if txStatus == "waiting-on-signature"}
    <div class="w-full flex flex-col items-center">
      <LoadingAnim />
      Waiting on signature...
    </div>

{:else if txStatus == "verifying"}
    <div class="w-full flex flex-col items-center">
        <LoadingAnim />
        Confirming Transaction...
    </div>

{:else if txStatus == "complete"}
    <div class="max-w-md flex flex-col gap-y-4">

        <span class="text-lg font-semibold">Redemption complete</span>

        <Steps steps={['Confirm', 'Redeem Complete']} activeStep=1 />

        <span class="text-sm font-medium">
            Your new balances:
        </span>

        <div class="flex flex-col space-y-2">
            <SimpleTableHeading left="TOKEN" right="AMOUNT" />
            <div class="flex flex-col space-y-2">
                {#if $myRedeemBalances}
                    {#each $myRedeemBalances as balance, index}
                        <SimpleTableRow alt={index % 2}>
                            <SimpleTableCell column=1 of=2>
                                {balance.currency.symbol}
                            </SimpleTableCell>
                            <SimpleTableCell column=2 of=2>
                                {balance.toSignificant(8)}
                            </SimpleTableCell>
                        </SimpleTableRow>
                    {/each}
                {/if}
            </div>    
        </div>

        <Button on:click={closeModal} label="Ok" />

    </div>

{:else if txStatus == "error"}
    <span class="tx-lg font-medium">Error</span>
    <InlineNotification type="error">{errorMsg}</InlineNotification>
{/if}

