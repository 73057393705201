<script>
import { CurrencyAmount, Price, Token } from '@uniswap/sdk-core'

// modules
import { getContext } from 'svelte'
import { ethers } from 'ethers'

// ABIs
import seedERC20 from '../../abi/SeedERC20.json'

// constants
import { constants } from '../../constants/Constants'

// stores
import { signer, provider, providerSet, account } from '../Account.js'
import { tick } from '../Tick.js'
import { BN } from '../../utils/utils';

$: ({ 
        // contracts
        contractsWithSigner : {
            seeder : seederContract
        },
        contractsReadOnly : {
            seeder : seederContractReadOnly,
            reserveToken : reserveToken
        },
        // seed info
        seeder,
        seederUnits,
        seederUnitPrice,
        seederUnitSupply,
        seederUnitsAvail,
        seederBalance,
        seedERC20Balance,
        seedFeePerUnit,
        unseedDelay,
        userSeed,
        seederCurrency,
        reserveCurrency,
        seederFee
    } = getContext('trustStores'))

// init a ready only contract instance
$: if ($seeder && $providerSet) {
    setSeederContractReadOnly()
}

async function setSeederContractReadOnly() {
    // seeder contract
    var _seedERC0 = new ethers.Contract($seeder, seedERC20.abi).connect($provider)

    // try calling one of its methods first, if it fails it isn't a SeedERC20
    _seedERC0.seedPrice().then(async (response)=>{
        // set the store
        seederContractReadOnly.set(_seedERC0)
        // set the currency
        $seederCurrency = new Token(constants.CHAIN_ID, $seeder, 0, await _seedERC0.symbol(), await _seedERC0.name())
    }).catch((error) => {
        // console.error(error)
        $userSeed = true
    })
}

// if we have a signer and a read-only contract, init a separate instance
$: if ($seeder && $signer) {
    setSeederContract()
}

async function setSeederContract() {
    // seeder contract
    var _seeder = new ethers.Contract($seeder, seedERC20.abi)  
    $seederContract = _seeder.connect($signer)
}

// once we have the read-only contract, get the static seeder config
$: if ($seederContractReadOnly && $seederCurrency && $reserveCurrency) {
    getStaticSeederConfig()
}

async function getStaticSeederConfig() {
    $seederUnitPrice = new Price(
        {
            baseAmount: CurrencyAmount.fromRawAmount($seederCurrency, BN.from(1)),
            quoteAmount: CurrencyAmount.fromRawAmount($reserveCurrency, await $seederContractReadOnly.seedPrice())
        })
    $unseedDelay = await $seederContractReadOnly.cooldownDuration()
    $seederUnitSupply = CurrencyAmount.fromRawAmount($seederCurrency, await $seederContractReadOnly.totalSupply())
}

// refresh the amount of seeder available units
$: if ($seederContractReadOnly && $reserveToken && $reserveCurrency && $seederCurrency && $seeder && $tick) {
    getSeederAvailUnits()
}

async function getSeederAvailUnits() {
    $seederUnitsAvail = CurrencyAmount.fromRawAmount($seederCurrency, await $seederContractReadOnly.balanceOf($seeder))
    $seederBalance = CurrencyAmount.fromRawAmount($reserveCurrency, await $reserveToken.balanceOf($seeder))
}

// get the seed fee per seed token
$: if ($seederFee && $seederUnits && $seederCurrency) {
    const seederUnitsAsCurrency = CurrencyAmount.fromRawAmount($seederCurrency, BN.from($seederUnits))
    $seedFeePerUnit = new Price({baseAmount: seederUnitsAsCurrency, quoteAmount: $seederFee})
}

// get the users balance of the seedERC20
$: if ($seederContractReadOnly && $seederCurrency && $signer && $tick) {
    getSeedERC20Balance()
}

async function getSeedERC20Balance() {
    $seedERC20Balance = CurrencyAmount.fromRawAmount($seederCurrency, await $seederContractReadOnly.balanceOf($account))
}
</script>