<script>
import { CurrencyAmount, Fraction } from '@uniswap/sdk-core'

// svelte
import { getContext } from 'svelte'

// modules
import { ethers } from 'ethers'

// components
import TokenField from '../../../components/tokenfield/TokenField.svelte'
import Button from '../../../components/buttons/Button.svelte'
import Modal from '../../../components/modals/Modal.svelte'
import ConfirmRedeem from './ConfirmRedeem.svelte'
import GenericSkeletonLoader from '../../../components/loader/GenericSkeletonLoader.svelte'
import InlineNotification from '../../../components/notifications/InlineNotification.svelte'

// constants
import { constants} from '../../../constants/Constants'

// stores
import { account } from '../../../stores/Account'
import ReserveTokens from '../components/ReserveTokens.svelte';

$: ({
    myBalances,
    redeemableCurrency,
    myRedeemBalances,
    projectState,
    redeemBalances
} = getContext('trustStores'))

const BN = ethers.BigNumber
const zeroAmount = new Fraction(BN.from(0))

let balanceExceeded = false
let modal = false
let tokenField

let amountIn = zeroAmount
let val = 0

$: if ($redeemableCurrency) { amountIn = new CurrencyAmount($redeemableCurrency, BN.from(0)) }

$: allZero = $redeemBalances?.every?.(b => b.equalTo(zeroAmount))

function changed({detail: {amount, value}}) {
    amountIn = amount
    val = value
}
</script>

<div class="bg-white p-5 shadow-md border border-gray-200 relative rounded-2xl flex flex-col gap-y-4">

    <div class="text-xl font-semibold text-gray-800">Redeem tokens</div>

    {#if $projectState === 5 && $redeemableCurrency}
        <InlineNotification type="warning">
            This raise has failed, but if you are a holder of {$redeemableCurrency.symbol} you can redeem your tokens for a share of amount raised.
        </InlineNotification>
    {/if}

    <div class="text-sm">
        <!-- Tokens will be burned and the wrapped reserve will be transferred to your wallet. -->
        Your raise tokens will be burned equivalent to the amount of project tokens distributed to your wallet address along with any additional rewards.
        {#if allZero}
            <br/><br/>
            Please note that after the project raise is done, you will have to wait for the project creator to populate the pool with reserve tokens 
            before you can claim your raise tokens (rTKN) for the project tokens. If you go ahead and burn your rTKN before <b>{$redeemableCurrency.symbol}</b> 
            tokens are added to the redeemables pool, it will end in total loss of your rTKNs and you will not be able to claim them back at a future time. 
            Please stand by for an announcement regarding this on project's respective social channels.
        {/if}
    </div>
    {#if $account}
        <GenericSkeletonLoader show={$myRedeemBalances && $myBalances}>
            <TokenField 
            label="Redeem"
            currency={$redeemableCurrency}
            estimated={false}
            on:changed={changed}
            tokenSymbol={$redeemableCurrency.symbol}
            maxAmt={$myBalances.redeemableBalance}
            tokenIcon={constants.redeemableIcon}
            bind:balanceExceeded={balanceExceeded}
            bind:this={tokenField}
            disabled={allZero}
            />
        </GenericSkeletonLoader>
    {/if}
    {#if $account}
        <Button disabled={amountIn.equalTo(zeroAmount) || balanceExceeded} on:click={()=>{modal = true}} variant="primary" label="Redeem" />
    {:else}
        <Button on:click={account.connect} variant="secondary" label="Connect wallet" />
    {/if}
    <ReserveTokens />
</div>

<!-- Redeem confirmation modal -->
{#if modal}
<Modal on:close={()=>{modal = false}}>
    <ConfirmRedeem
        on:close={()=>{modal = false}}
        on:complete={tokenField.clear()}
        tokenAmt={amountIn}
        tokenAmtDisplay={val}
        tokenIcon={constants.redeemableIcon}
        tokenSymbol={$redeemableCurrency.symbol}
    />
</Modal>
{/if}
