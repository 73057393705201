<script>
import { getContext } from "svelte"
import SocialsList from './../../../components/socials/SocialsList.svelte'

let stores = getContext('trustStores')
$: ({
    metadata
} = stores)

</script>

<div class="grid grid-cols-3 gap-x-4 gap-y-8 mt-4">
    {#each $metadata.partners as partner}
        <div class="space-y-4 flex flex-col">
            <div class="w-full aspect-w-16 aspect-h-12">
                <img class="object-cover w-full rounded-md" src={partner.image} />
            </div>
            <div>
                <div class="font-medium">
                    {partner.name}
                </div>
            </div>
            {#if partner?.bio}
                <div class="font-base text-gray-800">
                    {partner.bio}
                </div>
            {/if}
            {#if partner?.socials}
                <div class="flex flex-row">
                    <SocialsList socials={partner.socials} />
                </div>
            {/if}
        </div>
    {/each}
</div>