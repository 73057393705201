<script>
import {link} from 'svelte-spa-router'
import active from 'svelte-spa-router/active'

export let to = "";
  
</script>
  
<a href={'/' + to} use:link use:active={{className: 'active-nav', inactiveClassName: 'inactive-nav'}}>
  <slot />
</a>

<style>
  @layer base {

  }
</style>