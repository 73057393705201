<script>
    import { onMount } from 'svelte';

    // stores
    import { account, isMM } from '../../stores/Account'
    
    export let kycMessage = 'Check KYC Status'
    export let tooltip = '';

    $: kycStatus = kycMessage
    $: title = tooltip

    </script>
    
    {#if $isMM === 1}
    <div>
        <!-- {#if $account} -->
            <div class="text-sm" style="text-decoration: underline; color: white; text-align: center;">
                <a href='#/kyc' title="{title}">
                    {kycStatus}
                </a>
            </div>
        <!-- {/if} -->
    </div>
    {/if}
    