<script>
import AllTransactions from '../../views/project/fund/AllTransactions.svelte'
import ProgressBar from './ProgressBar.svelte'

export let progress = 0
export let total = 0
export let color = 'green'
export let height = 4

$: props = {
    progress : progress,
    total : total,
    color : color,
    height : height
}

export let label = 'Amount raised'
export let labelValue = '2000 of 5000 USDC'

</script>

<div class="w-full">
    <div class="w-full flex justify-between mb-1.5">
        {#if label}<span class="text-sm font-normal text-gray-400">{label}</span>{/if}
        {#if labelValue}<span class="text-sm font-medium text-gray-600 text-right">{labelValue}</span>{/if}
    </div>

    <ProgressBar {...props}/>
</div>

