<script>
import { getContext } from 'svelte'

import Contracts from './Contracts.svelte'
import Transactions from './Transactions.svelte'
import MyBalances from './MyBalances.svelte'
import SwapData from './SwapData.svelte'
import TopHolders from './TopHolders.svelte'
import RaiseProgress from './RaiseProgress.svelte'
import Redeemables from './Redeemables.svelte'
import TrustInfo from './TrustInfo.svelte'
import SpotPrice from './SpotPrice.svelte'
import Seeder from './Seeder.svelte'
import TimeRemaining from './TimeRemaining.svelte'
import AmountRaised from './AmountRaised.svelte'
import ReserveAndRedeemable from './ReserveAndRedeemable.svelte'
import Swap from './Swap.svelte';
import ProjectMetadata from './ProjectMetadata.svelte'
import Tiers from './Tiers.svelte';

let stores = getContext('trustStores')
let projectState = stores.projectState

</script>

<RaiseProgress />
<ProjectMetadata />
<Contracts />
<ReserveAndRedeemable />
<TrustInfo />

{#if $projectState === 0}
<MyBalances />
<Seeder />

{:else if $projectState === 2 || $projectState === 3}
<MyBalances />
<Tiers />
<AmountRaised />
<TimeRemaining />
<SpotPrice />
<Transactions />
<SwapData />
<Swap />
<TopHolders />
<Redeemables />

{:else if $projectState === 4 || $projectState === 5}
<MyBalances />
<Tiers />
<AmountRaised />
<TimeRemaining />
<SpotPrice />
<Transactions />
<SwapData />
<Swap />
<TopHolders />
<Redeemables />
<Seeder />
{/if}