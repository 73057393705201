import { writable, get } from 'svelte/store'
import { ethers } from 'ethers'

// constants
import { constants } from '../constants/Constants'

// modules
import detectEthereumProvider from '@metamask/detect-provider'

// store for the provider
export const provider = writable(0)
// store for the signer
export const signer = writable(0)
// store to react against - sometimes the provider itself updates after
// every call so reacting to it triggers more downstream stuff than necessary
export const providerSet = writable(0)
// block number store
export const blockNumber = writable(0)
// if user has mm installed
export const isMM = writable(0)
// state for when the chain id is incorrect
export const incorrectChain = writable(0)
// state to open the connect modal
export const connectModal = writable(0)

main()

async function main(){
  const _provider = await detectEthereumProvider()
  // if we have mm
  if (_provider === window.ethereum) {
    isMM.set(1)
    // subscribe to network changes and reload
    ethereum.on('chainChanged', (_chainId) => window.location.reload())
    // if the chain is correct
    if (await chainIdOk()) {
      if (constants.PROVIDER_RPC) {
        provider.set(new ethers.providers.JsonRpcProvider(constants.PROVIDER_RPC))
      } else {
        provider.set(new ethers.providers.Web3Provider(window.ethereum))
      }
      providerSet.set(1)
    }
  } else {
    isMM.set(-1)
    // @TODO: making a link we can use on mobile to send people to the web3 browser
    const hostname = window.location.host
    const deeplink = 'https://metamask.app.link/dapp/' + hostname + '/'
  }
}

async function chainIdOk(){
  const chainId = await ethereum.request({ method: 'eth_chainId' })
  if (constants.CHAIN_ID !== parseInt(chainId)) {
    incorrectChain.set(true)
  } else {
    return true
  }
}

// account store and connect
const accountStore = writable(0)

async function connectWeb3() {
    // connect
    ethereum
      .request({ method: 'eth_requestAccounts' })
      .then(
        (accounts)=>{accountStore.set(accounts[0])
        connectModal.set(0)
      })
      .catch((err) => {
        if (err.code === 4001) {
          // EIP-1193 userRejectedRequest error
          // If this happens, the user rejected the connection request.
          console.log('Please connect to MetaMask.')
        } else {
          console.error(err)
        }
    })

    // change the account store when the account is changed in metamask
    ethereum.on('accountsChanged', function (accounts) {
      accountStore.set(accounts[0])
    });  
}

async function openConnectModal(){
  connectModal.set(1)
}

export const account = {
  subscribe: accountStore.subscribe,
  connect: openConnectModal,
  connectWeb3: connectWeb3
}


account.subscribe((accounts)=>{
  if (accounts !== 0) {
    var _provider = new ethers.providers.Web3Provider(window.ethereum);
    // The Metamask plugin also allows signing transactions to
    // send ether and pay to change state within the blockchain.
    // For this, you need the account signer...
    signer.set(_provider.getSigner())
  }
})
