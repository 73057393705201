<script lang="ts">
    // svelte
    import { getContext } from "svelte"
    
    // components
    import Button from "../../../components/buttons/Button.svelte"
    import GenericSkeletonLoader from "../../../components/loader/GenericSkeletonLoader.svelte"
    import SimpleTableHeading from "../../../components/simpletable/SimpleTableHeading.svelte"
    import Modal from './../../../components/modals/Modal.svelte'
    import AddReserveModal from './AddReserveModal.svelte'
    import RedeemableFull from "../claim/RedeemableFull.svelte"
    
    // stores
    import { account } from './../../../stores/Account.js'
    let stores = getContext('trustStores')
    
    $: ({
        myBalances,
        myRedeemBalances,
        redeemableCurrency,
        creator,
        projectState,
        redeemShares
    } = stores)
    
    export let redeemBalance
    let modal
    
    </script>
    
    {#if $account}
        <div class="flex flex-col pt-6 gap-y-4 border-t border-gray-200">
            <div>
                <div class="text-gray-700 font-medium text-lg">Tokens in the reserve</div>
            </div>
    
                <div class="text-sm text-gray-500 mb-4">
                    Your share of the reserve tokens is proportional to your share of {$redeemableCurrency.symbol} total supply, including unsold tokens. 
    
                    {#if $projectState === 2 || $projectState === 3}
                        At the end of the raise unsold {$redeemableCurrency.symbol} will be burned, 
                        so your share will reflect your proportion of sold {$redeemableCurrency.symbol} only.
                    {/if}
                </div>
                <div>
                <SimpleTableHeading columns={['TOKEN', 'RESERVE BALANCE', 'YOUR SHARE']} />
    
                <GenericSkeletonLoader show={$redeemShares && $myRedeemBalances}>   
                    <div class="min-h-6">
                        {#if $myRedeemBalances && $redeemShares}
                            {#each $myRedeemBalances as balance, i}
                                <RedeemableFull index={i}/>
                            {/each}
                        {/if}
                    </div> 
                </GenericSkeletonLoader>
                </div>
            {#if $account && $account.toUpperCase?.() == $creator.toUpperCase?.()}
                <Button label="Add a reserve token" on:click={()=>{modal = true}}/>
            {/if}
        </div>
    {/if}
    
    {#if modal}
        <div>
            <Modal on:close={()=>{modal = false}}>
                <AddReserveModal />
            </Modal>
        </div>
    {/if}