<script>
import Modal from './../../../components/modals/Modal.svelte';
import { account } from './../../../stores/Account.js';
import { getContext } from 'svelte';
import Button from '../../../components/buttons/Button.svelte';
import TokenField from './../../../components/tokenfield/TokenField.svelte';
import UnseedModal from './UnseedModal.svelte';
import { BigNumber } from '@ethersproject/bignumber';
import { ethers } from 'ethers';

let stores = getContext('trustStores')

$: ({
    // contracts
    reserveCurrency,
    // balances
    myBalances,
    // seeder info
    seederUnitPrice,
    seederUnitsAvail,
    seederCurrency,
    seedERC20Balance
} = stores)

let tokenField

// props for the token field
$: tokenFieldProps = {
    label : "Refund",
    tokenSymbol : "seedTKN",
    maxAmt : $seedERC20Balance,
    showBalance : true,
    currency : $seederCurrency
}

// when the value in the token field changes
let errorMsg
let value, amount

$: if (amount && value && $myBalances && $seederUnitPrice && $seederUnitsAvail && $reserveCurrency) { validateVal() }

function updateVal({detail}) {
    ({ amount, value } = detail)
}

function validateVal() {
    errorMsg = false
    if (value % 1) {
        errorMsg = "Sorry, you must refund whole seed tokens"
    } else {
        if (amount.greaterThan($seedERC20Balance)) {
            errorMsg = "You don't have enough seedTKN"
        }
    }
} 

// modal stuff
let unseedModal
function openUnseedModal() { unseedModal = true }
function closeModal() { unseedModal = false }

</script>

{#if $account && $seedERC20Balance?.toExact() > 0}
    <div class="w-full space-y-8 border border-gray-200 bg-white filter rounded-2xl shadow-md p-5 flex flex-col">
        <div class="text-gray-900 font-medium text-2xl">Refund seed</div>

        <TokenField
            {...tokenFieldProps}
            on:changed={updateVal}
            bind:this={tokenField}
        />

        {#if $account}
            {#if errorMsg}
                <Button label={errorMsg} on:click={account.connect} disabled/>
            {:else}
                <Button label={"Refund"} on:click={openUnseedModal} disabled={!parseInt(value)}/>
            {/if}
        {/if}
    </div>

    {#if unseedModal}
    <Modal on:close={closeModal}>
        <UnseedModal 
            on:close={closeModal}
            on:complete={tokenField.clear()}
            amount={amount} 
        />
    </Modal>
    {/if}
{/if}