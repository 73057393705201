<script>
import { getContext } from 'svelte'
import { CurrencyAmount } from '@uniswap/sdk-core';

// stores
import { account } from '../Account'
import { tick } from '../Tick'

$: ({
    contractsReadOnly : {
        bPool: bPool,
        redeemableToken: redeemableToken,
        reserveToken: reserveToken
    },
    myBalances,
    reserveCurrency,
    redeemableCurrency,
    reserveAllowance,
    redeemableAllowance
} = getContext('trustStores'))

let reserveBalance,
    redeemableBalance

async function getMyBalances() {
    reserveBalance = CurrencyAmount.fromRawAmount($reserveCurrency, await $reserveToken.balanceOf($account))
    redeemableBalance = CurrencyAmount.fromRawAmount($redeemableCurrency, await $redeemableToken.balanceOf($account))
    
    // update the store
    myBalances.update(data => ({
        ...data,
        reserveBalance,
        redeemableBalance,
    }))
}

async function getAllowancesForPool() {
    $reserveAllowance = CurrencyAmount.fromRawAmount($reserveCurrency, await $reserveToken.allowance($account, $bPool.address))
    $redeemableAllowance = CurrencyAmount.fromRawAmount($redeemableCurrency, await $redeemableToken.allowance($account, $bPool.address))
}

$:  if ($account && $redeemableCurrency && $reserveCurrency && $redeemableToken && $reserveToken && !(($tick / 2) % 1)) {
        getMyBalances()
    }

$:  if ($account && $bPool && $redeemableCurrency && $reserveCurrency && $redeemableToken && $reserveToken && !(($tick / 2) % 1)) {
    getAllowancesForPool()
}
</script>