<script>
import SubHeader from './../../layout/header/SubHeader.svelte';
import ClaimCard from './claim/ClaimCard.svelte';
import ProjectList from '../../components/projectlist/ProjectList.svelte'
import Main from '../../layout/Main.svelte'
import LoadingAnim from '../../components/util/LoadingAnim.svelte';

import { curation, site } from '../../stores/Site'
import {constants} from '../../constants/Constants'

let numberOfProjects, noProjects

</script>

<SubHeader>
    <div class="w-full pt-12">
        <div class="text-4xl font-medium text-gray-900 pb-8">
            Claim rewards
        </div>
        {#if constants.HEADER_CONTENT}
            <div class="text-1xl font-regular text-gray-700 pb-8 prose">
                {constants.HEADER_CONTENT.claim}
            </div> 
        {/if}
    </div>
</SubHeader>

<Main>
    {#if noProjects}
        <div class="text-gray-600 flex-grow flex flex-col justify-center items-center h-full">
            {#if ($site == 'polinate' || $site == 'polinateProd')}
            There are no projects to claim rewards at this time. All the projects who successfully raise the target funds will show up here for the investors to claim rewards.
            {/if}
            {#if ($site == 'polygen' || $site == 'polygenProd')}
            There are no projects to claim rewards at this time. All the projects who successfully raise the target funds will show up here for the investors to claim rewards.
            {/if}
        </div>
    {:else if $curation}
        <div class="w-full hidden grid-cols-3 gap-5 pt-7" class:showGrid={numberOfProjects}>
            <ProjectList projects={$curation.curatedProjects} bind:noProjects bind:numberOfProjects statesToFilterBy={[4, 5]}>
                <ClaimCard />
            </ProjectList>
        </div>

        <div class="flex-grow flex flex-col justify-center items-center h-full" class:hideLoader={numberOfProjects}>
            <LoadingAnim width='200px' />
        </div>
    {/if}
</Main>

<style>
    .showGrid {
        @apply grid;
    }

    .hideLoader {
        @apply hidden;
    }
</style>

