<script>
	import { getContext } from 'svelte';
	import { TABS } from './Tabs.svelte';

	const tab = {};
	const { registerTab, selectTab, selectedTab } = getContext(TABS);

	registerTab(tab);
</script>

<style>
	.selected {
		@apply border-b border-gray-800 text-gray-800
	}
</style>

<a class="text-gray-400 font-medium pb-2 border-b border-gray-200 cursor-pointer text-sm" class:selected="{$selectedTab === tab}" on:click="{() => selectTab(tab)}">
	<slot></slot>
</a>
<div class="w-5 border-b border-gray-200"></div>