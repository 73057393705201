<script>
// modules
import { deployTrust } from "./create-util"
import { ethers } from 'ethers'
import { link } from 'svelte-spa-router'

// components
import Main from '../../layout/Main.svelte'
import Button from '../../components/buttons/Button.svelte'
import Input from '../../components/form/Input.svelte'
import FormPanel from '../../components/form/FormPanel.svelte'
import LoadingAnim from '../../components/util/LoadingAnim.svelte'
import IconLibrary from '../../components/icons/IconLibrary.svelte'
import InlineNotification from '../../components/notifications/InlineNotification.svelte'
import Modal from '../../components/modals/Modal.svelte'

// stores
import { account, provider } from '../../stores/Account'

// constants
import { Fraction, Token } from "@uniswap/sdk-core"
import { constants } from "../../constants/Constants"

// util
import { currencyAmountFromValue, toRaw } from "../../utils/utils"

import RESERVE_TOKEN from '../../abi/ERC20.json'

const BN = ethers.BigNumber

let trust
let txStatus
let errorMsg

// trust config
$: creatorAddress = $account || ""
let raiseDuration = 130000
let seederUnits = 200
let unseedDelay = 1000
let minimumTradingDuration = 10;

// redeemable config
let tokenName = 'Token'
let tokenSymbol = 'TKN'
let totalTokenSupplyVal = '2000'
let initRedeemVal = 1

// prestige
let tier = constants.TIER_DEFAULT
let minimumStatus = 0

// pool config
let reserveAddress = constants.RESERVE_DEFAULT
let reserveInitVal = 2000
let initTknPrice = 10
let minCreatorRaiseVal = 100
let seederFeeVal = 100

let reserveToken, reserveCurrency, reserveInit, initialValuation, totalTokenSupply, minCreatorRaise, seederFee, finalValuation, redeemInit

$: redeemableCurrency = new Token(constants.CHAIN_ID, ethers.constants.AddressZero, 18, tokenSymbol, tokenName)

$: if (reserveAddress) {(async () => {
        reserveToken = new ethers.Contract(reserveAddress, RESERVE_TOKEN.abi).connect($provider)
        reserveCurrency = new Token(constants.CHAIN_ID, reserveAddress, await reserveToken.decimals(), await reserveToken.symbol(), await reserveToken.name())
    })()
}

$: if (reserveCurrency && redeemableCurrency) {

    minCreatorRaise = toRaw(currencyAmountFromValue(reserveCurrency, minCreatorRaiseVal))
    seederFee = toRaw(currencyAmountFromValue(reserveCurrency, seederFeeVal))
    reserveInit = toRaw(currencyAmountFromValue(reserveCurrency, reserveInitVal))

    const initialValuationVal = initTknPrice * totalTokenSupplyVal
    initialValuation = toRaw(currencyAmountFromValue(reserveCurrency, initialValuationVal))

    totalTokenSupply = toRaw(currencyAmountFromValue(redeemableCurrency, totalTokenSupplyVal))

    const totalTokenSupplyFraction = new Fraction(BN.from(totalTokenSupplyVal))
    const initRedeem = currencyAmountFromValue(reserveCurrency, initRedeemVal)
    console.log(initRedeem)
    redeemInit = toRaw(initRedeem.multiply(totalTokenSupplyFraction))
    
    finalValuation = redeemInit.add(minCreatorRaise).add(seederFee).add(reserveInit)
}

const erc20Config = { name: "Token", symbol: "TKN" };
const seedERC20Config = { name: "SeedToken", symbol: "SDT" };


// pass these to the deployment function
$: trustConfig = {
        creator: creatorAddress,    
        minimumCreatorRaise: minCreatorRaise,
        seeder: ethers.constants.AddressZero,
        seederFee: seederFee,
        seederUnits: seederUnits,
        seederCooldownDuration: unseedDelay,
        // raiseDuration: raiseDuration,
        redeemInit,
        seedERC20Config: 
        {
            name: "SeedToken",
            symbol: "SDT"
        }
        }

$: redeemableConfig = {
        erc20Config:
        {
            name: tokenName,
            symbol: tokenSymbol
        },
        tier,
        minimumStatus,
        totalSupply: totalTokenSupply,
        }

$: poolConfig = {
        reserve: reserveAddress,
        reserveInit: reserveInit,
        initialValuation: initialValuation,
        finalValuation: finalValuation,
        minimumTradingDuration: BN.from(raiseDuration),
        }

async function deploy() {

    txStatus = 'waiting-on-signature'

    console.log(trustConfig, redeemableConfig, poolConfig)
    
    deployTrust(trustConfig, redeemableConfig, poolConfig)
        .then((_trust)=>{
            txStatus = 'deployed'
            trust = _trust
        })
        // .catch((error)=>{

        //     txStatus = 'error'
        //     errorMsg = error.message
        // })
}

</script>

<Main>

<div class="flex flex-col space-y-10 my-20 w-1/2">

<span class="text-3xl font-semibold text-gray-900">
    Create a project
</span>

<FormPanel heading="Redeemable">

    <Input bind:value={tokenName}>
        <span slot="label">Your redeemable token needs a name</span>
    </Input>

    <Input bind:value={tokenSymbol}>
        <span slot="label">...and a symbol (3 letters)</span>
    </Input>

    <Input type='number' bind:value={totalTokenSupplyVal}>
        <span slot="label">How many p{tokenSymbol} would you like to mint and sell?</span>
    </Input>

</FormPanel>


<FormPanel heading="Raise parameters">

    <Input bind:value={creatorAddress}>
        <span slot="label">Which address should be the recipient of this funds raised?</span>
    </Input>

    <Input bind:value={reserveAddress}>
        <span slot="label">Which token would you like to raise?</span>
    </Input>

    <Input type='number' bind:value={minCreatorRaiseVal}>
        <span slot="label">What is the minimum you'd like to raise?</span>
    </Input>

    <Input type='number' bind:value={initTknPrice}>
        <span slot="label">What price should p{tokenSymbol} be intially?</span>
    </Input>

    <Input type='number' bind:value={initRedeemVal}>
        <span slot="label">What should be the initial redemption value for p{tokenSymbol}?</span>
    </Input>

    <Input type='number' bind:value={raiseDuration}>
        <span slot="label">How long should this raise run for? (in blocks, 1 block = ~2 seconds)</span>
    </Input>

    <Input bind:value={tier}>
        <span slot="label">A Tier contract to gate this raise.</span>
    </Input>

    <Input type='number' bind:value={minimumStatus}>
        <span slot="label">The minimum tier an address needs to have to interact with this trust.</span>
    </Input>

</FormPanel>

<FormPanel heading="Seed">

    <Input type='number' bind:value={seederUnits}>
        <span slot="label">How many seed tokens would you like to create?</span>
    </Input>

    <Input type='number' bind:value={unseedDelay}>
        <span slot="label">How long before seeders can refund their seed tokens (in blocks)?</span>
    </Input>

    <Input type='number' bind:value={seederFeeVal}>
        <span slot="label">What fee would you like the seeders to receive?</span>
    </Input>

    <Input type='number' bind:value={reserveInitVal}>
        <span slot="label">How much reserve would you like to seed the pool with?</span>
    </Input>

</FormPanel>

{#if $account}
<Button on:click={deploy} label="Deploy trust"/>
{:else}
<Button on:click={account.connect} label="Connect wallet" variant="secondary" />
{/if}

</div>

{#if txStatus}
    <Modal on:close={()=>{txStatus=null}}>
        {#if txStatus == "waiting-on-signature"}
        <div class="w-full flex flex-col justify-center items-center">
            <LoadingAnim />
            Waiting on signature...
        </div>
        <!-- {:else if txStatus == "deploying"}
        <LoadingAnim />
        <div class="w-full flex flex-col justify-center items-center">
            <div>Deploying project...</div>
            <a target="_blank" href={constants.BLOCK_EXPLORER_TRANSACTION + trust.deployTransaction.hash}>
                <span class="mr-1">See transaction</span>
                <IconLibrary inline width=15 icon="link" color="text-gray-800" />
            </a>
        </div> -->
        {:else if txStatus == "deployed"}
        <div class="w-full flex flex-col justify-center items-center space-y-5">
            <div class="font-lg font-semibold">Project created</div>
            <div>This project has been deployed!</div>
            <div>
                <a href={"/project/" + trust.address} use:link>
                    <span class="mr-1">Go to project</span>
                    <IconLibrary inline width=15 icon="link" color="text-gray-800" />
                </a>
            </div>
        </div>
        {:else if txStatus == "error"}
        <div class="tx-lg font-medium">Error</div>
        <InlineNotification type="error">{errorMsg}</InlineNotification>
        {/if}
    </Modal>
{/if}

</Main>