<script>
import Button from './../../../../components/buttons/Button.svelte';
import IconLibrary from './../../../../components/icons/IconLibrary.svelte';
import SlippageButton from './SlippageButton.svelte'

import { createEventDispatcher } from "svelte"
import { fade } from 'svelte/transition'

// Event dispatcher
const dispatch = createEventDispatcher();

// Dispatch close event for the modal
function closeModal() {
  dispatch("close");
}

let _slippage
export let slippage = 5
$: if (_slippage) { slippage = _slippage }

$: likelyToRevert = (slippage < 0.01 && slippage !== null) ? true : false

</script>


    <h4 class="font-medium text-lg">Settings</h4>
    <div class="text-sm text-gray-600">Set slippage</div>

    <div class="flex flex-row space-x-4">
        <SlippageButton bind:slippage={_slippage} value=0.5/>
        <SlippageButton bind:slippage={_slippage} value=1/>
        <SlippageButton bind:slippage={_slippage} value=2/>
        <SlippageButton bind:slippage={_slippage} value=5/>
        <div class="flex flex-row border border-gray-200 rounded-md p-2 text-sm">
            <input
                bind:value={_slippage}
                on:blur={()=>{if (_slippage==null) {_slippage = 5}}}
                type="number"
                class="w-10 flex-shrink "
                placeholder="1.0"
                >
            <span>%</span>
        </div>
    </div>

    {#if likelyToRevert}
        <div 
            transition:fade={{duration:150}}
            class="text-sm text-gray-600 flex flex-row items-center"
        >
            <IconLibrary icon="alert" width=20 color="text-gray-400" />
            <div class="ml-2">Your transaction is likely to revert.</div>
        </div>
    {/if}
    
    <Button on:click={closeModal} label="Save" />


<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}</style>