<script>
// svelte
import { getContext, onDestroy } from 'svelte'
import { get } from 'svelte/store'

// stores
import { blockSpeed, lastBlockReq } from './../BlockSpeed.js'
import { provider } from './../Account'

let stores = getContext('trustStores')
let trustBounds = stores.trustBounds
let timeRemaining = stores.timeRemaining
let timeSpent = stores.timeSpent

let interval, tick = 1

$: if ($blockSpeed && $trustBounds && $provider && tick && $lastBlockReq) {
    updateTimeRemaining()
}

function updateTimeRemaining() {
    const timeSinceLastRequest = Date.now() - $lastBlockReq.timestamp
    $timeRemaining = ($blockSpeed * ($trustBounds.endBlock - $lastBlockReq.block) * 1000 - timeSinceLastRequest)
    $timeSpent = ($blockSpeed * ($lastBlockReq.block - $trustBounds.startBlock) * 1000 + timeSinceLastRequest)
}

interval = setInterval(()=>{tick++}, 1000)

onDestroy(() => clearInterval(interval));

</script>