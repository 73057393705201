<script>
    import SubHeader from './../../layout/header/SubHeader.svelte';
    import Main from '../../layout/Main.svelte'
    import {constants} from '../../constants/Constants'

    </script>
    
    <SubHeader>
        <div class="w-full pt-12">
            <div class="text-4xl font-medium text-gray-900 pb-8">
                Create Project
            </div>
            {#if constants.HEADER_CONTENT?.new}
                <div class="text-1xl font-regular text-gray-700 pb-8 prose">
                    {constants.HEADER_CONTENT.new}
                </div> 
            {/if}
        </div>
    </SubHeader>
    
    <Main>
        <div style="text-align: left;">
            <div class="text-2xl font-medium text-gray-900 pt-10 pb-2">
                Welcome to Polygen!
            </div>
            <div>
                Coming soon in our next release, Projects will have the ability to fund raise in a completely decentralised manner - No Gatekeepers! Anyone can launch any project and create their own customised raise.  Polygen will allow Projects to innovate and experiment with complete freedom.
            </div>
            <div class="text-2xl font-medium text-gray-900 pt-10 pb-2">
                How will it work?
            </div>
            <div>
                The entire process will be on chain and controlled by the Project.  After successfully completing our Know Your Business (KYB) process, Projects can load all their metadata – Project description, Team members, social feeds, website, whitepaper, project parameters (amount being raised), liquidity seeding rewards, project duration.   That’s it! 
            </div>
            <div class="pt-8">
                <!-- To learn more, you can read our whitepaper <a href="javascript:void(0);" target="_blank" style="text-decoration: underline;">here</a> -->
            </div>

            <div class="text-2xl font-medium text-gray-900 pt-10 pb-2">
                Can’t wait for our next release?
            </div>
            <div>
                We understand! If you want to get your project live as soon as possible, you can start the process today. Click the link below to complete your Project application. We will review all Projects submitted during our V1 Beta launch and feature a select few this year.  But don’t worry, Projects will soon be able to launch on their own in our next release.
                <br/><br/>
                <a href="https://forms.gle/Jqm9nLwwawrRyLVYA" target="_blank" style="text-decoration: underline;">Click here to apply for a new project</a>
            </div>
        </div>
    </Main>
    