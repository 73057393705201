<script>
import { onMount } from 'svelte'
import GenericSkeletonLoader from "../loader/GenericSkeletonLoader.svelte"

export let image
export let name

let imageEl, loaded

onMount(() => {
    imageEl.onload = () => {
      loaded = true
    }
})

</script>

<div class="p-4">
    <GenericSkeletonLoader show={loaded}>
        <div class="aspect-w-5 aspect-h-3 mb-3">
            <img class="object-cover" bind:this={imageEl} src="{image}" />
        </div>
    </GenericSkeletonLoader>
    <div class="text-md">{name}</div>
</div>
