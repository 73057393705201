<script>
import { getContext } from "svelte"
import Tier from "../../../components/tiers/Tier.svelte"

let stores = getContext('trustStores')
$: ({
    metadata,
    myBalances,
    myTier,
    redeemableCurrency
} = stores)

</script>

<div class="py-5">
    Buy <b>{$redeemableCurrency?.symbol}</b> to be eligible for the creator’s rewards below.
</div>

<div class="space-y-5">
    {#each $metadata.rewards.tiers as tier}
        <Tier balance={$myBalances?.redeemableBalance} rewards={$metadata.rewards} {tier} {myTier} currency={$redeemableCurrency}/>
    {/each}
</div>
