<script>
// svelte
import { getContext } from 'svelte'
import { push } from 'svelte-spa-router'

// components
import ProjectCreator from '../../project/components/ProjectCreator.svelte'
import Button from '../../../components/buttons/Button.svelte'
import GenericSkeletonLoader from '../../../components/loader/GenericSkeletonLoader.svelte';
import SmallBar from '../../../components/progressbar/SmallBar.svelte';
import ImageAndName from '../../../components/projectcard/ImageAndName.svelte';

// stores
import Seeder from '../../../stores/project/Seeder.svelte';
import TrustInfo from '../../../stores/project/TrustInfo.svelte';
import ReserveAndRedeemable from '../../../stores/project/ReserveAndRedeemable.svelte'

// stores
$: ({
    metadata,
    trustAddress,
    seederUnitSupply,
    seederUnitsAvail,
    reserveCurrency,
    seederBalance,
    projectState
} = getContext('trustStores'))

$: addressLink = '/project/' + $trustAddress

// props for the progress bar and label
$: barProps = {
    color : 'green',
    total : $seederUnitSupply.toSignificant?.(8),
    progress : $seederUnitSupply.subtract?.($seederUnitsAvail).toSignificant(8),
    height: 4,
    label: "Amount seeded",
    labelValue: $seederBalance.toSignificant?.(8) + ' ' + $reserveCurrency.symbol
}

// fully seeded props
const fullySeededBarProps = {
    color : 'green',
    total : 1,
    progress : 1,
    height: 4,
    label: "Fully seeded",
    labelValue: ""
}

// only show the bar when we have all the data
$: showBar = ($reserveCurrency.symbol && $seederUnitSupply) ? true : false

</script>

<div on:click={push(addressLink)} class="h-full flex flex-col bg-white shadow-sm border border-gray-200 relative rounded-2xl cursor-pointer">
    <ImageAndName name={$metadata.name} image={$metadata.image}/>
    <div class="flex flex-row justify-between px-4 pb-4">
        <ProjectCreator />
        {#if $projectState == 1}
            <Button small label="Start raise"/>
        {:else}
            <Button small label="Seed"/>
        {/if}
    </div>

    <div class="border-t border-gray-200 p-4 space-y-4">
        {#if $projectState == 1}
            <SmallBar {...fullySeededBarProps} />
        {:else}
            <GenericSkeletonLoader show={showBar}>
                <SmallBar {...barProps} />
            </GenericSkeletonLoader>
        {/if}
    </div>
</div>

<!-- stores calc -->
<TrustInfo />
<ReserveAndRedeemable />
<Seeder />