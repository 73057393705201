<script>
    import { createEventDispatcher } from "svelte";

    import { fade, fly } from 'svelte/transition'
    
    // Event dispatcher
    const dispatch = createEventDispatcher();
    
    // Dispatch close event for the modal
    function closeModal() {
      dispatch("close");
    }

</script>

<div class="z-10 fixed inset-0 flex flex-row justify-center items-center">
  <div transition:fly="{{y:20, duration: 100}}" class="bg-white p-5 shadow-sm border border-gray-200 rounded-2xl space-y-4 flex-shrink z-10">
    <slot></slot>
  </div>

  <div transition:fade="{{duration: 100}}" class="absolute inset-0 bg-opacity-50 bg-gray-700 backdrop-filter backdrop-blur-sm" on:click={closeModal}></div>
</div>
