<script>
import Pill from "../Pill.svelte"

export let id
export let items

$: reward = items.find(_item => _item.id == id)
</script>

<div class="space-y-2">
    <div class="relative">
        <div class="aspect-w-1 aspect-h-1">
            <img class="rounded-md object-cover" src={reward.image} />
        </div>
        <div class="absolute top-2 right-2">
            <Pill>{reward.type}</Pill>
        </div>
    </div>
    <div class="text-md font-medium">{reward.title}</div>
    <div class="space-y-1">
        {#if reward.delivery_date !== ""}
        <div class="text-xs font-medium text-gray-500">Expected delivery</div>
        <div class="text-md font-medium">{reward.delivery_date}</div>
        {/if}
    </div>
    <div class="text-sm">{reward.description}</div>
</div>