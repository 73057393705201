<script>
import { getContext } from "svelte"
import IconLibrary from "../../../components/icons/IconLibrary.svelte";
import SocialIcon from "../../../components/socials/SocialIcon.svelte";
import MarkdownProse from "./MarkdownProse.svelte"

let stores = getContext('trustStores')

$: ({
    metadata
} = stores)

</script>

<div class="space-y-5 pt-8 flex flex-col">
    <!-- <img src={$metadata?.creatorLogo} class="w-56" /> -->
    <div class="flex flex-row flex-wrap gap-4">
        {#each Object.entries($metadata?.channels) as [type, link]}
            <SocialIcon labelled type={type} link={link} />
        {/each}
    </div>

    <!-- <SocialIcon label="Project website" width=19 link={$metadata.projectURL} type="website" /> -->
    {#if $metadata?.description}
        <MarkdownProse src={$metadata?.description} />
    {:else if $metadata?.description_inline}
        <div class="prose mt-4">
            {@html $metadata?.description_inline}
        </div>
    {/if}

    {#if $metadata?.whitepaperURL}
    <a class="font-medium flex flex-row space-x-2 items-center" target="_blank" href={$metadata?.whitepaperURL}>
        <span>Read the project whitepaper</span>
        <IconLibrary icon="link" width=15 color="text-gray-700" />
    </a>
    {/if}
</div>
