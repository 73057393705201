<script>
import SubHeader from './../../layout/header/SubHeader.svelte';
import Main from '../../layout/Main.svelte'
import UpcomingProjectCard from './upcoming/UpcomingProjectCard.svelte';

import { curation, site } from '../../stores/Site'
import {constants} from '../../constants/Constants'

$: projects = $curation.comingSoonProjects

</script>

<SubHeader>
    <div class="w-full pt-12">
        <div class="text-4xl font-medium text-gray-900 pb-8">
            Upcoming Projects
        </div>
        {#if constants.HEADER_CONTENT?.upcoming}
            <div class="text-1xl font-regular text-gray-700 pb-8 prose">
                {constants.HEADER_CONTENT.upcoming}
            </div> 
        {/if}
        <!-- <div class="">
            <Tags tags={tags}/>
        </div> -->
    </div>
</SubHeader>

<Main>
    {#if !$curation.comingSoonProjects || $curation.comingSoonProjects.length == 0}
        <div class="text-gray-600 flex-grow flex flex-col justify-center items-center h-full">
            {#if ($site == 'polinate' || $site == 'polinateProd')}
                It is a bit empty here!
                <div>
                    There is no project in the queue. You might be interested to see what is currenlty live (if any). Click
                    <b style="text-decoration: underline;"><a href="#/fund">here</a></b> to see
                </div>
            {/if}
            {#if ($site == 'polygen' || $site == 'polygenProd')}
                It is a bit empty here!
                <div>
                    There are no projects upcoming, check back later, see what’s currently live and join our 
                    <b style="text-decoration: underline;"><a href="https://t.me/polygen_launchpad" target="_blank">Official Telegram Chat</a></b> for updates.
                </div>
            {/if}
        </div>
    {:else if $curation}
        <div class="grid w-full grid-cols-3 gap-5 pt-7">
            {#each projects as project}
                <UpcomingProjectCard {project}/>
            {/each}
        </div>
        <!-- <div class="flex-grow flex flex-col justify-center items-center h-full" class:hideLoader={numberOfProjects || $curation.comingSoonProjects}>
            <LoadingAnim width='200px' />
        </div> -->
    {/if}
</Main>
