<script>
import { CurrencyAmount } from '@uniswap/sdk-core'
import { getContext } from 'svelte'
import { tick } from '../Tick'

$: ({
    contractsReadOnly : {
        trust: trust,
        pool: pool
    },
    projectState,
    poolBalanceReserve,
    poolBalanceReserveStr,
    poolBalanceRedeemable,
    poolBalanceRedeemableStr,
    minRaise,
    redeemInit,
    redeemInitNum,
    reserveInit,
    seederFee,
    reserveCurrency,
    redeemableCurrency,
    raiseDuration,
    trustBounds
} = getContext('trustStores'))

$: if ($tick && $trust && !$reserveCurrency) { 
    getProjectState() 
}

async function getProjectState() {
    ({ distributionStatus: $projectState } = await $trust.getDistributionProgress())
}

$: if ($tick && $trust && $reserveCurrency && $redeemableCurrency) { getDistributionProgress() }

async function getDistributionProgress() {

    let _seederFee, _minRaise, _reserveInit, _redeemInit, _poolBalanceReserve, _poolBalanceRedeemable, distributionEndBlock, distributionStartBlock

    ({   
        distributionStatus: $projectState,
        minimumCreatorRaise: _minRaise,
        redeemInit: _redeemInit,
        reserveInit: _reserveInit,
        seederFee: _seederFee,
        poolReserveBalance: _poolBalanceReserve,
        poolTokenBalance: _poolBalanceRedeemable,
        distributionEndBlock,
        distributionStartBlock

    } = await $trust.getDistributionProgress())

    // console.log(distributionEndBlock, distributionStartBlock, distributionEndBlock - distributionStartBlock)
    // console.log((await $pool.minimumTradingDuration()).toString())

    $redeemInit =  CurrencyAmount.fromRawAmount($reserveCurrency, _redeemInit)
    $minRaise = CurrencyAmount.fromRawAmount($reserveCurrency, _minRaise)
    $seederFee = CurrencyAmount.fromRawAmount($reserveCurrency, _seederFee)
    $reserveInit = CurrencyAmount.fromRawAmount($reserveCurrency, _reserveInit)
    $poolBalanceReserve = CurrencyAmount.fromRawAmount($reserveCurrency, _poolBalanceReserve)
    $poolBalanceRedeemable = CurrencyAmount.fromRawAmount($redeemableCurrency, _poolBalanceRedeemable)
    // console.log('reserve balance ' + $poolBalanceReserve.toExact())
    // console.log('redeemable balance ' + $poolBalanceRedeemable.toExact())
    // console.log('min creator raise ' + _minRaise.toString())
    // console.log('redeem init ' + _redeemInit.toString())
    // console.log('reserve init ' + _reserveInit.toString())
    
    $redeemInitNum = $redeemInit.toFixed(4)
    $poolBalanceReserveStr = $poolBalanceReserve.toFixed(4)
    $poolBalanceRedeemableStr = $poolBalanceRedeemable.toFixed(4)

    $trustBounds = {
        startBlock: distributionStartBlock,
        endBlock: distributionEndBlock,
        raiseDuration: distributionEndBlock - distributionStartBlock
    }
}
</script>
