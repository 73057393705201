<script lang="ts">
  import { Tabs, TabList, TabPanel, Tab } from "../../../components/tabs/tabs";
  import Chart from "./chart/multiaxis/Chart.svelte";
  import TransactionsChart from "./chart/multiaxis/TransactionsChart.svelte";
</script>

<Tabs>
  <TabList>
    <Tab>Chart</Tab>
  </TabList>

  <TabPanel>
    <div class="flex flex-col justify-center items-center w-full p-4 bg-white shadow-sm border border-gray-200 rounded-2xl mt-4">
      <!-- <Chart /> -->
      <TransactionsChart />
    </div>
  </TabPanel>
</Tabs>
