<script>
	import StartRaise from './StartRaise.svelte';
// svelte
import { getContext } from 'svelte'

// components
import ProjectName from '../components/ProjectName.svelte'
import ProjectCreator from '../components/ProjectCreator.svelte'
import ProjectImage from '../components/ProjectImage.svelte'
import ProjectBodyTabs from '../components/ProjectBodyTabs.svelte'
import Spacer from '../../../components/util/Spacer.svelte'
import Steps from '../../../components/steps/Steps.svelte'
import UnseedPanel from './UnseedPanel.svelte'
import SeedPanel from './SeedPanel.svelte'

// stores
let stores = getContext("trustStores")
let projectState = stores.projectState

</script>

<div class="w-full flex flex-row justify-between py-5 max-w-screen-2xl mx-auto">
    <Steps steps={['Seed', 'Fund', 'Claim']} activeStep=0/>
</div>
    
<div class="w-full grid grid-cols-5 gap-4 py-5 max-w-screen-2xl mx-auto">
    <div class="col-span-3 flex flex-col space-y-3">
        <ProjectName />
        <ProjectCreator />
        <Spacer height=2 />
        <ProjectImage />
        <Spacer height=4 />
        <ProjectBodyTabs />
    </div>
    <div class="col-span-2 flex flex-col gap-y-4">
        {#if $projectState === 0}
            <SeedPanel />
            <UnseedPanel />
        {:else if $projectState === 1}
            <StartRaise />
        {/if}
    </div>
</div>