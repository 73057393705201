import "constants";
import "ethers";
import 'tailwindcss/colors';
export const FACTORY_ADDRESS = '0x56CdCBcc1aaB520964f9D24fa3BFAa97258fDdC1'; // new factory - with fix for KYC users to sell back rTKN
// kyc - latest
export const VERIFY_ADDRESS = '0xaDc2De2E4013dBC3ef6DA843d263eaC2eCFB0A80'; // admin - 0x87661e2C7379886f5C6824814407ED7238ec9624
export const VERIFY_TIER_DEFAULT = '0x80b7223d1eEC343904d062455d4ce8262b3806d2'; // a default VerifyTier for Polygen
// tier
export const TIER_DEFAULT = '0x80b7223d1eEC343904d062455d4ce8262b3806d2'; // a default VerifyTier for Polygen
export const MIN_TIER_MESSAGE = 'You need to complete the KYC to participate in this raise.';
// block explorer base path
export const BLOCK_EXPLORER_TRANSACTION = 'https://explorer-mumbai.maticvigil.com/tx/';
export const BLOCK_EXPLORER_ADDRESS = 'https://explorer-mumbai.maticvigil.com/address/';
export const RESERVE_DEFAULT = '0x25a4Dd4cd97ED462EB5228de47822e636ec3E31A'; // USDC
// chain
export const CHAIN_ID = 80001; // mumbai
export const NETWORK_NAME = "Mumbai";
// rpc
export const PROVIDER_RPC = 'https://polygon-mumbai.g.alchemy.com/v2/94Lqen0XXp2HNSDlUZ_nu8cbkDUJXJpJ';
//https://rpc-mumbai.maticvigil.com/
// covalent api key
export const COVALENT_KEY = 'ckey_7e3942e77eb947f7ab1ad48a3ca';
export const PAGE_LENGTH = 999999; // historical log pagination - number of blocks
export const logo = "./images/polygen-logo-dapp-new.svg";
//export const logoReversed = "./images/polygen-logo-reversed.svg"
export const logoReversed = "./images/polygen-logo-icon.png";
export const favicon = "./images/favicon.png";
export const webclip = "./images/webclip.png";
export const siteName = "Polygen";
export const siteSub = "THE COMMUNITY'S LAUNCHPAD";
export const KYC_BASE_URL = 'https://synaps-proxy-polygen.jupiter7108.workers.dev/';
export const KYC_ENABLED = true;
export const KYC_CLIENT_NAME = 'polygen'; // 
export const KYC_ENVIRONMENT = 'sandbox'; // sandbox|production
export const KYC_LOCAL_STORAGE_KEY = 'polygen';
//export const curationContent = './curation/polygen.json'
export const curationContent = 'https://dapp-assets.s3.eu-west-1.amazonaws.com/json_datasrouce/polygen/sandbox/polygen.json';
// theme for tailwind
export const theme = {
    extend: {
        colors: {
            primary: {
                100: '#F6F6FE',
                200: '#E8E8FD',
                300: '#D1D1FA',
                400: '#BEBFF8',
                500: '#A5A6F6',
                600: '#7879F1',
                700: '#5D5FEF',
                800: '#4042D4',
                900: '#2D2FB9'
            }
        }
    }
};
// header content
export const HEADER_CONTENT = {
    upcoming: "All the upcoming projects are listed here with raise details",
    seed: "Provide liquidity for the raise pool of a project you love and earn rewards. Investors, incubators or community members can provide liquidity for the seed raise pool",
    fund: "Buy your favorite Project tokens",
    claim: "Claim your Project tokens after a successful raise",
    new: "You can use this interface to know how you can get your project live for funding",
    guide: ""
};
