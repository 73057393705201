<script>
    // modules
    import { ethers } from 'ethers';
    
    // svelte
    import { getContext } from 'svelte'
    
    // components
    import BigBar from '../../../components/progressbar/BigBar.svelte'
    import BigLabelledVal from './../../../components/labelledvalues/BigLabelledVal.svelte'
    import Ellipsis from '../../../components/ellipsis/Ellipsis.svelte'
    import TokenField from '../../../components/tokenfield/TokenField.svelte'
    import Button from '../../../components/buttons/Button.svelte'
    import Modal from '../../../components/modals/Modal.svelte'
    import SeedModal from './SeedModal.svelte'
    
    // stores
    import { account } from '../../../stores/Account'
    import GenericSkeletonLoader from '../../../components/loader/GenericSkeletonLoader.svelte';
    
    let stores = getContext('trustStores')
    
    $: ({
        // contracts
        reserveCurrency,
        // balances
        myBalances,
        // trust info
        reserveInit,
        // seeder info
        seeder,
        seederUnitPrice,
        seederUnitSupply,
        seederUnitsAvail,
        seederBalance,
        seedFeePerUnit,
        seederCurrency
    } = stores)
    
    // alias BigNumber
    const BN = ethers.BigNumber
    
    // props for the progress bar and label
    let progressBarProps
    $: if ($seederBalance && $reserveCurrency && $seederUnitSupply && $reserveInit) {
        progressBarProps = {
            label : 'Amount seeded',
            value : $seederBalance.toSignificant(8) + ' of ' + $reserveInit.toExact() + ' ' + $reserveCurrency.symbol,
            color : 'green',
            total : $reserveInit.toSignificant(8),
            progress : $seederBalance.toSignificant(8)
        }
    }
    
    // only show the bar when we have all the data
    $: showBar = ($reserveCurrency.symbol && $seederUnitSupply && $reserveInit) ? true : false
    
    // the stats to show
    let stats = []
    $: if ($seederUnitPrice && $reserveCurrency && $seederUnitSupply && $seederUnitsAvail && $seedFeePerUnit) {
        stats = [
            {'token price': $seederUnitPrice.toSignificant(8) + ' ' + $reserveCurrency.symbol},
            {'reward per token' : $seedFeePerUnit.toSignificant(8) + ' ' + $reserveCurrency.symbol},
            {'total tokens' : $seederUnitSupply.toSignificant(8)},
            {'available' : $seederUnitsAvail.toSignificant(8)},
        ]
    }
    
    // only show the stats when we have all the data
    $: showStats = ($seederUnitPrice && $reserveCurrency && $seederUnitSupply && $seederUnitsAvail && $seedFeePerUnit) ? true : false
    
    // expand the text
    let extraInfo
    function revealInfo() { extraInfo = !extraInfo}
    
    // the token field
    let tokenField
    
    // props for the token field
    $: tokenFieldProps = {
        label : "Buy",
        tokenSymbol : "seedTKN",
        maxAmt : $seederUnitsAvail,
        showBalance : false,
        currency : $seederCurrency
    }
    
    // when the value in the token field changes
    let errorMsg
    let value, amount
    
    // $: if ($seederCurrency) { amount = CurrencyAmount.fromRawAmount($seederCurrency, BN.from(0)) }
    $: if (amount && value && $myBalances && $seederUnitPrice && $seederUnitsAvail && $reserveCurrency) { validateVal() }
    
    function updateVal({detail}) {
        ({ amount, value } = detail)
    }
    
    function validateVal() {
        errorMsg = false
        if (value % 1) {
            errorMsg = "Sorry, you must buy whole seed tokens"
        } else {
            if ($seederUnitPrice.quote(amount).greaterThan($myBalances.reserveBalance)) {
                errorMsg = "You don't have enough " + $reserveCurrency.symbol
            }
            if (amount.greaterThan($seederUnitsAvail)) {
                errorMsg = "Not enough seed tokens available"
            }
        }
    }  
    
    // label for the button
    let buttonLabel = ""
    $: if ($seederUnitPrice && amount) { 
        buttonLabel = "Seed " + ($seederUnitPrice.quote(amount).toSignificant(8)) + ' ' + $reserveCurrency.symbol
    } else {
        buttonLabel = "Seed"
    }
    
    // modal stuff
    let seedModal
    function openSeedModal() { seedModal = true }
    function closeModal() { 
        seedModal = false
    }
    
    </script>
    
    <div class="flex flex-col space-y-4">
        <GenericSkeletonLoader show={showBar}>
            <BigBar {...progressBarProps}/>
        </GenericSkeletonLoader>
        <div class="border-t border-gray-200"></div>
        <GenericSkeletonLoader show={showStats}>
            <div class="flex flex-row space-x-2 items-stretch justify-between">
                {#each stats as stat, index}
                    <div class="">
                        <BigLabelledVal label={Object.keys(stat)[0]} value={stat[Object.keys(stat)[0]]} />
                    </div>
                    {#if index !== stats.length - 1}
                        <div class="border-r border-gray-200"></div>
                    {/if}
                {/each}
            </div>
        </GenericSkeletonLoader>
        <div class="space-y-2 text-gray-600 flex flex-col">
            <div class="space-y-4">
                <div>
                    Provide liquidity for this project by buying sTKN and earn seed reward risk-free. Once a project is fully seeded, the raise can be started by anyone.
                </div>
                {#if extraInfo}
                    <div>
                        After a raise has ended seedTKN can be redeemed for a refund plus the seed reward.
                    </div>
                    <div>
                        Before a raise begins, seedTKN can be redeemed for a refund, but will be locked for 24hrs after purchase.
                    </div>
                {/if}
            </div>  
            <Ellipsis on:click={revealInfo}/>    
        </div>
        {#if $account}
            <GenericSkeletonLoader show={$myBalances}>
                <TokenField
                    {...tokenFieldProps}
                    on:changed={updateVal}
                    bind:this={tokenField}
                />
            </GenericSkeletonLoader>
        {/if}
        {#if $account && $seedFeePerUnit}
            {#if errorMsg}
                <Button label={errorMsg} on:click={account.connect} disabled/>
            {:else}
                <div class="space-y-4">
                    <Button label={buttonLabel} on:click={openSeedModal} disabled={!parseInt(value)}/>
                    {#if amount}
                    <div class="text-center text-sm text-gray-500">
                        You'll earn {$seedFeePerUnit.quote(amount).toSignificant(8)} {$reserveCurrency.symbol} in reward.
                    </div>
                    {/if}
                </div>
            {/if}
        {:else}
            <Button label="Connect wallet" on:click={account.connect} variant="secondary"/>
        {/if}
    </div>
    
    {#if seedModal}
    <Modal on:close={closeModal}>
        <SeedModal 
            on:close={closeModal}
            on:complete={tokenField.clear()}
            amount={amount} 
        />
    </Modal>
    {/if}