import "constants";
import "ethers";
import 'tailwindcss/colors';
//export const FACTORY_ADDRESS = '0x191F93ce7E36D56DAffbDf1D91443fd9D4dCf59D' // factory - old with KYC sell back issue
export const FACTORY_ADDRESS = '0xD84c71011BAFD6dF8d1e14A0aE5Cb9F0ceA78533'; // new factory - with fix for KYC users to sell back rTKN
// kyc - latest
export const VERIFY_ADDRESS = '0xaDc2De2E4013dBC3ef6DA843d263eaC2eCFB0A80'; // admin - 0x87661e2C7379886f5C6824814407ED7238ec9624
export const VERIFY_TIER_DEFAULT = '0x80b7223d1eEC343904d062455d4ce8262b3806d2'; // a default VerifyTier for Polygen
// tier
export const TIER_DEFAULT = '0x80b7223d1eEC343904d062455d4ce8262b3806d2'; // a default VerifyTier for Polygen
export const MIN_TIER_MESSAGE = 'You need to be part of the Polinate Social Hive with X tier to participate in this raise';
// block explorer base path
export const BLOCK_EXPLORER_TRANSACTION = 'https://explorer-mumbai.maticvigil.com/tx/';
export const BLOCK_EXPLORER_ADDRESS = 'https://explorer-mumbai.maticvigil.com/address/';
export const RESERVE_DEFAULT = '0x25a4Dd4cd97ED462EB5228de47822e636ec3E31A'; // USDC
// chain
export const CHAIN_ID = 80001; // mumbai
export const NETWORK_NAME = "Mumbai";
// rpc
export const PROVIDER_RPC = 'https://polygon-mumbai.g.alchemy.com/v2/ySRj00NBpSJIquZT3zMO5107pJbhQ2Jp';
// covalent api key
export const COVALENT_KEY = 'ckey_7e3942e77eb947f7ab1ad48a3ca';
export const PAGE_LENGTH = 999999; // historical log pagination - number of blocks
export const logo = "./images/polinate/polinate-logo.svg";
export const logoReversed = "./images/polinate/polinate-logo.svg";
export const favicon = "./images/polinate/favicon.png";
export const webclip = "./images/polinate/webclip.png";
export const siteName = "Polinate";
export const siteSub = "";
export const KYC_BASE_URL = 'https://synaps-proxy-polygen.jupiter7108.workers.dev/';
export const KYC_CLIENT_NAME = 'polinate'; // 
export const KYC_ENABLED = false;
export const KYC_ENVIRONMENT = 'sandbox'; // sandbox|production
export const KYC_LOCAL_STORAGE_KEY = 'polinate';
//export const curationContent = './curation/polinate.json?v=1'
export const curationContent = 'https://dapp-assets.s3.eu-west-1.amazonaws.com/json_datasrouce/polinate/sandbox/polinate.json';
// theme for tailwind
export const theme = {
    extend: {
        colors: {
            primary: {
                100: '#FEEDD2',
                200: '#FDD391',
                300: '#FCC369',
                400: '#F7B855',
                500: '#FBB03B',
                600: '#F0980F',
                700: '#D4860C',
                800: '#A66A0C',
                900: '#774C09'
            }
        }
    }
};
// header content
export const HEADER_CONTENT = {
    seed: "To seed a pool is to add liquidity to a pool, only once liquidity has been added can the pool then be traded and rewards be purchased.",
    fund: "To fund a pool is to purchase rewards or trade the pTKNs. At the end of the trading period anyone left with the tokens has access to the rewards via their pTKNs.",
    claim: "This is where you can claim your airdrops and rewards. The claim button will highlight when a claim is available."
};
