import "ethers";
import 'tailwindcss/colors';
// block explorer base path
export const BLOCK_EXPLORER_TRANSACTION = 'https://explorer-mumbai.maticvigil.com/tx/';
export const BLOCK_EXPLORER_ADDRESS = 'https://explorer-mumbai.maticvigil.com/address/';
// chain
export const CHAIN_ID = 80001; // mumbai
export const NETWORK_NAME = "Mumbai";
// covalent api key
export const COVALENT_KEY = 'ckey_7e3942e77eb947f7ab1ad48a3ca';
export const PAGE_LENGTH = 999999; // historical log pagination - number of blocks
export const logo = "./images/commgen/commgen-logo.svg";
export const logoReversed = "./images/commgen/commgen-logo.svg";
export const favicon = "./images/commgen/favicon.png";
export const webclip = "./images/commgen/webclip.png";
export const siteName = "Commgen";
export const siteSub = "Commodities launchpad";
export const curationContent = './curation/commgen.json';
// theme for tailwind
export const theme = {
    extend: {
        colors: {
            primary: {
                100: '#F6F6FE',
                200: '#E8E8FD',
                300: '#D1D1FA',
                400: '#BEBFF8',
                500: '#A5A6F6',
                600: '#7879F1',
                700: '#5D5FEF',
                800: '#4042D4',
                900: '#2D2FB9'
            }
        }
    }
};
