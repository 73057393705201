<script lang="ts">
// svelte
import { getContext } from 'svelte'

// util
import SimpleTableRow from '../../../components/simpletable/SimpleTableRow.svelte'
import SimpleTableCell from '../../../components/simpletable/SimpleTableCell.svelte'
import IconLibrary from '../../../components/icons/IconLibrary.svelte'
import { constants } from '../../../constants/Constants'
import { Fraction, Percent } from '@uniswap/sdk-core';
import { toRaw } from '../../../utils/utils';

// stores
$: ({
    redeemBalances,
    redeemCurrencies,
    myBalances,
    redeemTokens,
    redeemShares
} = getContext('trustStores'))

let percent

$: reserveBalance = $redeemBalances?.[index]
$: yourShare = $myBalances ? $redeemShares[index]?.quote($myBalances.redeemableBalance) : null
$: if (yourShare && reserveBalance) {
    percent = new Percent(toRaw(yourShare), toRaw(reserveBalance)).toSignificant(3)
}

export let index
</script>

<SimpleTableRow alt={index % 2}>
    <SimpleTableCell column=1 of=3>
        <a href={constants.BLOCK_EXPLORER_ADDRESS + $redeemTokens[index]?.address} target="_blank">
            {$redeemCurrencies[index]?.symbol}
            <IconLibrary width=10 icon={'link'} inline color={'text-gray-500'}/>
        </a>
    </SimpleTableCell>
    <SimpleTableCell column=2 of=3>
        {reserveBalance?.toSignificant(8)}
    </SimpleTableCell>
    <SimpleTableCell column=3 of=3>
        {yourShare?.toSignificant(8)} ({percent}%)
    </SimpleTableCell>
</SimpleTableRow>