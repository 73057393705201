<script>
export let heading
</script>

<div class="p-10 border border-gray-200 space-y-2 filter drop-shadow-sm rounded-2xl bg-white space-y-10 flex flex-col items-start">
    <span class="text-xl font-semibold font-gray-900">{heading}</span>
    <slot name="description"></slot>
    <slot></slot>
</div>

