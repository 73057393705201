<script>
// svelte
import { getContext } from 'svelte'
import { push } from 'svelte-spa-router'

// components
import ProjectCreator from '../../project/components/ProjectCreator.svelte'
import Button from '../../../components/buttons/Button.svelte'
import SmallBar from './../../../components/progressbar/SmallBar.svelte'
import GenericSkeletonLoader from '../../../components/loader/GenericSkeletonLoader.svelte'

// stores
import ImageAndName from '../../../components/projectcard/ImageAndName.svelte'
import TrustInfo from '../../../stores/project/TrustInfo.svelte'
import AmountRaised from './../../../stores/project/AmountRaised.svelte'
import ReserveAndRedeemable from '../../../stores/project/ReserveAndRedeemable.svelte'

// util
import { timeString } from '../../../utils/utils'
import TimeRemaining from '../../../stores/project/TimeRemaining.svelte'

// stores
$: ({
    metadata,
    trustAddress,
    successBalance,
    amountRaised,
    timeRemaining,
    reserveCurrency,
    projectState
} = getContext('trustStores'))

$: addressLink = '/project/' + $trustAddress

// props for the progress bar and label
let barProps

$: if ($amountRaised && $successBalance && $reserveCurrency.symbol) {
    barProps = {
        progress : $amountRaised.toSignificant(8),
        total : $successBalance.toSignificant(8),
        color : 'green',
        height : 4,
        labelValue : $amountRaised.toSignificant(8) + " " + $reserveCurrency.symbol,
        label : "Amount raised"
    }
}
</script>

<div on:click={push(addressLink)} class="flex flex-col bg-white shadow-sm border border-gray-200 relative rounded-2xl cursor-pointer">
    <ImageAndName name={$metadata.name} image={$metadata.image}/>
    <div class="flex flex-row justify-between px-4 pb-4">
        <ProjectCreator />
        {#if $projectState === 2}
            <Button small label="Fund" />
        {:else if $projectState === 3}
            <Button small label="Finalize" />
        {/if}
    </div>
    <div class="border-t border-gray-200 p-4 space-y-4">
        <GenericSkeletonLoader show={barProps}>
            <SmallBar {...barProps} />
        </GenericSkeletonLoader>
        <GenericSkeletonLoader show={$timeRemaining}>
            <div class="text-sm text-gray-500">
                {#if $timeRemaining > 0}
                <div class="flex flex-row space-x-2">
                    <div class="w-5 h-5 bg-red-400 animate-pulse rounded-full"></div>
                    <div>Time left: <span class='font-medium text-gray-700'>{timeString($timeRemaining)}</span></div>
                </div>
                {:else}
                    Raise ended - ready to finalize.
                {/if}
            </div>
        </GenericSkeletonLoader>
    </div>
</div>

<!-- stores calc -->
<TrustInfo />
<AmountRaised />
<TimeRemaining />
<ReserveAndRedeemable />