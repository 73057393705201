<script>
import { onMount } from 'svelte'
import SocialsList from './../../../components/socials/SocialsList.svelte'
import GenericSkeletonLoader from "../../../components/loader/GenericSkeletonLoader.svelte"

export let member

let image, loaded

onMount(() => {
    image.onload = () => {
      loaded = true
    }
})

</script>

<div class="space-y-4 flex flex-col">
    <GenericSkeletonLoader show={loaded}>
        <div class="w-full aspect-w-16 aspect-h-12">
            <img class="object-cover w-full rounded-md" bind:this={image} src={member.image} />
        </div>
    </GenericSkeletonLoader>
    <div>
        <div class="font-medium">
            {member.name}
        </div>
        <div>
            {member.role}
        </div>
    </div>
    {#if member.bio}
        <div class="font-base text-gray-800">
            {member.bio}
        </div>
    {/if}
    <div class="flex flex-row">
        <SocialsList socials={member.socials} />
    </div>
</div>