<script>
  import { account, signer, provider } from './../../stores/Account.js'
  import { kycContracts, roles } from '../../utils/kyc'
  import FormPanel from '../../components/form/FormPanel.svelte'
  import Input from '../../components/form/Input.svelte'
  import Button from '../../components/buttons/Button.svelte'
  import InlineNotification from '../../components/notifications/InlineNotification.svelte'

  let selectedRole, status, errorMsg, kycContract
  let address = ''

  $: if ($account) {
    kycContract = kycContracts($signer)[0]
  }

  const grantRole = async () => {
    kycContract
      .grantRole(selectedRole.hash, address)
      .then(async (result) => {
        status = 'tx-verifying'
        await $provider.waitForTransaction(result.hash, 1)
        status = 'tx-verified'
      })
      .catch((error) => {
        errorMsg = error.message
        if (error.data) {
          errorMsg = error.data.message
        }
        status = 'error'
      })
  }
</script>

<div class="w-full grid grid-cols-1 gap-4 mt-10">
  <FormPanel heading="Grant Role">
    <div>
      <select bind:value={selectedRole} on:blur={() => (address = '')}>
        {#each roles as role}
          <option value={role}>{role.role}</option>
        {/each}
      </select>
    </div>
    <Input type="text" bind:value={address}>
      <span slot="label">Enter an address</span>
    </Input>
    {#if status == 'tx-verifying'}
      <Button shrink="true" disabled={true}>Verifying transaction...</Button>
    {:else}
      <Button shrink="true" on:click={grantRole}>Grant role</Button>
    {/if}
    {#if status == 'tx-verified'}
      <InlineNotification type="warning">Role granted!</InlineNotification>
    {/if}
    {#if errorMsg}
      <InlineNotification type="error">{errorMsg}</InlineNotification>
    {/if}
  </FormPanel>
</div>
