<script>
// svelte
import { getContext } from 'svelte'

// components
import Button from '../../../components/buttons/Button.svelte'
import InlineNotification from '../../../components/notifications/InlineNotification.svelte'

// stores
import { account } from '../../../stores/Account'

let stores = getContext('trustStores')
let trust = stores.contractsWithSigner.trust

async function endRaise() {
    let tx = await $trust.anonEndDistribution()
}
</script>

<div class="flex flex-col space-y-4">
    <div class="text-sm">
        <InlineNotification type="warning">
            This project's raise has now ended, but needs to be finalized before anyone can redeem their tokens or claim rewards.
        </InlineNotification>
    </div>

    {#if $account}
        <Button label="Finalize raise" on:click={endRaise} />
    {:else}
        <Button on:click={account.connect} variant="secondary" label="Connect wallet" />
    {/if}
</div>
    