<script>
import { getContext, onMount } from "svelte"
import { fade } from "svelte/transition"
import IconLibrary from "../../../components/icons/IconLibrary.svelte"
import { site } from '../../../stores/Site.js'

$: ({
    redeemableCurrency,
} = getContext('trustStores'))

let hidden
//const cacheTime = 1000 * 60 * 60 * 24 * 7 // 1 week
const cacheTime = 1000 * 60 * 30 // 30 min

onMount(()=>{
    hidden = localStorage.getItem('hideHelp') ? true : null
    let time = localStorage.getItem('hideHelpTime')
    time = time ? parseInt(time) : null
    const elapsed = Date.now() - time
    if (hidden && elapsed > cacheTime) { hidden = false }
})

function hideHelp() {
    hidden = true
    localStorage.setItem('hideHelp', 'true')
    localStorage.setItem('hideHelpTime', Date.now())
}

</script>
{#if !hidden && $redeemableCurrency.symbol}

<div transition:fade={{duration:200}} class="bg-primary-100 text-gray-800 gap-y-6 p-8 rounded-xl flex flex-col relative">
    
    <div on:click={hideHelp} class="cursor-pointer absolute top-4 right-4">
        <IconLibrary icon="close" color="text-primary-700" width=20 />
    </div>

    {#if !($site !== 'goldfinch' || $site !== 'ff3')}

    <div class="flex flex-row space-x-6 items-center">
        <li>Raise token ({$redeemableCurrency.symbol} in case of current raise) is used to participate in raises on Polygen - it holds no value and is only used to facilitate project token price discovery.</li>
    </div>
    <div class="flex flex-row space-x-6 items-center">
        <li>You are buying/selling {$redeemableCurrency.symbol} not a project token during a raise</li>
    </div>
    <div class="flex flex-row space-x-6 items-center">
        <li>During a raise, the price will start high and decay over time. Buy {$redeemableCurrency.symbol} at the price that feels right for you, but don’t wait too long, the more people buy at once, the higher the rTKN price.</li>
    </div>
    <div class="flex flex-row space-x-6 items-center">
        <li>At the end of a sale, the amount of {$redeemableCurrency.symbol}s bought will be put into a pot and distributed as project tokens using a simple calculation.</li>
    </div>
    <div class="flex flex-row space-x-6 items-center font-medium">
        Amount of (project) TKN in the pool / {$redeemableCurrency.symbol} sold
    </div>
    <div class="flex flex-row space-x-6 items-center">
        <li>You can head to claim section to get your project tokens once raise is completed.
    </div>
    {:else}
    <div class="flex flex-row space-x-6 items-center">
        <img src="images/Diamond.svg" />
        <span>Fund this raise by buying {$redeemableCurrency.symbol}. After a successful raise, {$redeemableCurrency.symbol} can be used to claim your rewards.</span>
    </div>
    <!--<div class="flex flex-row space-x-6 items-center">
        <img src="images/Trade.svg" />
        <span>{$redeemableCurrency.symbol} tokens are freely tradeable during the raise!</span>
    </div> -->
    <div class="flex flex-row space-x-6 items-center">
        <img src="images/Dutch.svg" />
        <span>The {$redeemableCurrency.symbol} price overall will keep dropping until the raise end, but purchases of {$redeemableCurrency.symbol} increase the price - so move quickly if you want to be part of the project!</span>
    </div>
    {/if}
</div>
{/if}







 




