<script>
import { CurrencyAmount } from '@uniswap/sdk-core';

// modules
import { ethers } from 'ethers'

// svelte
import { getContext } from 'svelte'

// util
import { tick } from '../Tick'

$: ({
    contractsReadOnly : {
        bPool: bPool,
        redeemableToken: redeemableToken,
        reserveToken: reserveToken
    },
    spotPrice,
    reserveCurrency
} = getContext('trustStores'))

const BN = ethers.BigNumber

// spot price
async function getSpotPrice() {

    $bPool.getSpotPrice($reserveToken.address, $redeemableToken.address)
        .then((value)=>{
            $spotPrice = CurrencyAmount.fromRawAmount($reserveCurrency, value)
        })
        .catch(error=>{
            console.log(error)
        })

}

$: if ($bPool && $reserveToken && $redeemableToken && $reserveCurrency && $tick) {
    getSpotPrice()
}
</script>