<script>
import { getContext } from 'svelte'

// components
import ProjectName from '../components/ProjectName.svelte'
import ProjectCreator from '../components/ProjectCreator.svelte'
import ProjectImage from '../components/ProjectImage.svelte'
import ProjectBodyTabs from '../components/ProjectBodyTabs.svelte'
import Spacer from '../../../components/util/Spacer.svelte'
import RedeemPanel from './RedeemPanel.svelte'
import Steps from '../../../components/steps/Steps.svelte'
import RedeemableBalancesPanel from './RedeemableBalancesPanel.svelte'
import ReserveTokens from '../components/ReserveTokens.svelte'
import MyTier from './../../../components/tiers/MyTier.svelte'
import Button from '../../../components/buttons/Button.svelte'
import RedeemSeed from './RedeemSeed.svelte'
import GenericSkeletonLoader from '../../../components/loader/GenericSkeletonLoader.svelte'

// stores
import { signer } from '../../../stores/Account'
import { site } from '../../../stores/Site'
import { account } from './../../../stores/Account.js'
import Toggle from '../../../components/toggle/Toggle.svelte';
import Graphs from '../fund/Graphs.svelte';
import StatBlocks from '../fund/StatBlocks.svelte';
import TableTabs from '../fund/TableTabs.svelte';
import RaiseProgress from '../fund/RaiseProgress.svelte';

$: ({
    projectState,
    myTier,
    redeemableCurrency
} = getContext('trustStores'))

export let advanced

</script>

<div class="w-full flex flex-row justify-between py-5 max-w-screen-2xl mx-auto">
    <Steps steps={['Seed', 'Fund', 'Claim']} activeStep=2/>
    <Toggle bind:active={advanced} label="Advanced"/>
</div>
    
<div class="w-full grid grid-cols-5 gap-4 py-5 max-w-screen-2xl mx-auto">
    <div class="col-span-3 flex flex-col space-y-3">
        <ProjectName />
        <ProjectCreator />
        <Spacer height=2 />
        {#if advanced}
        <Graphs />
        <Spacer height=4 />
        <TableTabs />
        {:else}
        <ProjectImage />
        <Spacer height=4 />
        <ProjectBodyTabs />
        {/if}
    </div>
    <div class="col-span-2 flex flex-col gap-y-4">
        {#if !(($site == 'polinate' || $site == 'polinateProd') && $projectState == 4)}
            <RedeemPanel />
        {/if}
        {#if (($site == 'polinate' || $site == 'polinateProd') && $projectState == 4)}
            <div class="w-full gap-y-7 border border-gray-200 bg-white filter rounded-2xl shadow-md p-5 flex flex-col">
                <div class="text-xl font-semibold text-gray-800">Tier</div>
                {#if $account}
                    <MyTier />
                {:else}
                    <GenericSkeletonLoader show={$redeemableCurrency.symbol}>
                        <div class="text-sm mb-5">
                            If you purchased {$redeemableCurrency.symbol}, connect your wallet to see your tier.
                        </div>
                        <Button on:click={account.connect} variant="secondary" label="Connect wallet" />
                    </GenericSkeletonLoader>
                {/if}
            </div>
        {/if}
        <RedeemSeed />
    </div>
</div>