import { writable } from 'svelte/store'

const TICK_TIME = 15000
const INACTIVE_USER_TIME_THRESHOLD = 30000
const USER_ACTIVITY_THROTTLER_TIME = 1000
let _tick = writable(0)
let tickInterval

// _tick.subscribe((val)=>{console.log(val)})

// set the interval for the first time
resetTick()
activateActivityTracker()

// only update tick if the tab is active
function updateTick() {
    _tick.update(t => t + 1)
}

function resetTick() {
    // console.log('tick reset')
    updateTick()
    clearInterval(tickInterval)
    tickInterval = setInterval(updateTick, TICK_TIME)
}

function pauseTick() {
    // console.log('tick paused')
    clearInterval(tickInterval)
    tickInterval = null
}

export const tick = {
    subscribe : _tick.subscribe,
    update : resetTick
}

// Handling tab visibility, so we can not refresh data when the tab is inactive
// Set the name of the hidden property and the change event for visibility
var hidden, visibilityChange;
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

function handleVisibilityChange() {
  if (document[hidden]) {
    // console.log('tab inactive')
    pauseTick()
  } else {
    // console.log('tab active')
    resetTick()
  }
}

// Warn if the browser doesn't support addEventListener or the Page Visibility API
if (typeof document.addEventListener === "undefined" || hidden === undefined) {
  console.log("This app requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
} else {
  // Handle page visibility change
  document.addEventListener(visibilityChange, handleVisibilityChange, false)
}

// Handling inactive users (tab is open, but no user activity)
let userActivityTimeout, userActivityThrottlerTimeout 

function resetUserActivityTimeout() {
  if (!tickInterval) {
    resetTick()
  }
  clearTimeout(userActivityTimeout);
  userActivityTimeout = setTimeout(() => {
    inactiveUserAction();
  }, INACTIVE_USER_TIME_THRESHOLD);
}

function inactiveUserAction() {
//   console.log('user inactive')
  pauseTick()
}

function userActivityThrottler() {
    if (!userActivityThrottlerTimeout) {
      userActivityThrottlerTimeout = setTimeout(() => {
        resetUserActivityTimeout();
        clearTimeout(userActivityThrottlerTimeout);
        userActivityThrottlerTimeout = null;
      }, USER_ACTIVITY_THROTTLER_TIME);
    }
}

function activateActivityTracker() {
    window.addEventListener("mousemove", userActivityThrottler)
    window.addEventListener("scroll", userActivityThrottler)
    window.addEventListener("keydown", userActivityThrottler)
    window.addEventListener("resize", userActivityThrottler)
}