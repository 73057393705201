<script>
import SocialIcon from './SocialIcon.svelte'

export let socials
export let labelled=false

</script>

<div class='flex flex-row space-x-3'>
    {#each Object.entries(socials) as [type, link]}
        <SocialIcon {labelled} type={type} link={link}/>
    {/each}
</div>
