import "ethers";
import 'tailwindcss/colors';
// factory
export const FACTORY_ADDRESS = '0x19B5aCe714b7231Fa6596e25e4E15CcDc5dc09eA'; // new factory - with fix for Balancer issue - check on token balance on exit
// block explorer base path
export const BLOCK_EXPLORER_TRANSACTION = 'https://polygonscan.com/tx/';
export const BLOCK_EXPLORER_ADDRESS = 'https://polygonscan.com/address/';
// graphQL api
export const GRAPH_API_URL = "https://api.thegraph.com/subgraphs/name/beehive-innovation/rain-protocol";
// verify default
export const VERIFY_TIER_DEFAULT = '0x80b7223d1eEC343904d062455d4ce8262b3806d2'; // a default VerifyTier for Polygen
// tier
export const TIER_DEFAULT = '0x25ACA096cbEF597Aa3B99CDD747805e58d1aea81'; // a default VerifyTier for Polygen
// reserve
export const RESERVE_DEFAULT = '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'; // USDC
// rpc
export const PROVIDER_RPC = 'https://polygon-mainnet.g.alchemy.com/v2/UwJdTDXBUAilq84N85m_3ZBrNovDXpCi';
// chain
export const CHAIN_ID = 137; // mumbai
export const NETWORK_NAME = "Matic";
// covalent api key
export const COVALENT_KEY = 'ckey_7e3942e77eb947f7ab1ad48a3ca';
export const PAGE_LENGTH = 999999; // historical log pagination - number of blocks
export const logo = "./images/goldfinch/ff-logo.png";
export const logoReversed = "./images/goldfinch/ff-logo-on-black.png";
export const favicon = "./images/goldfinch/favicon.png";
export const webclip = "./images/goldfinch/webclip.png";
export const siteName = "First Flights";
export const siteSub = "Film launchpad";
export const curationContent = './curation/ff3.json';
// theme for tailwind
export const theme = {
    extend: {
        colors: {
            primary: {
                100: '#F6F6FE',
                200: '#E8E8FD',
                300: '#D1D1FA',
                400: '#BEBFF8',
                500: '#A5A6F6',
                600: '#7879F1',
                700: '#5D5FEF',
                800: '#4042D4',
                900: '#2D2FB9'
            }
        }
    }
};
// header content
export const HEADER_CONTENT = {
    upcoming: "Upcoming projects on FF3.",
    seed: "Provide liquidity for the raise pool of a project you love and earn a reward.",
    fund: "Support your favourite short film projects.",
    claim: "Claim your rewards after a successful raise.",
    new: "You can use this interface to know how you can get your project live for funding",
    guide: ""
};
