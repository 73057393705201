<script>
import { getContext } from 'svelte';
import { curation } from '../Site'
let stores = getContext('trustStores')

$: ({
    trustAddress,
    metadata
} = stores)

// filter to the matching addresses metadata
$: if ($trustAddress && $curation) {
   $metadata = $curation.curatedProjects
        .filter(project=>{
            return project.address.toUpperCase() == $trustAddress.toUpperCase()
        })[0]
}

</script>