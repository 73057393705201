<script>
import { createEventDispatcher } from 'svelte';
let dispatch = createEventDispatcher()
function click() {dispatch('click')}
</script>

<span on:click={click}
    class="flex flex-row space-x-1 p-2 hover:bg-gray-100 active:bg-gray-200 rounded-xl transition-colors cursor-pointer flex-shrink self-center">
    <div class="rounded-full w-1 h-1 bg-gray-400"></div>
    <div class="rounded-full w-1 h-1 bg-gray-400"></div>
    <div class="rounded-full w-1 h-1 bg-gray-400"></div>
</span>