<script>
import { push } from 'svelte-spa-router'
import ImageAndName from '../../../components/projectcard/ImageAndName.svelte'
import User from '../../../components/user/User.svelte'

import { site } from '../../../stores/Site'

export let project
$: console.log(project.name, project.image)
$: addressLink = '/upcoming/' + project.id
</script>

<div on:click={push(addressLink)} class="flex flex-col bg-white shadow-sm border border-gray-200 relative rounded-2xl relative cursor-pointer">
    <ImageAndName name={project.name} image={project.image}/>
    <div class="absolute top-7 right-7 bg-white rounded-lg p-2 text-xs text-gray-700 font-medium">
        LAUNCHING SOON
    </div>  

    <div class="flex flex-row justify-between px-4 pb-4">
        <User name={project.creator} avatar={project.creatorAvatar} label="Creator"/>
        <!-- <Button small label="Fund" on:click={push(addressLink)} /> -->
    </div>
    <div class="border-t border-gray-200 p-4 space-y-4">
        <div>
            <span class="text-gray-800">Raise target: </span>
                <span class="font-medium">{parseInt(project.raise_info?.raise_target).toLocaleString("en-US")} {project.raise_info?.reserve_token_symbol}</span>
        </div>
        <div>
            {#if project?.launch_datetime}
                <span class="text-gray-800">Launch date: </span>
                <span class="font-medium">{project?.launch_datetime}</span>
            {/if}
        </div>
    </div>
</div>