<script>
import { getContext } from "svelte"
import MarkdownProse from "./MarkdownProse.svelte"

let stores = getContext('trustStores')
$: ({
    metadata
} = stores)

</script>

<MarkdownProse src={$metadata.raise} />