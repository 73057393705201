<script>
import { getContext } from "svelte";

import Button from "../../../components/buttons/Button.svelte";
import InlineNotification from "../../../components/notifications/InlineNotification.svelte";
import LoadingAnim from "../../../components/util/LoadingAnim.svelte";

// stores
import { provider, account } from '../../../stores/Account'
import { bnToFourDecimal } from "../../../utils/utils";

let stores = getContext('trustStores')

// contracts
let pool = stores.contractsWithSigner.pool

let txStatus, errorMsg

async function startRaise() {
    txStatus = "waiting-on-signature"
    let tx = await $pool.startDutchAuction({
        // gasLimit: 100000000
    }).then((tx)=>{
        txStatus = "verifying"
        return $provider.waitForTransaction(tx.hash, 2)
    }).then(()=>{
        txStatus = "raise-started"
    }).catch(error => {
        errorMsg = error.message
        if (error.data) {errorMsg = error.data.message}
        txStatus = "error"
    })
}

</script>

{#if !txStatus}
<div class="space-y-4 flex flex-col">
    <!--<img class="w-32 self-center" src="/images/rocket.webp" />-->
    <span class="text-lg font-semibold">Start raise</span>
    <span class="text-lg font-light">You are about to start this raise!</span>
    <Button on:click={startRaise} label="Start raise"/>
</div>

{:else if txStatus == "waiting-on-signature"}
<div class="w-full flex flex-col items-center">
    <LoadingAnim />
    Waiting on signature...
</div>

{:else if txStatus == "verifying"}
<div class="w-full flex flex-col items-center">
    <LoadingAnim />
    Confirming Transaction...
</div>

{:else if txStatus == "raise-started"}
<div class="w-full flex flex-col items-center">
    Raise started!
</div>

{:else if txStatus == "error"}
<span class="tx-lg font-medium">Error</span>
<InlineNotification type="error">{errorMsg}</InlineNotification>
{/if}