<script>

export let active
export let label

let activeSwitch

function toggle() {
    active = !active
    activeSwitch = !activeSwitch
}

</script>

<div class="flex flex-row items-center cursor-pointer">
    {#if label}
        <span class="text-gray-600 mr-2 text-sm">{label}</span>
    {/if}

    <div on:click={toggle} class="transition duration-200 ease-in-out rounded-full p-1 w-11 bg-gray-300" class:active>
        <div class="h-5 w-5 bg-white rounded-full shadow-md transform duration-200 ease-in-out" class:activeSwitch></div>
    </div>
</div>

<style>
    .active {
        @apply bg-gray-600;
    }
    
    .activeSwitch {
        @apply translate-x-4;
    }
</style>