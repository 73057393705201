<script>
import { getContext } from 'svelte'
import { Datatable, rows } from 'svelte-simple-datatables'
import IconLibrary from '../../../components/icons/IconLibrary.svelte'

// stores
let stores = getContext('trustStores')
let myTransactions = stores.myTransactions

const settings = {
    sortable: true,
    pagination: true,
    scrollY: false,
    rowPerPage: 50,
    columnFilter: false,
    css: false,
    labels: {
        search: 'Search...',    // search input placeholer
        filter: 'Filter',       // filter inputs placeholder
        noRows: 'No entries to show',
        info: 'Showing {start} to {end} of {rows} entries',
        previous: 'Previous',
        next: 'Next',       
    },
    blocks: {
        searchInput: false, 
        paginationButtons: true,
        paginationRowCount: true,
    }
}

$: data = $myTransactions ? $myTransactions : []

</script>

<div class="mt-5">
<Datatable settings={settings} data={data}>
    <thead>
        <th></th>
        <th data-key="swap">Swap</th>
        <th data-key="amountIn">In</th>
        <th data-key="amountOut">Out</th>
        <th data-key="tokenPrice">Raise Token Price (rTKN)</th>
        <th data-key="relativeTime">Time</th>
        <th></th>
    </thead>
    <tbody>
        <tr></tr>
        {#each $rows as row}
        <tr>
            <td></td>
            <td>{row.swap}</td>
            <td>{row.amountIn}</td>
            <td>{row.amountOut}</td>
            <td>{row.tokenPrice}</td>
            <td flex flex-row items-center justify-end>
                <span>{row.relativeTime}</span>
                <a class="ml-1" href="{row.txLink}"><IconLibrary inline icon="link" width=10 color="text-gray-400"/></a>
            </td>
            <td></td>
        </tr>
        {/each}
    </tbody>
</Datatable>
</div>

<style global lang="postcss">
@layer base {
    .datatable table {
        @apply w-full;
    }

    .datatable table {
        border-spacing: 0 0.5rem;
        border-collapse: unset;
    }

    .dt-table {
        @apply border border-gray-200 rounded-2xl drop-shadow-md;
    }

    .datatable th {
        @apply text-left font-medium pt-2 pb-3 text-gray-800 border-b border-gray-200;
    }

    .datatable th:nth-last-child(2) {
        @apply text-right;
    }

    .datatable th:first-child, .datatable th:last-child {
        @apply w-4
    }

    .datatable td {
        @apply py-2;
    }
    
    .datatable tr:nth-child(even) td {
        @apply bg-gray-100;
    }

    .datatable td:nth-last-child(2) {
        @apply pr-2 rounded-r-lg text-right;
    }

    .datatable td:nth-child(2) {
        @apply pl-2 rounded-l-lg;
    }

    .datatable tr td:first-child, .datatable tr td:last-child {
        @apply bg-white
    }

    .dt-pagination-rowcount {
        @apply text-gray-600
    }

    .dt-pagination-buttons button {
        @apply px-2 border-gray-200 border-t border-b border-r
    }

    .dt-pagination-buttons button:first-child {
        @apply rounded-l-md border-l
    }

    .dt-pagination-buttons button:last-child {
        @apply rounded-r-md
    }

}
</style>