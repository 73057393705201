<script>
// svelte
import { getContext, onMount } from 'svelte'
import { createEventDispatcher } from 'svelte'

import { CurrencyAmount } from '@uniswap/sdk-core'

// components
import DisplayToken from '../../../components/tokenfield/DisplayToken.svelte'
import Button from '../../../components/buttons/Button.svelte'
import LoadingAnim from '../../../components/util/LoadingAnim.svelte'
import InlineNotification from '../../../components/notifications/InlineNotification.svelte'
import Steps from '../../../components/steps/Steps.svelte'
import SimpleTableHeading from './../../../components/simpletable/SimpleTableHeading.svelte'
import SimpleTableRow from './../../../components/simpletable/SimpleTableRow.svelte'

// stores
import { signer, account } from '../../../stores/Account'
import { toRaw } from '../../../utils/utils'
import SimpleTableCell from '../../../components/simpletable/SimpleTableCell.svelte'

$: ({
    contractsWithSigner : {
        seeder : seederContract
    },
    contractsReadOnly : {
        seeder : seederContractReadOnly
    },
    seederUnitSupply,
    seederBalance,
    seederCurrency,
    seedERC20Balance,
    projectState,
    seedFeePerUnit,
    seederUnitPrice,
    reserveCurrency,
    seederUnitSupply
} = getContext('trustStores'))

export let tokenAmt
export let totalRefund

let txStatus = 'confirm'
let errorMsg

// store the refund amount when the modal is opened for the first time (totalRefund is dynamic)
let _totalRefund
onMount(()=>{
    _totalRefund = totalRefund
})

// close modal event
const dispatch = createEventDispatcher()
function closeModal() {
    dispatch('close')
}

function redeem() {
    txStatus = "waiting-on-signature"
    let tx = $seederContract.redeem(toRaw(tokenAmt))
            .then(async (result)=> {
                txStatus = "verifying"
                await $signer.provider.waitForTransaction(result.hash, 1)
                $seedERC20Balance = CurrencyAmount.fromRawAmount($seederCurrency, await $seederContractReadOnly.balanceOf($account))
                txStatus = "complete"
                // dispatch a complete event so the field can be cleared
                dispatch('complete')
            }).catch(error => {
                errorMsg = error.message
                if (error.data) {errorMsg = error.data.message}
                txStatus = "error"
            })
}
</script>

{#if txStatus == 'confirm'}
    <div class="max-w-md flex flex-col gap-y-4"> 

        <span class="text-lg font-semibold">Confirm seed refund</span>

        <Steps steps={['Confirm', 'Redeem Complete']} activeStep=0 />

        <span class="text-sm font-medium">
            You are redeeming:
        </span>

        <DisplayToken amount={tokenAmt} tokenVal={tokenAmt.toSignificant(8)} currency={$seederCurrency} tokenSymbol="seedTKN" />

        <span class="text-sm font-medium">
            You'll receive:
        </span>

        <div class="flex flex-col space-y-2">
                <SimpleTableRow>
                    <SimpleTableCell column=1 of=2>
                        {totalRefund.toSignificant(8)}
                    </SimpleTableCell>
                    <SimpleTableCell column=2 of=2>
                        {$reserveCurrency.symbol}
                    </SimpleTableCell>
                </SimpleTableRow>            
        </div>

        <Button label="Confirm redeem" on:click={redeem} />

    </div>

{:else if txStatus == "waiting-on-signature"}
    <div class="w-full flex flex-col items-center">
      <LoadingAnim />
      Waiting on signature...
    </div>

{:else if txStatus == "verifying"}
    <div class="w-full flex flex-col items-center">
        <LoadingAnim />
        Confirming Transaction...
    </div>

{:else if txStatus == "complete"}
    <div class="max-w-md flex flex-col gap-y-4">

        <span class="text-lg font-semibold">Redemption complete</span>

        <Steps steps={['Confirm', 'Redeem Complete']} activeStep=1 />

        <span class="text-sm font-medium">
            You were refunded:
        </span>

        <DisplayToken amount={_totalRefund} tokenVal={_totalRefund.toSignificant(8)} currency={$reserveCurrency} />


        <Button on:click={closeModal} label="Ok" />

    </div>

{:else if txStatus == "error"}
    <span class="tx-lg font-medium">Error</span>
    <InlineNotification type="error">{errorMsg}</InlineNotification>
{/if}

