<script>
// svelte
import { getContext } from 'svelte'
import { push } from 'svelte-spa-router'

// components
import ProjectCreator from '../../project/components/ProjectCreator.svelte'
import Button from '../../../components/buttons/Button.svelte'
import ImageAndName from '../../../components/projectcard/ImageAndName.svelte'
import HoverTooltip from './../../../components/tooltips/HoverTooltip.svelte'
import IconLibrary from '../../../components/icons/IconLibrary.svelte'

// stores
import TrustInfo from '../../../stores/project/TrustInfo.svelte'

let stores = getContext('trustStores')
let metadata = stores.metadata
let address = stores.trustAddress
let projectState = stores.projectState

$: addressLink = '/project/' + $address
</script>

<div on:click={push(addressLink)} class="flex flex-col bg-white shadow-sm border border-gray-200 relative rounded-2xl cursor-pointer">
    <ImageAndName name={$metadata.name} image={$metadata.image}/>
    {#if $projectState == 5}
    <div class="cursor-default absolute top-7 right-7 bg-red-200 rounded-lg p-2 text-xs text-red-900 font-medium">
        <HoverTooltip width="w-60">
            FAILED
            <IconLibrary color="text-red-900" inline width=13 icon="info"/>
            <span slot="tip">This raise has failed, however if you hold the raise token you can still redeem your share of the partial raise.</span>  
        </HoverTooltip>
    </div> 
    {/if}
    <div class="flex flex-row justify-between px-4 pb-4">
        <ProjectCreator />
        <Button small label="Claim" />
    </div>
    <div class="border-t border-gray-200 p-4 space-y-4">

    </div>
</div>

<TrustInfo />