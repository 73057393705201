<script>
// modules
import { ethers } from 'ethers'
import { getContext, tick } from 'svelte'

// components  
import Modal from './../../../../components/modals/Modal.svelte'
import ConfirmSwap from './ConfirmSwap.svelte'
import Settings from './Settings.svelte'
import Button from './../../../../components/buttons/Button.svelte'
import IconLibrary from './../../../../components/icons/IconLibrary.svelte'
import TokenField from '../../../../components/tokenfield/TokenField.svelte'
import InlineNotification from "../../../../components/notifications/InlineNotification.svelte"
import HoverTooltip from './../../../../components/tooltips/HoverTooltip.svelte'

// constants
import { constants } from '../../../../constants/Constants'

// utils
import { CurrencyAmount, Fraction, Price } from '@uniswap/sdk-core'
import { getTokenWeights, toRaw } from '../../../../utils/utils'

// stores
import { account, provider } from '../../../../stores/Account'
import GenericSkeletonLoader from '../../../../components/loader/GenericSkeletonLoader.svelte'

$: ({
    contractsReadOnly : {
        redeemableToken : redeemableToken,
        reserveToken : reserveToken,
        bPool : bPool
    },
    contractsWithSigner : {
        redeemableToken : redeemableTokenWithSigner,
        reserveToken : reserveTokenWithSigner,
        crp : crpWithSigner
    },
    poolBalanceReserve,
    poolBalanceRedeemable,
    myBalances,
    redeemableAllowance,
    reserveAllowance,
    reserveCurrency,
    redeemableCurrency,
    swapReversed,
    projectState
} = getContext('trustStores'))

// aliasing big number for convenience
const BN = ethers.BigNumber

// constants
const maxPrice = ethers.constants.MaxUint256
const swapFee = BN.from('0')
const refreshPriceTime = 15000 // 15 seconds

// some ui settings
let slippage = 5
let errorMsg, 
    swapError, 
    price, 
    calculating,
    calculatingMsg,
    modal,
    settingsModal,
    txStatus,
    priceRefreshTimeout

// we use these to consolidate the token params we need
let reserve = {}
let redeemable = {}

// we use these to keep params that are local to this component
let reserveLocal = {}
let redeemableLocal = {}

// link it all up
$: reserve.currency = $reserveCurrency
$: reserve.token = $reserveToken
$: reserve.tokenWithSigner = $reserveTokenWithSigner
$: reserve.symbol = $reserveCurrency.symbol
$: reserve.poolBal = $poolBalanceReserve
$: reserve.myBalance = $myBalances.reserveBalance
$: reserve.allowance = $reserveAllowance
$: reserve.icon = constants.reserveIcon

reserveLocal.estimated = false
reserveLocal.amount
reserveLocal.fieldValue = 0
reserveLocal.minOut = 0
reserveLocal.maxIn = 0
reserveLocal.balanceExceeded = false

$: redeemable.currency = $redeemableCurrency
$: redeemable.token = $redeemableToken
$: redeemable.tokenWithSigner = $redeemableTokenWithSigner
$: redeemable.symbol = $redeemableCurrency.symbol
$: redeemable.poolBal = $poolBalanceRedeemable
$: redeemable.myBalance = $myBalances.redeemableBalance
$: redeemable.allowance = $redeemableAllowance
$: redeemable.icon = constants.redeemableIcon

redeemableLocal.estimated = false
redeemableLocal.amount
redeemableLocal.fieldValue = 0
redeemableLocal.minOut = 0
redeemableLocal.maxIn = 0
redeemableLocal.balanceExceeded = false

// alias the tokens so they can be easily flipped by changing swapReversed
$: tokenIn = $swapReversed ? redeemable : reserve
$: tokenOut = $swapReversed ? reserve: redeemable
$: tokenInLocal = $swapReversed ? redeemableLocal : reserveLocal
$: tokenOutLocal = $swapReversed ? reserveLocal: redeemableLocal

// bind to the token fields
let tokenInField, tokenOutField

// clear the token fields
function clearFields() {
    tokenInField.clear()
    tokenOutField.clear()
}

// calculate the slippage amounts when the values change
$: slippageOut = new Fraction(BN.from(1000000000000 - parseInt(parseFloat(slippage) * 10000000000)), BN.from(1000000000000))
$: slippageIn = new Fraction(BN.from(1000000000000 + parseInt(parseFloat(slippage) * 10000000000)), BN.from(1000000000000))

$: if (tokenOutLocal.amount) {
    tokenOutLocal.minOut = tokenOutLocal.amount.multiply(slippageOut)
}
$: if (tokenInLocal.amount) {
    tokenInLocal.maxIn = tokenInLocal.amount.multiply(slippageIn)
}

// make sure we aren't dividing by zero to get a price
$: if (tokenOutLocal.amount?.numerator.length && tokenInLocal.amount) {
    price = new Price({baseAmount: tokenOutLocal.amount, quoteAmount: tokenInLocal.amount}).toSignificant(8)
}

function validateSwap(amountIn, amountOut) {
    const two = new Fraction(BN.from(2))
    const halfIn = tokenIn.poolBal.divide(two)
    if (amountIn.greaterThan(halfIn)) {
        swapError = "Amount in can't be higher than " + halfIn.toSignificant(6) + " right now."
    }
    return true
}

function refreshPrice() {
    const amount = tokenOutLocal.estimated ? tokenInLocal.amount : tokenOutLocal.amount
    calcSwap(amount, tokenOutLocal.estimated, true)
}

async function reverseSwap() {
    $swapReversed = !$swapReversed
    await tick()
    refreshPrice()
}

let swapRequests = 0

async function calcSwap(amount, exactIn, refresh) {
    clearTimeout(priceRefreshTimeout)
    swapRequests ++
    const thisSwap = swapRequests
    swapError = false
    calculating = true
    calculatingMsg = refresh ? 'Refreshing price' : 'Calculating'
    tokenOutLocal.estimated = exactIn ? true : false
    tokenInLocal.estimated = exactIn ? false : true

    let weights = await getTokenWeights(tokenIn, tokenOut, $bPool)

    if (exactIn) {
        tokenInLocal.amount = amount
        const result = await calcSwapExactIn(amount, weights)
        if ((thisSwap !== swapRequests) || swapError) { return }
        tokenOutLocal.amount = CurrencyAmount.fromRawAmount(tokenOut.currency, result)
        calculating = false
    } else {
        tokenOutLocal.amount = amount
        const result = await calcSwapExactOut(amount, weights)
        if ((thisSwap !== swapRequests) || swapError) { return }
        tokenInLocal.amount = CurrencyAmount.fromRawAmount(tokenIn.currency, result)
        calculating = false
    }

    validateSwap(tokenInLocal.amount, tokenOutLocal.amount)
    priceRefreshTimeout = setTimeout(()=>{refreshPrice()}, refreshPriceTime)
}

// called when there is a change to the 'from' field
async function calcSwapExactIn(amount, weights) {
    const result = await $bPool.calcOutGivenIn(
        toRaw(tokenIn.poolBal),
        weights[0],
        toRaw(tokenOut.poolBal),
        weights[1],
        toRaw(amount),
        swapFee
    ).catch(error => {
        console.log(error)
        calculating = false
        swapError = error.reason || "Something went wrong."
    })
    return result
}

// called when there is change to the 'to' field
async function calcSwapExactOut(amount, weights, refresh) {
    const result = await $bPool.calcInGivenOut(
        toRaw(tokenIn.poolBal),
        weights[0],
        toRaw(tokenOut.poolBal),
        weights[1],
        toRaw(amount),
        swapFee
    ).catch(error => {
        console.log(error)
        calculating = false
        swapError = error.reason || "Something went wrong."
    })
    return result
}

// poke the weights
async function poke() {
    modal = true
    txStatus = "waiting-on-signature-poke"
    let tx = await $crpWithSigner.pokeWeights().then(async(result)=>{
        txStatus = "verifying"
        await $provider.waitForTransaction(result.hash, 1)
        txStatus = "weights-updated"
    }).catch(error => {
        errorMsg = error.message
        if (error.data) {errorMsg = error.data.message}
        txStatus = "error"
    })
}

// open the modal
function openModal() {
    modal = true
    // stop refreshing the price
    clearTimeout(priceRefreshTimeout)
}

// close the modal
function closeModal() {
    modal = false
    // restart the price refresh loop
    refreshPrice()
}
</script>


<div class="bg-white p-5 shadow-md border border-gray-200 relative rounded-2xl flex flex-col space-y-4">

    <div class="text-gray-900 font-medium text-2xl">Swap</div>

    <!-- <div on:click={()=>{settingsModal = true}} class="absolute w-5 h-5 right-5 top-2 cursor-pointer">
        <IconLibrary icon="settings" width="20" />
    </div> -->

    <!-- The two swap fields -->

    <GenericSkeletonLoader show={tokenIn.symbol}>
        <TokenField 
        label="From"
        currency={tokenIn.currency}
        estimated={tokenInLocal.estimated}
        amount={tokenInLocal.amount}
        on:changed={({detail : { amount }})=>{calcSwap(amount, true)}}
        tokenSymbol={tokenIn.symbol}
        maxAmt={tokenIn.myBalance}
        tokenIcon={tokenIn.icon}
        bind:fieldValue={tokenInLocal.fieldValue}
        bind:balanceExceeded={tokenInLocal.balanceExceeded}
        bind:this={tokenInField}
    />
    </GenericSkeletonLoader>

    <div on:click={reverseSwap} class="self-center cursor-pointer">
        <IconLibrary icon="double-arrow" color="text-gray-800" width=30 />
    </div>

    <GenericSkeletonLoader show={tokenIn.symbol}>
        <TokenField 
        label="To"
        currency={tokenOut.currency}
        estimated={tokenOutLocal.estimated}
        amount={tokenOutLocal.amount}
        on:changed={({detail : { amount }})=>{calcSwap(amount, false)}}
        tokenSymbol={tokenOut.symbol}
        maxAmt={tokenOut.myBalance}
        tokenIcon={tokenOut.icon}
        bind:fieldValue={tokenOutLocal.fieldValue}
        bind:balanceExceeded={tokenOutLocal.balanceExceeded}
        bind:this={tokenOutField}
    />
    </GenericSkeletonLoader>

    <!-- Swap error messages -->
    {#if swapError}
        <InlineNotification small=true type="error">
            {swapError} Try another swap.
        </InlineNotification>
    {/if}

    <!-- Grid underneath the fields -->
    <div class="grid grid-cols-2 text-sm text-gray-500 items-center">
        {#if price}
            <div class="col-span-1">Price
                <HoverTooltip>
                    <IconLibrary color="text-gray-500" inline width=13 icon="info"/>
                    <span slot="tip">The average price for your swap.</span>  
                </HoverTooltip>
            </div>

            <div class="col-span-1 text-right">
                {price} 
                {tokenIn.symbol} per {tokenOut.symbol}
            </div>
        {/if}

        <div class="col-span-1">Slippage tolerance 
            <HoverTooltip>
                <IconLibrary color="text-gray-500" inline width=13 icon="info"/>
                <span slot="tip">Your transaction will revert if the price changes unfavourably by more than this percentage.</span>           
            </HoverTooltip>
        </div>

        <div class="col-span-1 text-right">
            <div class="flex flex-row justify-end items-center col-span-1">
                <span on:click={()=>{settingsModal=true}} class="underline cursor-pointer">{slippage}%</span>   
            </div>
        </div>
    </div>

    <!-- Buttons -->
    {#if calculating}
    <Button disabled=true>
        <span class="pr-2">
            {calculatingMsg}...
        </span>
        <svg class="animate-spin h-4 w-4 text-white inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    </Button>
    {:else if $account}
        <Button
            disabled={!$reserveToken || !$redeemableToken || !parseInt(tokenInLocal.fieldValue) && !parseInt(tokenOutLocal.fieldValue) || tokenInLocal.balanceExceeded || tokenOutLocal.balanceExceeded || swapError}
            on:click={openModal}
            variant="primary"
            label="Swap"
        />
    {:else if !$account}
        <Button on:click={account.connect} variant="secondary" label="Connect wallet" />
    {/if}

    {#if $account && $projectState !== 3}
        <Button on:click={poke} variant="secondary" label="Update price" />
    {/if}

</div>

<!-- Swap confirmation modal -->
{#if modal}
<Modal on:close={closeModal}>
    <ConfirmSwap 
        on:close={closeModal}
        on:complete={clearFields}
        tokenIn={tokenIn}
        tokenOut={tokenOut}
        tokenInLocal={tokenInLocal}
        tokenOutLocal={tokenOutLocal}
        slippage={slippage}
        maxPrice={maxPrice}
        txStatus={txStatus}
        errorMsg={errorMsg}
        priceRefreshTimeout={priceRefreshTimeout}
    />
</Modal>
{/if}

<!-- Settings modal for slippage -->
{#if settingsModal}
<Modal on:close={()=>{settingsModal = false}}>
    <Settings 
        bind:slippage={slippage}
        on:close={()=>{settingsModal = false}}
        />
</Modal>
{/if}




