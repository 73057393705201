<script>
import NavLink from './NavLink.svelte'
import Connect from './Connect.svelte'
import { constants } from '../../constants/Constants'
import { site } from '../../stores/Site';

const logo = constants.logo
let polygen_bg_style = "";
let logo_width = "170"
$: console.log($site)
if($site == 'polygen' || $site == 'polygenProd'){
    polygen_bg_style = "background-image: linear-gradient(to bottom right, #c30b13, #BD4AB4);background-color: #c30b13;";
    logo_width = "230";
} else if ($site == 'goldfinch' || $site == 'ff3') {
    polygen_bg_style = "background-image: url('./images/goldfinch/ff-header.jpg'); background-size: cover;";
    logo_width = "230";
}

</script>

<div class="bg-gray-100" style="{polygen_bg_style}">
    <div class="flex flex-row w-full justify-between items-center p-8">
        <div class="flex-1">
            <img src="{logo}" loading="lazy" alt="" class="flex-1" width='{logo_width}'>
        </div>
        <div class="flex-1 flex flex-row justify-center">
            <div class="font-normal text-sm rounded-4xl bg-white border border-gray-100 shadow-md flex flex-row overflow-hidden">
                <div class="p-2 space-x-2 flex flex-row">
                    <!-- {#if $site == 'polinate' || $site == 'polinateProd'} -->
                        <NavLink to="upcoming">Upcoming</NavLink>    
                    <!-- {/if} -->
                    <NavLink to="seed">Seed</NavLink>
                    <NavLink to="fund">
                        Fund
                    </NavLink>
                    <NavLink to="claim">Claim</NavLink>
                    {#if $site == 'polygen' || $site == 'polygenProd'}
                        <NavLink to="guide">Guide</NavLink>
                    {/if}
                    
                </div>
                <!-- <a  class="bg-blue-600 text-white flex flex-col items-center px-10 justify-center hover:bg-blue-700"
                    href='/create' 
                    use:link 
                    use:active={{className: 'active-create', inactiveClassName: 'inactive-create'}}>

                    <span>Create</span>
                </a> -->
            </div>
        </div>
        <div class="flex-1 flex flex-row justify-end">
            <Connect />
        </div>
    </div>
</div>