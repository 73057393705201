<script>
import { setContext } from 'svelte'
import { initProjectStores } from '../../stores/project/Project.js'

export let stores = initProjectStores()
setContext('trustStores', stores)

let trustAddress = stores.trustAddress
export let address
$: $trustAddress = address

let projectState = stores.projectState
export let state
$: state = $projectState
</script>

<slot></slot>