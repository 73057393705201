<script>
import AllTransactions from '../../views/project/fund/AllTransactions.svelte'
import ProgressBar from './ProgressBar.svelte'
import BigLabelledVal from '../labelledvalues/BigLabelledVal.svelte'

export let progress = 0
export let total = 0
export let color = 'green'
export let height = 5

$: props = {
    progress : progress,
    total : total,
    color : color,
    height : height
}

export let label
export let value

</script>

<div class="w-full flex flex-col">
    <BigLabelledVal label={label} value={value} />
    <ProgressBar {...props}/>
</div>

