<script>
    // svelte
    import { getContext } from 'svelte'
    
    // components
    import StatBlock from './StatBlock.svelte'
    
    $: ({
        initRedemptionValue,
        spotPrice,
        redeemableSupply,
        reserveCurrency,
        redeemableCurrency,
        poolBalanceRedeemable,
    } = getContext('trustStores'))
    
    // spot price
    $: _spotPrice = $spotPrice?.toSignificant?.(8) + ' ' + $reserveCurrency.symbol
    $: spotPriceLabel = $redeemableCurrency.symbol + ' spot price'
    
    // redemption value
    $: redemptionValue = $initRedemptionValue?.toSignificant?.(8) + ' ' + $reserveCurrency.symbol
    
    // balance
    $: redeemableTokenStat = $poolBalanceRedeemable?.toSignificant?.(8) + ' ' + $redeemableCurrency.symbol
    
    // supply available
    $: percentAvailable = Math.floor($poolBalanceRedeemable?.toFixed?.(4) / $redeemableSupply?.toFixed?.(4) * 100) + '%'
    </script>
    
    <div class="w-full space-x-3 flex flex-row mb-12">
    
    
    <StatBlock value={_spotPrice} label={spotPriceLabel} show={$spotPrice}/>
    <StatBlock value={redemptionValue} label="Redemption value" show={$initRedemptionValue !== null}/>
    <StatBlock value={redeemableTokenStat} label="In pool" show={$poolBalanceRedeemable !== null}/>
    <StatBlock value={percentAvailable} label="Supply available" show={$poolBalanceRedeemable !== null}/>
    
    </div>
