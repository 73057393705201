// svelte
import { writable, get} from 'svelte/store'

// stores
import { provider } from './Account'
import { tick } from './Tick'

const blocksToAverage = 50

let _provider
provider.subscribe((v)=>{
    _provider = v
})

export let blockSpeed = writable(2.1)
export let lastBlockReq = writable(null)

async function getBlockSpeed() {
    const requestTime = Date.now()
    var currentBlockNum = await _provider.getBlockNumber()
    var currentBlock = await _provider.getBlock(currentBlockNum)
    var prevBlock = await _provider.getBlock(currentBlockNum - blocksToAverage)
    var averageBlockTime = (currentBlock.timestamp - prevBlock.timestamp) / blocksToAverage
    blockSpeed.set(averageBlockTime)
    lastBlockReq.set({
        timestamp: requestTime,
        block: currentBlockNum
    })
}

tick.subscribe(()=>{
    if (_provider) {
        getBlockSpeed()
    }
})

