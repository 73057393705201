<script>
// svelte
import { getContext } from 'svelte'

// util
import { formatAddress } from '../../utils/utils'

// stores
import { account } from '../Account'
import { constants } from '../../constants/Constants';
let stores = getContext('trustStores')
let transactions = stores.transactions
let myTransactions = stores.myTransactions
let swapData = stores.swapData
const BLOCK_EXPLORER_TRANSACTION = constants.BLOCK_EXPLORER_TRANSACTION

// update on tick
$:if ($swapData) {
    formatTransactions()
}

function formatTransactions() {
    let transactionsRaw, myTransactionsRaw
    
    transactionsRaw = [...$swapData]

    // sort by timestamp
    transactionsRaw.sort(function(a, b) {
        return b.timestamp - a.timestamp
    })

    $transactions = arrayToObj(transactionsRaw)

    if ($account) {
        myTransactionsRaw = transactionsRaw.filter(log => {
            return log.caller.toUpperCase() == $account.toUpperCase()
        })
        $myTransactions = arrayToObj(myTransactionsRaw)
    }
}

function arrayToObj(array) {
    let objArray = []

    array.forEach((row)=>{
        let obj = {}
        obj.swap = row.tokenInSymbol + ' to ' + row.tokenOutSymbol
        obj.amountIn = row.tokenAmountInNum + ' ' + row.tokenInSymbol
        obj.amountOut = row.tokenAmountOutNum + ' ' + row.tokenOutSymbol
        obj.tokenPrice = row.tokenPrice
        obj.relativeTime = row.relativeTime
        obj.account = formatAddress(row.caller)
        obj.txLink = BLOCK_EXPLORER_TRANSACTION + row.transactionHash
        objArray.push(obj)
    })

    return objArray
}

</script>