<script>
// svelte
import { fade } from 'svelte/transition'
import { setContext } from 'svelte'

// components
import LoadingAnim from '../../components/util/LoadingAnim.svelte'
import Main from '../../layout/Main.svelte'

// views
import Fund from './fund/Fund.svelte'
import Seed from './seed/Seed.svelte'
import Claim from './claim/Claim.svelte'

// stores
import { initProjectStores } from '../../stores/project/Project'
import ProjectStores from '../../stores/project/ProjectStores.svelte'

export let params = {}

let stores = initProjectStores()
setContext('trustStores', stores)

let projectState = stores.projectState
let trustAddress = stores.trustAddress
$: $trustAddress = params.address
</script>

<Main>
    {#if $projectState !== null}
        <div class="h-full flex-grow flex flex-col justify-start items-center w-full" transition:fade="{{duration:200}}">
            {#if $projectState === 0 || $projectState === 1} 
            <Seed />
            {:else if $projectState === 2 || $projectState === 3}
            <Fund />
            {:else if $projectState === 4 || $projectState === 5}
            <Claim />
            {/if}
        </div>
    {:else}
        <div class="flex-grow flex flex-col justify-center items-center h-full">
            <LoadingAnim width="200px"/>
        </div>
    {/if}
</Main>

<!-- stores calc -->
<ProjectStores />
