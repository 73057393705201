import { CurrencyAmount, Fraction } from '@uniswap/sdk-core';
import { ethers } from 'ethers';
import { constants } from '../constants/Constants';
export function formatAddress(address) {
    let formatted = address.slice(0, 6) + '...' + address.slice(address.length - 4, address.length);
    return formatted;
}
var msPerMinute = 60 * 1000;
var msPerHour = msPerMinute * 60;
var msPerDay = msPerHour * 24;
var msPerWeek = msPerDay * 7;
var msPerMonth = msPerDay * 30;
var msPerYear = msPerDay * 365;
// to turn the timestamp into a relative 'X ago' string
export function timeDifference(current, previous) {
    var elapsed = current - previous;
    if (elapsed < msPerMinute) {
        let seconds = Math.floor(elapsed / 1000);
        return seconds == 1 ? +seconds + ' second ago' : seconds + ' seconds ago';
    }
    else if (elapsed < msPerHour) {
        let minutes = Math.floor(elapsed / msPerMinute);
        return minutes == 1 ? +minutes + ' minute ago' : minutes + ' minutes ago';
    }
    else if (elapsed < msPerDay) {
        let hours = Math.floor(elapsed / msPerHour);
        return hours == 1 ? +hours + ' hour ago' : hours + ' hours ago';
    }
    else if (elapsed < msPerMonth) {
        let days = Math.floor(elapsed / msPerDay);
        return days == 1 ? +days + ' day ago' : days + ' days ago';
    }
    else if (elapsed < msPerYear) {
        let months = Math.floor(elapsed / msPerMonth);
        return months == 1 ? +months + ' month ago' : months + ' months ago';
    }
    else {
        let years = Math.floor(elapsed / msPerYear);
        return years == 1 ? +years + ' year ago' : years + ' years ago';
    }
}
// to split a timestamp into the separate components
export function splitTime(timestamp) {
    let weeks = Math.floor(timestamp / msPerWeek);
    let lessWeeks = timestamp % msPerWeek;
    let days = Math.floor(lessWeeks / msPerDay);
    let lessDays = lessWeeks % msPerDay;
    let hours = Math.floor(lessDays / msPerHour);
    let lessHours = lessDays % msPerHour;
    let minutes = Math.floor(lessHours / msPerMinute);
    let lessMinutes = lessHours % msPerMinute;
    let seconds = Math.floor(lessMinutes / 1000);
    return [weeks, days, hours, minutes, seconds];
}
export function timeString(timestamp, options) {
    const timeArray = splitTime(timestamp);
    const weeks = timeArray[0] ? timeArray[0] + 'w ' : '';
    const days = timeArray[1] ? timeArray[1] + 'd ' : '';
    const hours = timeArray[2] ? timeArray[2] + 'h ' : '';
    const minutes = timeArray[3] ? timeArray[3] + 'm ' : '';
    const seconds = timeArray[4] ? timeArray[4] + 's' : '';
    const strings = new Map([
        ['wdhms', weeks + days + hours + minutes + seconds],
        ['wdhm', weeks + days + hours + minutes],
        ['wdh', weeks + days + hours],
        ['wd', weeks + days],
        ['w', weeks],
    ]);
    return strings.get(options) || weeks + days + hours + minutes + seconds;
}
export let BN = ethers.BigNumber;
const fourteenZerosBN = BN.from('100000000000000');
// to convert an eighteen zeros BN into a four decimal places number
export function bnToFourDecimal(BN) {
    let n = BN.div(fourteenZerosBN).toString() / 10000;
    return n;
}
// to divide two token values that are eighteen decimals
export function tokenDiv(dividend, divisor) {
    return dividend.mul(constants.eighteenZerosBN).div(divisor);
}
// to multiply two token values that are eighteen decimals
export function tokenMul(one, two) {
    return one.mul(two).div(constants.eighteenZerosBN);
}
// filter an array with an array of filters
function multiFilter(array, filters) {
    return array.filter(item => {
        // validates all filter criteria
        return filters.every(filter => {
            // ignores non-function predicates
            if (typeof filter !== 'function')
                return true;
            return filter(item);
        });
    });
}
function countDecimals(value) {
    if (Math.floor(value) === parseFloat(value))
        return 0;
    return value.toString().split(".")[1].length || 0;
}
// convert float to Fraction
export function toFraction(value) {
    value = value.toString();
    const decimals = countDecimals(value);
    const numerator = BN.from(value.replace('.', ''));
    const denominator = BN.from(10).pow(decimals);
    return new Fraction(numerator, denominator);
}
export function currencyAmountFromValue(currency, value) {
    const valueAsFraction = toFraction(value);
    const one = new Fraction(BN.from(10).pow(currency.decimals));
    const amount = one.multiply(valueAsFraction);
    return CurrencyAmount.fromFractionalAmount(currency, amount.numerator, amount.denominator);
}
export async function getTokenWeights(tokenIn, tokenOut, pool) {
    var tokenInWeight = await pool.getDenormalizedWeight(tokenIn.token.address);
    var tokenOutWeight = await pool.getDenormalizedWeight(tokenOut.token.address);
    return [tokenInWeight, tokenOutWeight];
}
export function toRaw(currencyAmount) {
    const one = new Fraction(BN.from(10).pow(currencyAmount.currency.decimals));
    const amount = currencyAmount.multiply(one);
    return BN.from(amount.toFixed(0));
}
