<script>
// svelte
import { getContext } from 'svelte'
import { bnToFourDecimal } from '../../utils/utils';

let stores = getContext('trustStores')

$: ({
    reserveInit,
    poolBalanceReserve,
    minRaise,
    redeemInit,
    reserveInit,
    seederFee,
    amountRaised,
    successBalance
} = stores)

$: if ($minRaise && $seederFee && $redeemInit) {
    $successBalance = $minRaise.add($seederFee).add($redeemInit)
}

$: if ($poolBalanceReserve && $reserveInit) {
    $amountRaised = $poolBalanceReserve.subtract($reserveInit)
}
</script>