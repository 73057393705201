import "constants";
import "ethers";
import 'tailwindcss/colors';
// factory address is to be updated
//export const FACTORY_ADDRESS = '0x8f72c8dd9a16a86519497d57fa7123268f95d9d1' // factory - old with KYC sell back issue
//export const FACTORY_ADDRESS = '0xaDb1efb43D0e5Dad2BA68F3750652493e1Ca8859' // new factory - with fix for KYC users to sell back rTKN
export const FACTORY_ADDRESS = '0x19B5aCe714b7231Fa6596e25e4E15CcDc5dc09eA'; // new factory - with fix for Balancer issue - check on token balance on exit
// graphQL api
//export const GRAPH_API_URL = "https://api.thegraph.com/subgraphs/name/beehive-innovation/rain-protocol-staging"
export const GRAPH_API_URL = "https://api.thegraph.com/subgraphs/name/beehive-innovation/rain-protocol";
// kyc
export const VERIFY_ADDRESS = '0xd0ce9e4cb06d63cb02a9a71a070d3b0be7db94d1'; // admin - 0x6646Bf1b4538E5124c96BF82f3f533bD64FFc1E2
export const VERIFY_TIER_DEFAULT = '0x25ACA096cbEF597Aa3B99CDD747805e58d1aea81'; // a default VerifyTier for Polygen
// tier
export const TIER_DEFAULT = '0x25ACA096cbEF597Aa3B99CDD747805e58d1aea81'; // a default VerifyTier for Polygen
export const MIN_TIER_MESSAGE = 'You need to complete the KYC to participate in this raise.';
// block explorer base path
export const BLOCK_EXPLORER_TRANSACTION = 'https://polygonscan.com/tx/';
export const BLOCK_EXPLORER_ADDRESS = 'https://polygonscan.com/address/';
export const RESERVE_DEFAULT = '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'; // USDC
// chain
export const CHAIN_ID = 137; // mumbai
export const NETWORK_NAME = "Matic";
// rpc
export const PROVIDER_RPC = 'https://polygon-mainnet.g.alchemy.com/v2/UwJdTDXBUAilq84N85m_3ZBrNovDXpCi';
// covalent api key
export const COVALENT_KEY = 'ckey_7e3942e77eb947f7ab1ad48a3ca';
export const PAGE_LENGTH = 999999; // historical log pagination - number of blocks
export const logo = "./images/polygen-logo-dapp-new.svg";
//export const logoReversed = "./images/polygen-logo-reversed.svg"
export const logoReversed = "./images/polygen-logo-icon.png";
export const favicon = "./images/favicon.png";
export const webclip = "./images/webclip.png";
export const siteName = "Polygen";
export const siteSub = "A permissionless launchpad to fund innovation";
export const KYC_BASE_URL = 'https://synaps-proxy-polygen.jupiter7108.workers.dev/';
export const KYC_CLIENT_NAME = 'polygen'; // 
export const KYC_ENABLED = true;
export const KYC_ENVIRONMENT = 'production'; // sandbox|production
export const KYC_LOCAL_STORAGE_KEY = 'polygen';
//export const curationContent = './curation/polygen-prod.json?v=2'
export const curationContent = 'https://dapp-assets.s3.eu-west-1.amazonaws.com/json_datasrouce/polygen/production/polygen-prod.json';
// theme for tailwind
export const theme = {
    extend: {
        colors: {
            primary: {
                100: '#F6F6FE',
                200: '#E8E8FD',
                300: '#D1D1FA',
                400: '#BEBFF8',
                500: '#A5A6F6',
                600: '#7879F1',
                700: '#5D5FEF',
                800: '#4042D4',
                900: '#2D2FB9'
            }
        }
    }
};
// header content
export const HEADER_CONTENT = {
    upcoming: "All the upcoming projects are listed here with raise details",
    seed: "Provide liquidity for the raise pool of a project you love and earn rewards. Investors, incubators or community members can provide liquidity for the seed raise pool",
    fund: "Buy your favorite Project tokens",
    claim: "Claim your Project tokens after a successful raise",
    new: "You can use this interface to know how you can get your project live for funding",
    guide: ""
};
