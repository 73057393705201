<script>
// svelte
import { getContext } from 'svelte'

// components
import Button from '../../../components/buttons/Button.svelte'
import InlineNotification from '../../../components/notifications/InlineNotification.svelte'
import Modal from '../../../components/modals/Modal.svelte'
import LoadingAnim from '../../../components/util/LoadingAnim.svelte'

// stores
import { account, signer, provider } from '../../../stores/Account'

// constants
import { bnToFourDecimal } from '../../../utils/utils'

$: ({
    contractsReadOnly : {
        pool : pool,
    },
    contractsWithSigner : {
        reserveToken : reserveToken,
        trust: trust
    },
    reserveInit,
    reserveCurrency,
    seeder
} = getContext('trustStores'))

let seederAllowance
let seederAllowanceOk
let seederCheck = 1
let isSeeder
let errorMsg
let txStatus

$: if ($seeder && seederCheck && $reserveToken) {
    $reserveToken.allowance($seeder, $pool.address).then((result)=>{
        seederAllowance = result
    })
}

$: if(seederAllowance) {seederAllowanceOk = seederAllowance.gte($reserveInit.bn)}
$: if($account && $seeder) {isSeeder = $seeder.toUpperCase() == $account.toUpperCase()}

async function startRaise() {
    txStatus = "waiting-on-signature"
    let tx = await $trust.startRaise({
        // gasLimit: 100000000
    }).then((tx)=>{
        txStatus = "verifying"
        return $provider.waitForTransaction(tx.hash, 3)
    }).then(()=>{
        txStatus = null
    }).catch(error => {
        errorMsg = error.message
        if (error.data) {errorMsg = error.data.message}
        txStatus = "error"
    })
}

async function approveAllowance() {
    txStatus = "waiting-on-signature"
    let tx = await $reserveToken.approve($pool.address, $reserveInit.bn).then(async (result)=>{
      txStatus = "verifying"
      await $signer.provider.waitForTransaction(result.hash, 3)
      txStatus = null
      seederCheck++
    }).catch(error => {
        errorMsg = error.message
        if (error.data) {errorMsg = error.data.message}
        txStatus = "error"
    })
}

</script>


{#if seederAllowanceOk}

    <div class="">
        This raise is ready to start.
    </div>

    {#if $account}
        <Button label="Start raise" on:click={startRaise} />
    {:else}
        <Button on:click={account.connect} label="Connect wallet" variant="secondary" />
    {/if}

{/if}

{#if seederAllowanceOk == false  && !$account}

    <div class="space-y-5">
        <InlineNotification type="warning">
            The seeder for this raise hasn't approved transfer of pool liquidity for the reserve token.
        </InlineNotification>
        <div>
            If you're the seeder, connect and approve the allowance.
        </div>
    </div>

    {#if $account}
        <Button label="Start raise" on:click={startRaise} />
    {:else}
        <Button on:click={account.connect} label="Connect wallet" variant="secondary" />
    {/if}

{/if}

{#if seederAllowanceOk == false && isSeeder == false}

    <InlineNotification type="warning">
        This raise can't be started yet, as the seeder hasn't approved transfer of pool liquidity for the reserve token.
    </InlineNotification>

{/if}

{#if seederAllowanceOk == false && isSeeder}

    <InlineNotification type="warning">
        This raise can't be started until transfer of reserve token for pool liquidity has been approved by you.
    </InlineNotification>

    <div class="space-y-2">
        <div>Current allowance: {bnToFourDecimal(seederAllowance)} {$reserveCurrency.symbol}</div>
        <div>Amount to be approved: {bnToFourDecimal($reserveInit.bn)} {$reserveCurrency.symbol}</div>
    </div>

    <Button label="Approve allowance" on:click={approveAllowance} />

{/if}
    

{#if txStatus}
    <Modal>
        {#if txStatus == "waiting-on-signature"}
        <div class="w-full flex flex-col items-center">
            <LoadingAnim />
            Waiting on signature...
        </div>
        {:else if txStatus == "verifying"}
        <div class="w-full flex flex-col items-center">
            <LoadingAnim />
            Confirming Transaction...
        </div>
        {:else if txStatus == "error"}
        <span class="tx-lg font-medium">Error</span>
        <InlineNotification type="error">{errorMsg}</InlineNotification>
        {/if}
    </Modal>
{/if}

<!-- 


Allowance ok ==>                            start raise

Allowance not ok, don't know if seeder ==>  The seeder for this raise hasn't approved transfer of pool liquidity 
                                            for the reserve token.
                                            if you're the seeder, connect and approve the allowance.

Allowance not ok, not seeder ==>            This raise can't be started yet, as the seeder hasn't approved
                                            transfer of pool liquidity for the reserve token.

Allowance not ok, is seeder ==>             This raise can't be started until the transfer of resrve token for
                                            pool liquidity has been approved by you.
                                            Reserve to be approved: XXXX
                                        
-->