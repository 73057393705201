<script>
import { constants } from "../constants/Constants"
</script>

<div class="flex-grow flex flex-col items-center pt-64 px-8 gap-y-6 bg-primary-900">
    <img src="{constants.logoReversed}" />
    <div class="text-white text-center text-md font-semibold">
        {constants.siteName} is only available on desktop for now.
    </div>
</div>
