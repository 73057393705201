<script>
// modules
import { getContext } from 'svelte'

// components
import Swap from './swap/Swap.svelte'
import RaiseProgress from './RaiseProgress.svelte'
import ProjectName from '../components/ProjectName.svelte'
import ProjectCreator from '../components/ProjectCreator.svelte'
import StatBlocks from './StatBlocks.svelte'
import TableTabs from './TableTabs.svelte'
import ProjectImage from '../components/ProjectImage.svelte'
import ProjectBodyTabs from '../components/ProjectBodyTabs.svelte'
import Graphs from './Graphs.svelte'
import Steps from '../../../components/steps/Steps.svelte'
import Toggle from '../../../components/toggle/Toggle.svelte'
import Spacer from '../../../components/util/Spacer.svelte'
import StartRaise from './../seed/StartRaise.svelte'
import TutorialPanel from './../components/TutorialPanel.svelte'

// stores
let stores = getContext('trustStores')
let projectState = stores.projectState

export let advanced

</script>

<div class="w-full flex flex-row justify-between py-5 mx-auto">
    <Steps steps={['Seed', 'Fund', 'Claim']} activeStep=1/>
    <Toggle bind:active={advanced} label="Advanced"/>
</div>

<div class="w-full grid grid-cols-5 gap-4 py-5 mx-auto">
    <div class="col-span-3 flex flex-col space-y-3">
        <ProjectName />
        <ProjectCreator />
        <Spacer height=2 />
        {#if advanced}
        <Graphs />
        <StatBlocks />
        <Spacer height=4 />
        <TableTabs />
        {:else}
        <ProjectImage />
        <Spacer height=4 />
        <ProjectBodyTabs />
        {/if}
    </div>
    <div class="col-span-2 space-y-6">
        {#if $projectState === 2 || $projectState === 3}
            <TutorialPanel />
            <RaiseProgress />
            <div class="sticky top-4">
                <Swap />
            </div>
        {/if}
    </div>
</div>