<script>
import { fly } from 'svelte/transition'
let visible, timer

export function flash() {
    visible = true
    clearTimeout(timer)
    timer = setTimeout(()=>{visible=false}, 1800)
}
</script>

{#if visible}
    <div 
        transition:fly={{ y:10, duration:500 }}
        class="rounded-md transform -translate-x-1/2 -translate-y-full left-2/4 -top-2 px-3 py-2 absolute max-w-sm text-xs bg-gray-800 text-gray-300 z-10">
        <slot></slot>
    </div>
{/if}