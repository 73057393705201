import { writable } from 'svelte/store';

export let initProjectStores = () => {
    return {
        // trust address
        trustAddress : writable(0),

        // Contracts.svelte
        contractAddresses : writable(0),
        contractsReadOnly : {
            trust : writable(0),
            crp : writable(0),
            pool : writable(0),
            reserveToken : writable(0),
            redeemableToken : writable(0),
            bPool : writable(0),
            seeder : writable(0)
        },
        contractsWithSigner : {
            trust : writable(0),
            crp : writable(0),
            pool : writable(0),
            reserveToken : writable(0),
            redeemableToken : writable(0),
            bPool : writable(0),
            seeder : writable(0)
        },

        // ReserveAndRedeemable.svelte
        redeemableSupply : writable(0),
        reserveCurrency : writable(0),
        redeemableCurrency : writable(0),

        // TrustInfo.svelte
        trustConfig : writable(0),
        trustBounds : writable(0),
        initRedemptionValue : writable(null),
        creator : writable(0),
        finalWeight : writable(0),
        seeder : writable(null),

        // RaiseProgress.svelte
        projectState : writable(null),
        poolBalanceReserve : writable(null),
        poolBalanceReserveStr : writable(null),
        poolBalanceRedeemable : writable(null),
        poolBalanceRedeemableStr : writable(null),
        minRaise : writable(null),
        redeemInit : writable(null),
        redeemInitNum : writable(0),
        reserveInit : writable(null),
        seederFee : writable(null),
        raiseDuration : writable(null),

        // TimeRemaining.svelte
        timeRemaining : writable(null),
        timeSpent : writable(null),

        // AmountRaised.svelte
        amountRaised : writable(null),
        successBalance : writable(null),

        // SwapData.svelte
        swapData : writable(0),

        // SpotPrice.svelte
        spotPrice : writable(null),

        // MyBalances
        myBalances : writable(0),
        reserveAllowance : writable(0),
        redeemableAllowance : writable(0),
        seedTokenBalance : writable(0),
        seedTokenBalanceNum : writable(0),

        // Transactions.svelte
        transactions : writable(0),
        myTransactions : writable(0),

        // TopHolders.svelte
        topHolders: writable(0),

        // Redeemables.svelte
        redeemTokens : writable(null),
        redeemCurrencies : writable(null), 
        redeemBalances : writable(null),
        redeemAddresses : writable(null),
        myRedeemBalances : writable(null),
        getRedeemables : writable(null),
        redeemShares : writable(null),

        // Seeder.svelte
        seederUnitPrice : writable(null),
        seederUnits : writable(0),
        seederUnitSupply : writable(0),
        seederUnitsAvail : writable(0),
        seedFeePerUnit : writable(null),
        unseedDelay : writable(0),
        userSeed : writable(0),
        seederCurrency : writable(0),
        seederBalance : writable(0),
        seedERC20Balance : writable(null),
        
        // Swap.svelte
        swapReversed : writable(0),

        // Metadata
        metadata : writable(null),

        // Tiers
        myTier : writable(null)
    }
}