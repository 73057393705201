<script>
import lottie from 'lottie-web';
import { onMount } from 'svelte';

export let width = '80%';

onMount(async () => {
  lottie.loadAnimation({
    container: document.getElementById("60d0da4c-fbd0-f892-74a9-5f1fbad8326e"), // the dom element that will contain the animation
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: '/documents/lf30_editor_hlcm9d0i.json' // the path to the animation json
  });
});
</script>

<div id="60d0da4c-fbd0-f892-74a9-5f1fbad8326e" style="width: {width}"></div>
 