<script>
// components
import DisplayAddress from '../../components/user/DisplayAddress.svelte'
import Jazzicon from '../../components/user/Jazzicon.svelte'
import KYCStatus from '../../components/user/KYCStatus.svelte'
// stores
import { account, isMM, signer } from '../../stores/Account'
import { site } from '../../stores/Site.js'
import GenericSkeletonLoader from '../../components/loader/GenericSkeletonLoader.svelte'

import { constants } from '../../constants/Constants'
import { getOnChainKYCStatus, KYCStates } from '../../utils/kyc'
import { KYC_SESSION_STATUSES, getKYCSessionIdForAccount, getKYCSessionDetailsById, setKYCSessionStatus } from '../../utils/kycAPIProxy'

let kycMessage = 'Check KYC status'

$: displayMessage = kycMessage
$: tooltip = ''

let on_chain_kyc_status = KYCStates.NOT_ADDED
let on_chain_kyc_status_timestamp_date = ''
let on_chain_kyc_timestamp = 0

$: if ($account && $site) {
    kycMessage = 'Check KYC status'
    displayMessage = 'Check KYC status'
    tooltip = ''
    if (!($site !== 'goldfinch' || $site !== 'ff3')) {
        _getOnChainKYCStatus($account)
    }
}else{
    displayMessage = 'Check KYC status'
}

const _getOnChainKYCStatus = async (account) =>{
    let onChainStatuses = await getOnChainKYCStatus($signer, account)
    
    on_chain_kyc_status = onChainStatuses[0]
    on_chain_kyc_timestamp = onChainStatuses[2]
    on_chain_kyc_status_timestamp_date = onChainStatuses[3]

    await setKYCStatus(account)
}

const setKYCStatus = async(account) => {

    if (on_chain_kyc_status == KYCStates.APPROVED){
        kycMessage = 'KYC Completed'
        tooltip = 'KYC approved since: ' + on_chain_kyc_status_timestamp_date
    } else if (on_chain_kyc_status == KYCStates.BANNED){
        kycMessage = 'Your wallet is banned, please contact support'
        tooltip = 'KYC approved since: ' + on_chain_kyc_status_timestamp_date
    }else if (on_chain_kyc_status == KYCStates.ADDED || on_chain_kyc_status == KYCStates.NOT_ADDED){
        
        //kycMessage = 'KYC Under Review'
        //tooltip = 'Application is under review'

        //Check KYC application status - using get seesion by id and get session overview api
        let is_active_session_exits = false
        let is_session_verified = false

        let session_id = await getKYCSessionIdForAccount(account)

        const currentSession = await getKYCSessionDetailsById(session_id)

        if(currentSession.status === KYC_SESSION_STATUSES.VERIFIED){
            is_session_verified = true
        }else if(currentSession.status === KYC_SESSION_STATUSES.CANCELLED){
            kycMessage = 'KYC application cancelled'
            tooltip = 'Your KYC application is cancelled'
        }else if(currentSession.status === KYC_SESSION_STATUSES.PENDING){
            
            let sessionStatuses = await setKYCSessionStatus(account)
            
            let is_kyc_application_started = sessionStatuses[1]
            let is_kyc_application_incomplete = sessionStatuses[2]
            let is_kyc_application_under_review = sessionStatuses[3]
            let is_kyc_application_validated = sessionStatuses[4]
            let is_kyc_application_rejected = sessionStatuses[5]
            let kyc_rejection_reasons = sessionStatuses[6]

            if(is_kyc_application_started){
                kycMessage = 'Start KYC'
                tooltip = 'KYC application is not initiated'
            }else if(is_kyc_application_incomplete){
                kycMessage = 'KYC Incomplete'
                tooltip = 'KYC application is to be completed'
            }else if(is_kyc_application_rejected){
                kycMessage = 'KYC Rejected'
                tooltip = 'KYC application is rejected'
            }else if((is_session_verified || is_kyc_application_validated || is_kyc_application_under_review) && on_chain_kyc_status == KYCStates.NOT_ADDED){
                kycMessage = 'KYC Submission Pending'
                tooltip = 'KYC is application is pending submission on chain'
            }else if(is_kyc_application_under_review){
                kycMessage = 'KYC Under Review'
                tooltip = 'KYC application is under review'
            }else{
                kycMessage = 'Start KYC'
                tooltip = 'You have not yet started the KYC application'
            }
        }
    }
    console.log(kycMessage);
    displayMessage = kycMessage
}

</script>

<style>
    .connect-button {
        background-color: transparent;
    }
</style>

{#if $isMM === 1}
<div>
    {#if $account}
        <div class="p-2 rounded-full bg-white shadow-md space-x-2 flex flex-row items-center border border-gray-200">
            <div class="flex flex-col items-end pl-5 pr-2">
                <div class="text-sm font-semibold">Connected</div>
                <div class="text-sm">
                    <DisplayAddress address={$account} />
                </div>
            </div>
            {#key $account}
                <div class="jazzicon"><Jazzicon width=38 address={$account} /></div>
            {/key}
        </div>
        <div>
            {#if constants.KYC_ENABLED === true}
                <div style="padding-top: 1em; ">
                    <GenericSkeletonLoader show={displayMessage != 'Check KYC status'}>
                        <KYCStatus kycMessage={displayMessage} />
                    </GenericSkeletonLoader>
                </div>
            {/if}
        </div>
    {:else}
        <button on:click={account.connect} class="py-5 px-7 text-sm rounded-full bg-white shadow-md space-x-2 flex flex-row">Connect</button>
        {#if constants.KYC_ENABLED === true}
            <div style="padding-top: 1em; ">
                <!-- <GenericSkeletonLoader show={displayMessage != ''}> -->
                    <KYCStatus kycMessage={displayMessage} tooltip={tooltip} />
                <!-- </GenericSkeletonLoader> -->
            </div>
        {/if}
    {/if}
</div>
{/if}
