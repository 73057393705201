<script>
import { CurrencyAmount } from '@uniswap/sdk-core';
// svelte
import { getContext } from 'svelte'

// util
import { BN, formatAddress } from '../../utils/utils'

// stores
let stores = getContext('trustStores')

// contracts
let redeemableToken = stores.contractsReadOnly.redeemableToken
let redeemableCurrency = stores.redeemableCurrency

let topHolders = stores.topHolders
let swapData = stores.swapData

$: if ($swapData && $redeemableToken) {
    let uniqueCallers = new Set()
    // get all the unique addresses
    $swapData.forEach((swap)=>{
        uniqueCallers.add(swap.caller)
    })

    let topHoldersArr = []
    uniqueCallers.forEach((caller)=>{
        let obj = {}

        // just get the swaps for this caller
        var callersSwaps = $swapData.filter((swap)=>{
            return swap.caller == caller
        })

        // add or subtract each swap
        const tokenAddress = $redeemableToken.address
        var balance = CurrencyAmount.fromRawAmount($redeemableCurrency, BN.from(0))

        callersSwaps.forEach((swap)=>{
            if (swap.tokenIn.toUpperCase() == tokenAddress.toUpperCase()) {
                balance = balance.subtract(swap.tokenAmountIn)
            } else if (swap.tokenOut.toUpperCase() == tokenAddress.toUpperCase()) {
                balance = balance.add(swap.tokenAmountOut)
            }
        })

        // push onto the array
        topHoldersArr.push({
            balance : balance.toSignificant(6),
            account : formatAddress(caller)
        })
    })
    // sort by the amount held
    topHoldersArr.sort((a,b)=>{return parseFloat(b.balance) - parseFloat(a.balance)})

    // save to the store
    topHolders.set(topHoldersArr)
}
</script>