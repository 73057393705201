<script>
import Button from '../components/buttons/Button.svelte';
import { account } from '../stores/Account'
import { constants } from '../constants/Constants'
import { fade } from 'svelte/transition';
</script>

<div transition:fade={{duration:200}} class="flex-grow flex flex-col items-center justify-center bg-gradient-to-bl from-primary-100 to-white gap-y-10">
    <div class="flex flex-col items-center">
        <img class="mb-4 w-72" src={constants.logo} />
        <div class="text-2xl font-semibold text-gray-700">
            {constants.siteSub}
        </div>
    </div>

    <div class="text-gray-600">
        Please
        <a class="underline transition-colors hover:text-primary-800" href="https://metamask.io/" target="_blank">
        install Metamask</a> 
        and 
        <a class="underline hover:text-primary-800 transition-colors" href="https://docs.matic.network/docs/develop/metamask/config-polygon-on-metamask/" target="_blank">
        set up the Polygon network
        </a>
        to get started.
    </div>
    <a class="transition-transform transform hover:scale-105" href="https://metamask.io/" target="_blank">
        <img class="w-72" src="./images/metamask-fox-wordmark-horizontal.svg" />
    </a>
</div>