<script>
import Step from './Step.svelte'
export let steps = []
export let activeStep
</script>

<div class = "flex flex-row space-x-2 items-center">
    {#each steps as step, i}
        <Step name={step} step={i} activeStep={activeStep} />
        {#if i !== steps.length - 1}
            <span class="w-32 h-px border-t border-gray-200"></span>
        {/if}
    {/each}
</div>