<script>
  import Button from './../../components/buttons/Button.svelte'
  import { account, signer, provider } from './../../stores/Account.js'
  import SynapsClient from '@synaps-io/verify.js'
  import Main from '../../layout/Main.svelte'
  import { kycContracts, uuidToBytes, KYCStates, getOnChainKYCStatus, getKYCStateLabel } from '../../utils/kyc'
  import { ethers } from 'ethers'
  import { get } from 'svelte/store'
  import Steps from '../../components/steps/Steps.svelte'
  import InlineNotification from '../../components/notifications/InlineNotification.svelte'
  import GenericSkeletonLoader from '../../components/loader/GenericSkeletonLoader.svelte'

  import { constants } from '../../constants/Constants'
  import { KYC_SESSION_STATUSES, getNewKYCSession, setKYCSessionStatus, getKYCSessionIdForAccount, getKYCSessionDetailsById
    //is_kyc_application_incomplete, is_kyc_application_under_review, is_kyc_application_validated, is_kyc_application_rejected, kyc_rejection_reasons 
  } from '../../utils/kycAPIProxy'

  
  let _console_log_enabled = false

  let connected_wallet_session_id = null
  let is_loading = false

  let kycContract
  let status, errorMsg
  let is_kyc_session_verified = false
  let out_of_matic = true

  let on_chain_kyc_status = KYCStates.NOT_ADDED
  let on_chain_kyc_status_timestamp_date = ''
  let on_chain_kyc_timestamp = 0

  let is_kyc_application_started = false
  let is_kyc_application_incomplete = false
  let is_kyc_application_under_review = false
  let is_kyc_application_validated = false
  let is_kyc_application_rejected = false
  let kyc_rejection_reasons = []

  let is_kyc_application_not_proceeded = false

  const steps = ['Connect Wallet', 'Start KYC', 'Submit Session', 'KYC Status']

  /// First check if a session exists for the connected wallet/account?
  ///   IF NO - Then initiate a new session using initKYC
  ///   ID YES - Check the status of the session.
  ///      IF PENDING then - resume the session with same session id
  ///      IF FINISHED then - show that KYC is already completed. 
  ///                         Also check the session status in the contract...
  ///      IF PENDING then - resume the session with the same session id

  ///
  ///
  ///
  ///
/*
1- restart in case selected account/wallet address changes
2 - get status of the connect account in the tier contract if already added and its status (added, approved or banned)
*/

  $: if ($account && constants.KYC_ENABLED === true) {
    init()
  }

  const init = async() => {

    is_loading = true

    resetLocalStatus();

    kycContract = kycContracts($signer)[0]

    let onChainStatuses = await getOnChainKYCStatus($signer, $account)

    setOnChainStatuses(onChainStatuses)

    let _msg = 'on_chain_kyc_status: ' + on_chain_kyc_status + ' > ' + getKYCStateLabel(on_chain_kyc_status) + ' since: ' + on_chain_kyc_status_timestamp_date

    __logConsole(_msg)

    __logConsole('>>>>Connected account: ' + $account)

    //If KYC is APPROVED or BANNED then nothing to do apart from showing the proper message
    if(on_chain_kyc_status != KYCStates.APPROVED && on_chain_kyc_status != KYCStates.BANNED){
      await setSessionIdForAccount($account)
      __logConsole('Current session id: ' + connected_wallet_session_id)
    }

    is_loading = false
  }

  const setOnChainStatuses = (onChainStatuses)=>{
    on_chain_kyc_status = onChainStatuses[0]
    on_chain_kyc_timestamp = onChainStatuses[2]
    on_chain_kyc_status_timestamp_date = onChainStatuses[3]
  }

  const resetLocalStatus = () => {

    connected_wallet_session_id = null

    kycContract = status = errorMsg = null
    is_kyc_session_verified = false

    on_chain_kyc_status = KYCStates.NOT_ADDED
    on_chain_kyc_status_timestamp_date = ''
    on_chain_kyc_timestamp = 0

    is_kyc_application_started = false
    is_kyc_application_incomplete = false
    is_kyc_application_under_review = false
    is_kyc_application_validated = false
    is_kyc_application_rejected = false
    kyc_rejection_reasons = []

    is_kyc_application_not_proceeded = false
  }

  const setSessionIdForAccount = async(account) => {
  
      connected_wallet_session_id = await getKYCSessionIdForAccount(account)

      const currentSession = await getKYCSessionDetailsById(connected_wallet_session_id)

      if(currentSession.status === KYC_SESSION_STATUSES.VERIFIED){
        __logConsole('Account has KYC session verified already: ' + currentSession.session_id)
        is_kyc_session_verified = true;
      }else if(currentSession.status === KYC_SESSION_STATUSES.CANCELLED){
        __logConsole('KYC session IS CANCELLED: ' + currentSession.session_id)
        //per Synaps this won't be the case
      }else if(currentSession.status === KYC_SESSION_STATUSES.PENDING){
        let sessionStatuses = await setKYCSessionStatus(account)
        setLocalMembers(sessionStatuses)
      }

    if(is_kyc_session_verified == true || is_kyc_application_under_review == true){
      //Take the user to step 3 to submit on chain. This is already handle down the line on this page
    }else{
      //Initialize session to resume
      launchKYCSession(connected_wallet_session_id);
    }
  }

  const setLocalMembers = (sessionStatuses) => {
    is_kyc_application_started = sessionStatuses[1]
    is_kyc_application_incomplete = sessionStatuses[2]
    is_kyc_application_under_review = sessionStatuses[3]
    is_kyc_application_validated = sessionStatuses[4]
    is_kyc_application_rejected = sessionStatuses[5]
    kyc_rejection_reasons = sessionStatuses[6]
  }

  const resetAndCreateNewSession = async(_account) => {
    __logConsole('Create new session for ' + _account)
    resetLocalStatus();

    __logConsole('Old session id: ' + connected_wallet_session_id)
    connected_wallet_session_id = await getNewKYCSession(_account)
    __logConsole('New session id: ' + connected_wallet_session_id)
    
    await setSessionIdForAccount(_account)
    location.reload()
  }

  const launchKYCSession = async(sessionId) => {
    if(sessionId){
        __logConsole('Initiate session for sessionId: ' + sessionId, true)
        const Synaps = new SynapsClient(sessionId, 'workflow')
        Synaps.init()

        Synaps.on('finish', async() => {
          is_kyc_application_not_proceeded = true
          processCurrentKYCState();
      })
    }else{
      __logConsole('KYC session id is not set!')
    }
  }

  const processCurrentKYCState = async () => {

    let sessionStatuses = await setKYCSessionStatus($account)

    setLocalMembers(sessionStatuses)

    if(is_kyc_application_incomplete){
      is_kyc_application_not_proceeded = false
    }

    if(is_kyc_application_rejected){
      __logConsole("Your KYC application is rejected. Click XYZ CTA to start a new session. \nReason:" + kyc_rejection_reasons.join(', '))
    }

    if(is_kyc_application_incomplete){
      __logConsole("Can't proceed without submitting all the required details for KYC")
    }
  }

  const __logConsole = (msg, override_default = false) => {
    if(_console_log_enabled || override_default == true){
      console.log(msg);
    }
  }

  const addKYC = async () => {
    if(connected_wallet_session_id){
      kycContract
        .add(ethers.BigNumber.from(uuidToBytes(connected_wallet_session_id)))
        .then(async (result) => {
          status = 'tx-verifying'
          await $provider.waitForTransaction(result.hash, 1)
          status = 'tx-verified'
          //on_chain_kyc_status = KYCStates.ADDED
          let onChainStatuses = await getOnChainKYCStatus($signer, $account)
          setOnChainStatuses(onChainStatuses)
        })
        .catch((error) => {
          errorMsg = error.message
          if (error.data) {
            errorMsg = error.data.message
          }
          if (errorMsg == 'execution reverted: SESSION_EXISTS') {
            errorMsg = 'A KYC session has already been submitted for this account.'
          }
          status = 'error'
        })
    }else{
      __logConsole('Connect wallet not found: ' + connected_wallet_session_id, true)
    }
  }
</script>

<Main>
  <div class="flex flex-col flex-grow justify-start pt-24">
    <div class="p-10 border rounded-lg bg-white max-w-4xl">
      <GenericSkeletonLoader show={is_loading == false}>
        {#if constants.KYC_ENABLED === true}
          {#if on_chain_kyc_status == KYCStates.APPROVED}
            <Steps {steps} activeStep="4" />
            <div class="text-lg font-semibold mt-10 mb-5">KYC Status!</div>
            <div class="mb-5">
              Congratulations! You have successfully completed the KYC process.
              {#if on_chain_kyc_timestamp > 0}
                <br/><br/>
                Your wallet address was approved on {on_chain_kyc_status_timestamp_date}
              {/if}
            </div>
          {:else if on_chain_kyc_status == KYCStates.BANNED}
              <Steps {steps} activeStep="4" />
              <div class="text-lg font-semibold mt-10 mb-5">KYC Status!</div>
              <div class="mb-5">
                Your wallet address is banned due to issues with your KYC application. 
                If you wish to make an inquiry, please reach out to us at support@polygen.io to appeal for the ban to be lifted so you can redo the KYC process.
                {#if on_chain_kyc_timestamp > 0}
                  <br/><br/>
                  Your wallet address has been banned since {on_chain_kyc_status_timestamp_date}
                {/if}
              </div>
          {:else if on_chain_kyc_status == KYCStates.ADDED && (is_kyc_session_verified == true || is_kyc_application_under_review == true)}
              <Steps {steps} activeStep="4" />
              <div class="text-lg font-semibold mt-10 mb-5">KYC Status!</div>
              <div class="mb-5">
                Your KYC application is now complete. 
                Please come back in 24 hours from the time of submitting your application to view the status of your KYC.
              </div>
          {:else if on_chain_kyc_status == KYCStates.NOT_ADDED && (is_kyc_session_verified || is_kyc_application_under_review || is_kyc_application_validated) }
            <Steps {steps} activeStep="2" />
            <div>
              <div class="text-lg font-semibold mt-10 mb-5">Submit session.</div>
              <div class="mb-5">
                Your account is linked to session ID <span class="font-medium">{connected_wallet_session_id}</span>. Please submit this on-chain using your wallet.
                <br>
                <br>
                Important: Please note that your wallet will not be eligible to participate in a project raise 
                <span class="font-medium">if you do not submit your session ID on-chain</span> even if your KYC is successfully completed!
                {#if out_of_matic}
                  <br>
                  <br>
                  <br>
                  <div>
                    <strong style="text-decoration: underline;">Out of MATIC to submit session on chain?</strong>
                    <br/>
                    No problem as we got you covered. Click the link below to get some MATIC to submit your KYC application on chain.
                    <br/><br/>
                    *Please be noted that MATIC will only be transferred to your wallet address if you have completed your KYC application which includes completing the liveness check and uploading an identity proof.
                    <br/><br/>
                    <a href="https://forms.gle/ZcVJRMiBX2WumLxS7" target="_blank" style="text-decoration: underline;">Click here to get some MATIC</a>
                  </div>
                {/if}
              </div>
              {#if status == 'tx-verifying'}
                <Button disabled={true}>Verifying Transaction...</Button>
              {:else}
                <Button on:click={addKYC}>Submit</Button>
              {/if}
              {#if errorMsg}
              <div class='mt-5'>
                <InlineNotification type="error">{errorMsg}</InlineNotification>
              </div>
              {/if}
            </div>
          {:else if $account}
            <Steps {steps} activeStep="1" />
            <div class="text-lg font-semibold mb-5 mt-10">
              You're ready to start KYC verification
            </div>
            <div class="mb-5">
              Before you start your KYC application, please ensure that:
              <li>
                You have enough MATIC (please have at least 0.01 MATIC) for paying the gas fee.
              </li>
              <li>
                You have a valid Identity Document (Passport/National ID Card/Driver’s license).
              </li>
              <br/><br/>
              <b>Disclaimer:</b><br>
              Residents of the United States and China are <b>NOT</b> allowed to do KYC on this platform.
              <br/><br/>
              *Please do not navigate away from this page until the KYC process is complete.
              <br/><br/>
                {#if is_kyc_application_incomplete}
                  Clicking resume will open the Synaps portal to resume your KYC application. After you complete and close the portal, you will need to submit your KYC session on-chain.
                {:else}
                  Clicking start will open the Synaps portal to submit your KYC details. After you complete and close the portal, you will need to submit your KYC session on-chain.
                {/if}
            </div>
            {#if (is_kyc_application_incomplete || is_kyc_application_not_proceeded) && is_kyc_application_rejected == false }
              <div style="margin-bottom: 10px;">
                <InlineNotification type="warning">You need to provide all the details to proceed to the next step. Please click below to continue!</InlineNotification>
              </div>
            {/if}
            {#if is_kyc_application_rejected}
              <div style="margin-bottom: 10px;">
                <InlineNotification type="error">Your KYC application is rejected.
                  <br/><br/>Reason: {(kyc_rejection_reasons.length > 0) ? kyc_rejection_reasons.join('. ') : 'Unknown'}
                </InlineNotification>
                <br/>
                <Button id="synaps-btn" on:click={resetAndCreateNewSession($account)}>Click here to submit a new application</Button>
              </div>
            {:else}
              <Button id="synaps-btn" on:click={launchKYCSession(connected_wallet_session_id)}>{#if is_kyc_application_incomplete}Resume{:else}Start{/if}</Button>
            {/if}
          {:else}
            <Steps {steps} activeStep="0" />
            <div class="text-lg font-semibold mb-5 mt-10">
              To start the KYC for Polygen, please connect your wallet
            </div>
            <div class="mb-5">
              Connect your wallet to start the KYC process.
            </div>
            <Button on:click={account.connect}>Connect wallet</Button>
          {/if}
        {:else}
          <div class="mb-5">
            {
              __logConsole('KYC is not enabled for this app!')
            }
          </div>
        {/if}
      </GenericSkeletonLoader>
    </div>
  </div>
</Main>
