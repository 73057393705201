<script>
import { getContext, onMount } from 'svelte'
import GenericSkeletonLoader from '../../../components/loader/GenericSkeletonLoader.svelte';

let stores = getContext('trustStores')
let metadata = stores.metadata

let projectImage, loaded

onMount(() => {
    projectImage.onload = () => {
      loaded = true
    }
}) 
</script>


<GenericSkeletonLoader show={loaded}>
    <img class="object-cover max-h-96 w-full" bind:this={projectImage} src="{$metadata?.image}" />
</GenericSkeletonLoader>
