<script>
// svelte
import Router from 'svelte-spa-router'
import { fade } from 'svelte/transition'

// layout
import Main from './layout/Main.svelte'
import Header from './layout/header/Header.svelte'
import Footer from './layout/footer/Footer.svelte'
import GetStarted from './layout/GetStarted.svelte'

// routes
import Home from './views/Home.svelte'
import Project from './views/project/Project.svelte'
import Seed from './views/listings/Seed.svelte'
import Fund from './views/listings/Fund.svelte'
import Claim from './views/listings/Claim.svelte'
import Create from './views/create/Create.svelte'
import Upcoming from './views/listings/Upcoming.svelte'
import UpcomingProject from './views/project/upcoming/UpcomingProject.svelte'
import NewProject from './views/create/NewProject.svelte'
import UserGuide from './views/listings/UserGuide.svelte'
import KYCadmin from './views/kyc-admin/KYCadmin.svelte'
import KYC from './views/kyc/KYC.svelte'

// components
import Modal from './components/modals/Modal.svelte'
import InlineNotification from './components/notifications/InlineNotification.svelte'
import MobileMessage from './layout/MobileMessage.svelte'

// stores
import { incorrectChain, account, providerSet, connectModal, isMM  } from './stores/Account.js'
import { constants } from './constants/Constants'
import { site } from './stores/Site'

let routes = {}

routes = {
    // Using named parameters, with last being optional
    '/project/:address': Project,

    // Using named parameters, with last being optional
    '/upcoming/:id': UpcomingProject,

    '/': Home,
    '/upcoming': Upcoming,
    '/create': Create,
    '/seed': Seed,
    '/fund': Fund,
    '/claim': Claim,

    // Catch-all
    // This is optional, but if present it must be the last
    // '*': NotFound,
}

if ($site == 'polygen' || $site == 'polygenProd'){
    routes = {...routes, ...{
                                '/guide': UserGuide,
                                '/new': NewProject,
                                '/kyc-admin' : KYCadmin,
                                '/kyc' : KYC
                            }
    }
}

/*
const routes = {

    // Using named parameters, with last being optional
    '/project/:address': Project,

    // Using named parameters, with last being optional
    '/upcoming/:id': UpcomingProject,

    '/': Home,
    '/seed': Seed,
    '/fund': Fund,
    '/claim': Claim,
    '/upcoming': Upcoming,
    '/create': Create,
    '/guide': UserGuide,
    '/new': NewProject,
    '/kyc-admin' : KYCadmin,
    '/kyc' : KYC,

    // Catch-all
    // This is optional, but if present it must be the last
    // '*': NotFound,
}
*/

let isDesktop

if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
  // true for mobile device
  isDesktop = false
}else{
  // false for not mobile device
  isDesktop = true
}

</script>

<!-- routes -->
<div class="flex flex-col min-h-screen">
    {#if isDesktop}
        <Header />
        {#if $providerSet}
        <Router {routes} />
        {:else if $isMM === -1}
        <GetStarted />
        {:else}
        <div class="flex-grow"></div>
        {/if}
        <Footer />
    {:else if !isDesktop}
        <MobileMessage />
    {/if}
</div>

<!-- show a notice if the network is incorrect -->
{#if $incorrectChain}
<div class="modal-wrap" transition:fade="{{duration: 100}}">
  <Modal>
    <InlineNotification type="error">
        Incorrect chain - please switch to the {constants.NETWORK_NAME} network.
    </InlineNotification>
  </Modal>
</div>
{/if}

{#if $connectModal}
<Modal on:close={()=>{$connectModal = false}}>
    <div class="gap-y-4 p-2 flex flex-col w-80">
        <div class="text-lg text-gray-800 font-semibold">Connect wallet</div>
        <button 
            on:click={account.connectWeb3} 
            class="items-center justify-left hover:scale-105 transform transition-transform font-gray-600 flex flex-row gap-x-3 p-2 border border-gray-300 rounded-md"
        >
            <img class="w-10" src="./images/metamask-fox.svg" />
            <span class="font-regular text-gray-600">Metamask</span>
        </button>
    </div>
</Modal>
{/if}

<!-- {#if $connectWallet}

{/if} -->

<style global lang="postcss">
    @tailwind base;
    @tailwind components;
    @tailwind utilities;

    @layer base {
        body {
            font-family: Poppins, sans-serif;
            font-weight: 300;
            @apply bg-gray-10;
        }
        
        .active-nav {
            @apply block leading-none py-4 px-9 bg-gray-900 text-white rounded-3xl;
        }

        .inactive-nav {
            text-decoration: underline;
            @apply block leading-none py-4 px-9 hover:bg-gray-100 rounded-3xl;
        }
    }
</style>