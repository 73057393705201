<script>
import { getContext } from "svelte"
import GenericSkeletonLoader from "../loader/GenericSkeletonLoader.svelte"

import Pill from "../Pill.svelte"
import RewardItem from "./RewardItem.svelte"

$: ({
    metadata,
    myBalances,
    myTier,
    redeemableCurrency
} = getContext('trustStores'))

</script>

<GenericSkeletonLoader show={$myTier}>
    <div class="flex flex-row gap-x-4">
        <div class="flex flex-col space-y-2">
            <div class="text-2xs font-medium text-gray-400 uppercase">Your tier</div>
            {#if $myTier === -1}
                <div class="text-sm">You are not eligible for any tier rewards on this project.</div>
            {:else}
                <div>
                    <div class="text-2xl font-semibold text-gray-900">{$myTier?.title}</div>
                    <div class="text-lg font-medium text-gray-800">
                        {$myTier?.tokens_to_hold}+ {$redeemableCurrency?.symbol} 
                        {#if $myBalances?.redeemableBalance}
                            (You have {$myBalances?.redeemableBalance.toSignificant(4)})
                        {/if}
                    </div>
                </div>
            {/if}
        </div>
    </div>
</GenericSkeletonLoader>
