<script lang="ts">
import { Price } from '@uniswap/sdk-core';

// modules
import { getContext } from 'svelte'

// util
import { bnToFourDecimal, tokenDiv } from '../../utils/utils'

// get stores
$: ({
    contractsReadOnly : {
        trust : trustReadOnly,
        crp : crpReadOnly,
        redeemableToken : redeemableToken,
        pool : pool,
    },
    trustConfig,
    trustBounds,
    initRedemptionValue,
    seeder,
    seederUnits,
    creator,
    redeemInit,
    finalWeight,
    redeemableSupply,
    projectState
} = getContext('trustStores'))

// creator
async function setCreator() {
    $creator = await $trustReadOnly.creator()
}

// seeder info
async function setSeederConfig() {
    $seeder = await $trustReadOnly.seeder()
    $seederUnits = await $trustReadOnly.seederUnits()
}

$: if ($trustReadOnly) {
    setCreator()
    setSeederConfig()
}

// get initial redemption value
async function getInitRedemptionValue() {
    $initRedemptionValue = new Price({baseAmount: $redeemableSupply, quoteAmount: $redeemInit})
}

$: if ($redeemableToken && $redeemableSupply && $redeemInit && ($projectState == 2 || $projectState == 3)) {
    getInitRedemptionValue()
}

async function getFinalWeight() {
    $finalWeight = await $pool?.finalWeight?.() // FIXME: remove typescript optional chaining when balancer version updated
}

$: if ($pool) {
    getFinalWeight()
}
</script>