<script>
import GenericSkeletonLoader from "../../../components/loader/GenericSkeletonLoader.svelte";

export let value = null
export let label = null
export let show = null

</script>

<GenericSkeletonLoader show={show} flexGrow=true>
<div class="bg-white p-5 shadow-sm border border-gray-200 relative rounded-2xl flex-1 font-medium space-y-4">
        <div class="text-xs text-gray-400 uppercase mb-2 leading-none">{label}</div>
        <div class="text-base leading-none font-normal">{value}</div>
</div>
</GenericSkeletonLoader>