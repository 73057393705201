<script>
// svelte
import { getContext } from 'svelte'

// components
import BigBar from '../../../components/progressbar/BigBar.svelte'
import BigLabelledVal from './../../../components/labelledvalues/BigLabelledVal.svelte'
import GenericSkeletonLoader from './../../../components/loader/GenericSkeletonLoader.svelte'
import EndRaise from './EndRaise.svelte'
import ReserveTokens from '../components/ReserveTokens.svelte'

// util
import { timeString } from '../../../utils/utils'

import { site } from '../../../stores/Site'
import MyTier from '../../../components/tiers/MyTier.svelte'

import { lastBlockReq, blockSpeed } from '../../../stores/BlockSpeed.js'
import HoverTooltip from '../../../components/tooltips/HoverTooltip.svelte'
import IconLibrary from '../../../components/icons/IconLibrary.svelte';

$: ({
    spotPrice,
    topHolders,
    timeRemaining,
    projectState,
    successBalance,
    amountRaised,
    redeemableSupply,
    reserveCurrency,
    redeemableCurrency,
    poolBalanceRedeemable,
    myTier,
    trustBounds
} = getContext('trustStores'))

// props for the progress bar and label
let progressBarProps
$: if ($amountRaised && $successBalance && $reserveCurrency.symbol) {
    progressBarProps = {
        label : 'Amount raised',
        value : $amountRaised.toSignificant(10) + ' of ' + $successBalance.toSignificant(10) + ' ' + $reserveCurrency.symbol,
        color : 'green',
        total : $successBalance.toSignificant(8),
        progress : $amountRaised.toSignificant(8)
    }
}

// stats
let stats = []
$: if ($redeemableCurrency.symbol && $redeemableSupply && $poolBalanceRedeemable && $spotPrice && $topHolders) {
    stats = [
        {['total ' + $redeemableCurrency.symbol] : $redeemableSupply.toSignificant(7)},
        {'available' : $poolBalanceRedeemable.toSignificant(7)},
        {'current spot' : $spotPrice.toSignificant(5)},
        {'funders' : $topHolders.length},
    ]
}

// $: console.log($redeemableCurrency.symbol, $redeemableSupply, $poolBalanceRedeemable, $spotPrice, $topHolders)
// show the time remaining
$: timeLeft = timeString($timeRemaining, "wdhm")

// expand the text
let extraInfo
function revealInfo() { extraInfo = !extraInfo}

</script>

<div class="w-full gap-y-7 border border-gray-200 bg-white filter rounded-2xl shadow-md p-5 flex flex-col">

    <GenericSkeletonLoader show={progressBarProps}>
        <BigBar {...progressBarProps}/>
    </GenericSkeletonLoader>

    {#if $projectState === 3}
        <EndRaise />
    {:else}
        <GenericSkeletonLoader show={$timeRemaining !== null}>
            <div class="flex flex-row space-x-2">
                <!-- <div class="w-5 h-5 bg-red-400 animate-pulse rounded-full"></div> -->
                <div class="flex flex-col space-y-2">
                    <div>
                        <span class="text-gray-700">
                            This raise ends at block:
                        </span>  
                        <span class="text-gray-700 font-medium">
                            {$trustBounds?.endBlock}
                        </span> 
                    </div>
                    <div>
                        <span class="text-gray-700">
                            Approx. blocks elapsed:
                        </span>  
                        <span class="text-gray-700 font-medium">
                            {$lastBlockReq?.block - $trustBounds?.startBlock} ({(($lastBlockReq?.block - $trustBounds?.startBlock) / ($trustBounds?.endBlock - $trustBounds?.startBlock)*100)?.toFixed(2)}%)
                        </span> 
                    </div>
                    <div>
                        <span class="text-gray-700">
                            Approx. blocks remaining:
                        </span>  
                        <span class="text-gray-700 font-medium">
                            {$trustBounds?.endBlock - $lastBlockReq?.block}
                        </span> 
                    </div>
                    <div>
                        <span class="text-gray-700">
                            Estimated time remaining:
                        </span>  
                        <span>
                            {#if $timeRemaining > 0}
                            <span class="text-gray-700 font-medium">
                                {timeLeft}
                            </span>
                            <span>
                                 (~{$blockSpeed} seconds per block)
                            </span>
                                <HoverTooltip>
                                    <IconLibrary color="text-gray-500" inline width=13 icon="info"/>
                                    <span slot="tip">As block speed can vary, the exact timing for this raise can only be estimated.</span>           
                                </HoverTooltip>                    
                            {:else} 
                                This raise is about to end.
                            {/if}
                        </span> 
                    </div>  
                </div>
            </div>    
        </GenericSkeletonLoader> 
    {/if}

    <GenericSkeletonLoader show={stats}>
        <div class="flex flex-row space-x-2 items-stretch justify-between">
                {#each stats as stat, index}
                    <div class="">
                        <BigLabelledVal label={Object.keys(stat)[0]} value={stat[Object.keys(stat)[0]]} />
                    </div>
                    {#if index !== stats.length - 1}
                        <div class="border-r border-gray-200"></div>
                    {/if}
                {/each}
        </div>
    </GenericSkeletonLoader>
    {#if $myTier}
        <div class="border-t border-gray-200 pt-4">
            <MyTier />
        </div>
    {/if}

    {#if (!$site == 'polinate')}
        <ReserveTokens />
    {/if}
</div>