<script>
export let column
export let of

</script>

{#if column == 0}
    <div class="flex-1">
        <slot></slot>
    </div>
{:else if column == of}
    <div class="flex-1 text-right">
        <slot></slot>
    </div>
{:else}
    <div class="flex-1 text-left">
        <slot></slot>
    </div>
{/if}