import { utils, ethers } from 'ethers';
import { signer } from '../../stores/Account';
import { get } from 'svelte/store';
import { constants } from '../../constants/Constants';
import trustJson from '../../abi/Trust.json';
import trustFactoryJson from '../../abi/TrustFactory.json';
function linkLibraries({ bytecode, linkReferences, }, libraries) {
    Object.keys(linkReferences).forEach((fileName) => {
        Object.keys(linkReferences[fileName]).forEach((contractName) => {
            if (!libraries.hasOwnProperty(contractName)) {
                throw new Error(`Missing link library name ${contractName}`);
            }
            const address = utils.getAddress(libraries[contractName]).toLowerCase().slice(2);
            linkReferences[fileName][contractName].forEach(({ start: byteStart, length: byteLength }) => {
                const start = 2 + byteStart * 2;
                const length = byteLength * 2;
                bytecode = bytecode
                    .slice(0, start)
                    .concat(address)
                    .concat(bytecode.slice(start + length, bytecode.length));
            });
        });
    });
    return bytecode;
}
export async function deployTrust(trustConfig, redeemableConfig, poolConfig) {
    // const trustFactory = TrustFactory__factory.connect(constants.FACTORY_ADDRESS, get(signer))
    const trustFactory = new ethers.Contract(constants.FACTORY_ADDRESS, trustFactoryJson.abi, get(signer));
    const trust = await trustDeploy(trustFactory, get(signer), trustConfig, redeemableConfig, poolConfig);
    return trust;
}
export const trustDeploy = async (trustFactory, creator, ...args) => {
    const tx = await trustFactory["createChild((address,uint256,address,uint256,uint16,uint16,uint256,(string,string)),((string,string),address,uint8,uint256),(address,uint256,uint256,uint256,uint256))"](...args);
    const receipt = await tx.wait();
    const trust = new ethers.Contract(ethers.utils.hexZeroPad(ethers.utils.hexStripZeros(receipt.events?.filter((x) => x.event == "NewContract" && x.address.toUpperCase() == trustFactory.address.toUpperCase())[0].topics[1]), 20 // address bytes length
    ), trustJson.abi, creator);
    if (!ethers.utils.isAddress(trust.address)) {
        throw new Error(`invalid trust address: ${trust.address} (${trust.address.length} chars)`);
    }
    console.log(trust);
    await trust.deployed();
    return trust;
};
