<script>
import { getContext, onMount } from 'svelte'
import GenericSkeletonLoader from '../../../components/loader/GenericSkeletonLoader.svelte';

let stores = getContext('trustStores')
let metadata = stores.metadata

let projectImageVideo, loaded

onMount(() => {
  projectImageVideo.onload = () => {
      loaded = true
    }
}) 
</script>


<GenericSkeletonLoader show={loaded}>
  {#if $metadata?.media && $metadata?.media?.cover_type}
   {#if $metadata?.media?.cover_type === "video"}
    <iframe bind:this={projectImageVideo} src="{$metadata?.media?.cover_url}" title="" class="object-cover max-h-96 w-full" height="400" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="">
    </iframe>
  {:else}
    <img class="object-cover max-h-96 w-full" bind:this={projectImageVideo} src="{$metadata?.media?.cover_url}" />
  {/if}
  {:else}
    <img class="object-cover max-h-96 w-full" bind:this={projectImageVideo} src="{$metadata?.image}" />
  {/if}
</GenericSkeletonLoader>
