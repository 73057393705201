<script>
export let alt = false

</script>


{#if !alt}
    <div class="bg-gray-100 text-gray-700 rounded-lg flex flex-row justify-between p-2 w-full text-sm">
        <slot></slot>
    </div>
{:else}
    <div class="rounded-lg text-gray-700 flex flex-row justify-between p-2 w-full text-sm">
        <slot></slot>
    </div>
{/if}
