<script>
import SubHeader from './../../layout/header/SubHeader.svelte';
import ProjectList from '../../components/projectlist/ProjectList.svelte'
import FundCard from './fund/FundCard.svelte'
import Main from '../../layout/Main.svelte'
import LoadingAnim from '../../components/util/LoadingAnim.svelte';

import { curation, site } from '../../stores/Site'
import {constants} from '../../constants/Constants'

let tags = ['Music', 'NFT', 'Writing', 'Film']
let numberOfProjects, noProjects

</script>

<SubHeader>
    <div class="w-full pt-12">
        <div class="text-4xl font-medium text-gray-900 pb-8">
            Fund a project
        </div>
        {#if constants.HEADER_CONTENT}
            <div class="text-1xl font-regular text-gray-700 pb-8 prose">
                {constants.HEADER_CONTENT.fund}
            </div> 
        {/if}
        <!-- <div class="">
            <Tags tags={tags}/>
        </div> -->
    </div>
</SubHeader>

<Main>
    {#if noProjects}
        <div class="text-gray-600 flex-grow flex flex-col justify-center items-center h-full">
            {#if ($site == 'polinate' || $site == 'polinateProd')}
                There are no projects for trading at this time. Please visit again soon!
            {/if}
            {#if ($site == 'polygen' || $site == 'polygenProd')}
                There are no projects for trading at this time. Please visit again soon!
            {/if}
        </div>
    {:else if $curation}
        <div class="w-full hidden grid-cols-3 gap-5 pt-7" class:showGrid={numberOfProjects || $curation.comingSoonProjects}>
            <ProjectList projects={$curation.curatedProjects} bind:noProjects bind:numberOfProjects statesToFilterBy={[3, 2]}>
                <FundCard />
            </ProjectList>
            <!-- {#each $curation.comingSoonProjects as project}
                <ComingSoonProjectCard project={project}/>
            {/each} -->
        </div>
        <div class="flex-grow flex flex-col justify-center items-center h-full" class:hideLoader={numberOfProjects || $curation.comingSoonProjects}>
            <LoadingAnim width='200px' />
        </div>
    {/if}
</Main>

<style>
    .showGrid {
        @apply grid;
    }

    .hideLoader {
        @apply hidden;
    }
</style>
