import { account } from './../stores/Account.js';
import '../utils/kyc';
import { constants } from '../constants/Constants';
let connected_wallet_session_id = null;
let is_kyc_application_started = false;
let is_kyc_application_incomplete = false;
let is_kyc_application_under_review = false;
let is_kyc_application_validated = false;
let is_kyc_application_rejected = false;
let kyc_rejection_reasons = [];
export const KYC_METHODS = {
    InitNewKYCSession: 'kyc',
    GetSessionBySessionId: 'session_by_id',
    GetSessionOverviewBySessionId: 'session_overview_by_id',
    GetSessionsByAlias: 'sessions_by_alias',
    GetSessionsBySessionStatus: 'sessions_by_state',
    GetSessionStatuses: 'session_statuses'
};
export const KYC_SESSION_STATUSES = {
    PENDING: 'PENDING',
    CANCELLED: 'CANCELLED',
    VERIFIED: 'VERIFIED'
};
export const getNewKYCSession = async (account) => {
    let session_id = null;
    const request_url_new_session = constants.KYC_BASE_URL + KYC_METHODS.InitNewKYCSession + '/'
        + account + '?_client=' + constants.KYC_CLIENT_NAME + '&_env=' + constants.KYC_ENVIRONMENT;
    const newSessionResponse = await fetch(request_url_new_session);
    const newSession = await newSessionResponse.json();
    session_id = newSession.session_id;
    return session_id;
};
export const getKYCSessionIdForAccount = async (account) => {
    const request_url_current_sessions = constants.KYC_BASE_URL + KYC_METHODS.GetSessionsByAlias
        + '/' + account + '?_client=' + constants.KYC_CLIENT_NAME + '&_env=' + constants.KYC_ENVIRONMENT;
    const sessionJSON = await fetch(request_url_current_sessions);
    const accountSessions = await sessionJSON.json();
    if (accountSessions.length > 0) {
        connected_wallet_session_id = accountSessions[accountSessions.length - 1].session_id;
    }
    else {
        connected_wallet_session_id = await getNewKYCSession(account);
    }
    return connected_wallet_session_id;
};
export const getKYCSessionDetailsById = async (sessionId) => {
    const request_url_current_sessions = constants.KYC_BASE_URL + KYC_METHODS.GetSessionsByAlias
        + '/' + account + '?_client=' + constants.KYC_CLIENT_NAME + '&_env=' + constants.KYC_ENVIRONMENT;
    let request_url_session_by_id = constants.KYC_BASE_URL + KYC_METHODS.GetSessionBySessionId;
    const currentSession = await sessionByIdResponse.json();
};
export const setKYCSessionStatus = async (account) => {
    let kycSessionOverview = null;
    connected_wallet_session_id = await getKYCSessionIdForAccount(account);
    is_kyc_application_started = false;
    is_kyc_application_incomplete = false;
    is_kyc_application_under_review = false;
    is_kyc_application_validated = false;
    is_kyc_application_rejected = false;
    kyc_rejection_reasons = [];
    if (connected_wallet_session_id) {
        let request_url_session_overview_by_id = constants.KYC_BASE_URL + KYC_METHODS.GetSessionOverviewBySessionId + '/'
            + connected_wallet_session_id + '?_client=' + constants.KYC_CLIENT_NAME + '&_env=' + constants.KYC_ENVIRONMENT;
        const sessionByIdResponse = await fetch(request_url_session_overview_by_id);
        kycSessionOverview = await sessionByIdResponse.json();
        if (kycSessionOverview && kycSessionOverview.steps != undefined) {
            //Possible statuses: NOT_STARTED, SUBMITTED, VALIDATED, REJECTED
            if ((kycSessionOverview.steps.filter(validation_step => (validation_step.state === "PENDING")).length > 0)
                ||
                    (kycSessionOverview.steps.filter(validation_step => (validation_step.state === "SUBMITTED")).length > 0)) {
                is_kyc_application_under_review = true;
            }
            is_kyc_application_started = (kycSessionOverview.steps.filter(validation_step => (validation_step.state === "NOT_STARTED")).length == kycSessionOverview.steps.length);
            is_kyc_application_validated = (kycSessionOverview.steps.filter(validation_step => (validation_step.state === "VALIDATED")).length == kycSessionOverview.steps.length);
            is_kyc_application_rejected = (kycSessionOverview.steps.filter(validation_step => (validation_step.state === "REJECTED")).length > 0);
            is_kyc_application_incomplete = ((kycSessionOverview.steps.filter(validation_step => (validation_step.state === "NOT_STARTED")).length > 0)
                &&
                    (kycSessionOverview.steps.filter(validation_step => (validation_step.state != "NOT_STARTED")).length > 0));
            kyc_rejection_reasons = (kycSessionOverview.steps.filter(validation_step => (validation_step.state === "REJECTED" && validation_step.history.length > 0))).map(rejected_step => rejected_step.history[0].review_message);
        }
        return [kycSessionOverview, is_kyc_application_started, is_kyc_application_incomplete, is_kyc_application_under_review, is_kyc_application_validated,
            is_kyc_application_rejected, kyc_rejection_reasons, account];
    }
};
