<script>
import { constants } from "../../constants/Constants"
import Jazzicon from "../user/Jazzicon.svelte"
export let currency
export let width = 32

$: token = constants.tokenIcons.find(token=>{
    return token.address?.toUpperCase() === currency.address?.toUpperCase()
})
</script>

{#if token}
    <img src="{token.icon}" loading="lazy" alt="" style="width:{width}px; height:{width}px" class="swap-token-image">
{:else}
    <Jazzicon address={currency?.address} width=32 />
{/if}
