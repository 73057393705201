<script>
import GenericSkeletonLoader from './../../../components/loader/GenericSkeletonLoader.svelte'
import { getContext } from "svelte"

import MultiSeeder from "./MultiSeeder.svelte"
import UserSeed from "./UserSeed.svelte"

let stores = getContext("trustStores")
let seederContractReadOnly = stores.contractsReadOnly.seeder
let userSeed = stores.userSeed

$: show = ($seederContractReadOnly || $userSeed) ? true : false

</script>

<div class="w-full space-y-8 border border-gray-200 bg-white filter rounded-2xl shadow-md p-5 flex flex-col">
    <div class="text-gray-900 font-medium text-2xl">Seed this raise</div>

    <GenericSkeletonLoader show={$seederContractReadOnly || $userSeed}>
        <div class="min-h-32">
            {#if $seederContractReadOnly}
                <MultiSeeder />
            {:else if $userSeed}
                <UserSeed />
            {/if}
        </div>
    </GenericSkeletonLoader>
</div>