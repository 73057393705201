<script>
import { createEventDispatcher } from "svelte";

// Event dispatcher
const dispatch = createEventDispatcher();

// Dispatch close event for the modal
function closeModal() {
  dispatch("close");
}

export let slippage = null
export let value = 0

$: active = slippage == value ? true : false
$: displayVal = parseFloat(value).toFixed(1) + "%"

</script>

<div 
    class={active ? 'p-2 text-sm cursor-pointer rounded-md border border-gray-200 bg-gray-100' : 'text-sm p-2 cursor-pointer rounded-md border border-gray-200'}
    on:click={()=>{slippage=value}}
    >
    {displayVal}
    </div>