import "constants";
import { ethers } from "ethers";
import colors from 'tailwindcss/colors';
// Balancer rights manager library address
export const RIGHTS_MANAGER = '0x4aCB6685da2B5FcB29b1614E71825CE67464440b';
export const CRP_FACTORY = '0xA3F9145CB0B50D907930840BB2dcfF4146df8Ab4';
export const BALANCER_FACTORY = '0x39D7de7Cf0ad8fAAc56bbb7363f49695808efAf5';
// block explorer base path
export const BLOCK_EXPLORER_TRANSACTION = 'https://explorer-mumbai.maticvigil.com/tx/';
export const BLOCK_EXPLORER_ADDRESS = 'https://explorer-mumbai.maticvigil.com/address/';
export const RESERVE_DEFAULT = '0x25a4Dd4cd97ED462EB5228de47822e636ec3E31A'; // USDC
// chain
export const CHAIN_ID = 80001; // mumbai
export const NETWORK_NAME = "Mumbai";
// factory
export const FACTORY_ADDRESS = '0x48087b53c0B0AF584Fde72192f96a6267Df776C0'; // old live with beta kyc users
//export const FACTORY_ADDRESS = '0xDA36099e359CfA5a9883552DB48F97fe1DDb45e7'
// covalent api key
export const COVALENT_KEY = 'ckey_7e3942e77eb947f7ab1ad48a3ca';
export const PAGE_LENGTH = 999999; // historical log pagination - number of blocks
// graphQL api
export const GRAPH_API_URL = "https://api.thegraph.com/subgraphs/name/vishalkale151071/rain-protocol";
// Tier
export const TIER_DEFAULT = '0xe5b844041dD1CB2f680962A14DED1Fa16aE4DEA9'; // a default Tier for the Create page
export const MIN_TIER_MESSAGE = 'You do not have the minimum tier to make a swap.';
export const logo = "./images/polygen-logo-dapp.svg";
export const logoReversed = "./images/polygen-logo-reversed.svg";
export const favicon = "./images/favicon.png";
export const webclip = "./images/webclip.png";
export const siteName = "Default";
export const siteSub = "A default site";
export const BONE = ethers.BigNumber.from('000000000000000000');
export const curationContent = './curation/polygen.json';
export const tokenIcons = [
    {
        address: "0x25a4Dd4cd97ED462EB5228de47822e636ec3E31A",
        icon: "/images/usdc-symbol.png"
    }
];
// theme for tailwind
export const theme = {
    extend: {
        boxShadow: {
            'md': '0 4px 6px 2px rgba(0, 0, 0, 0.02), 0 2px 4px -1px rgba(0, 0, 0, 0.02)',
        },
        borderRadius: {
            '4xl': '1.9rem',
        },
        fontSize: {
            '2xs': '0.7rem',
        },
        minHeight: theme => theme('height'),
        colors: {
            // green: colors.lime,
            gray: { 10: '#FCFCFC', ...colors.trueGray },
            primary: {
                100: '#F6F6FE',
                200: '#E8E8FD',
                300: '#D1D1FA',
                400: '#BEBFF8',
                500: '#A5A6F6',
                600: '#7879F1',
                700: '#5D5FEF',
                800: '#4042D4',
                900: '#2D2FB9'
            }
        },
        keyframes: {
            'remove-400': {
                '0%': { display: 'block' },
                '100%': {
                    display: 'none'
                },
            }
        },
        animation: {
            'remove-400': 'remove-400 0.4s forwards',
        }
    },
};
