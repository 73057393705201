<script>
import { Fraction } from '@uniswap/sdk-core';
import { ethers } from 'ethers'
import { createEventDispatcher, onMount } from 'svelte'
import { currencyAmountFromValue } from '../../utils/utils'
import TokenIcon from './TokenIcon.svelte';

const BN = ethers.BigNumber
const dispatch = createEventDispatcher()

export let tokenSymbol = ""
export let maxAmt = new Fraction(BN.from(0))
export let label = null
export let estimated = false
export let tokenIcon
export let errorMsg
export let showBalance = true
export let currency
export let balanceExceeded
export let amount = new Fraction(BN.from(0))
export let fieldValue = 0
export let disabled = false

// set the field when the amount is changed from the parent
$: if (amount && estimated) { fieldValue = amount.toSignificant(8) }

// only check balance if this is the token being paid in
$: if (amount && maxAmt) { 
    balanceExceeded = (amount.greaterThan(maxAmt) && label=="From") ? true : false 
}

function setMax() {
    fieldValue = maxAmt.toSignificant(8)
    updateAmount(maxAmt)
}

export function clear() {
    createAmount('0')
    fieldValue = 0
}

function createAmount(value) {
    // pass the raw field value back up in case of swap inversion
    fieldValue = value
    // empty field is equal to zero
    value = value ? value : '0'
    amount = currencyAmountFromValue(currency, value)
    updateAmount(amount)
}

function updateAmount(amount) {
    dispatch('changed', {
        amount: amount,
        value: fieldValue
    })
}

// debouncing the above
let timer
const debounce = (v, f) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			f(v);
		}, 300);
	}
</script>

<div class="p-4 flex flex-col space-y-3 filter shadow-sm bg-white border border-gray-200 rounded-2xl" class:balanceExceeded class:disabled>

    <div class="text-sm text-gray-600">
        {label}
        {#if estimated} (estimated){/if}
    </div>

    <div class="flex flex-row justify-end items-center">

        <input 
            value={fieldValue}
            on:input={({target: { value }}) => debounce(value, createAmount)}
            on:focus={(event)=>{
                const {target: {value}} = event
                event.target.value = value === "0" ? "" : value 
            }}
            type="number"
            class="text-3xl flex-grow font-light min-w-0 outline-none appearance-none"
            {disabled}
        >

        {#if maxAmt}
        <button class="bg-gray-400 text-white text-xs p-1 rounded-md mx-2" {disabled} on:click={setMax}>MAX</button>
        {/if}

        <TokenIcon {currency} />

        <div class="text-3xl pl-3 font-light">{tokenSymbol}</div>
    </div>

    <div class="flex flex-row justify-between">

        {#if maxAmt && showBalance}
            <span class="text-sm text-gray-600">Balance {maxAmt.toSignificant(8)} {tokenSymbol}</span>
        {/if}

        {#if balanceExceeded}
            <span class="text-red-700 text-sm">Balanced exceeded</span>
        {/if}

        {#if errorMsg}
            <span class="text-red-700 text-sm">{errorMsg}</span>
        {/if}

    </div>  
</div>

<style>
    .balanceExceeded {
        @apply border-red-400
    }
    
    .disabled {
        @apply opacity-50 cursor-not-allowed
    }
</style>