<script>
import { getContext } from "svelte"
import TeamMember from "./TeamMember.svelte"

let stores = getContext('trustStores')
$: ({
    metadata
} = stores)

</script>

<div class="grid grid-cols-3 gap-x-4 gap-y-8 mt-4">
    {#each $metadata.team as member}
        <TeamMember member={member} />
    {/each}
</div>