<script lang='ts'>
// components
import ProjectMetadata from './../../stores/project/ProjectMetadata.svelte'
import Project from './Project.svelte'
import StoresForFilter from './StoresForFilter.svelte'
import Contracts from '../../stores/project/Contracts.svelte'
import RaiseProgress from '../../stores/project/RaiseProgress.svelte'

// take the address for each project and attach an index and null state
export let projects
projects = projects.map((project, i) => {
    return {i: i, address: project.address, state: null}
})

let filters = []

// filter for the project state
export let statesToFilterBy
let stateFilter = project => {
    // filter if any of the states to filter by match the project state
    return statesToFilterBy.some(state => project.state == state)
}
$: if (statesToFilterBy) {
    filters.push(stateFilter)
}

// filter for the project address
export let addressToFilterBy
let addressFilter = project => project.address == addressToFilterBy
$: if (addressToFilterBy) {
    // filters.push(addressFilter)
}

let filteredProjects = []
// filter the projects by all the filters
$: filteredProjects = projects.filter((project) => {
        return filters.every(filter => filter(project))
    })
// check that every project has a state calculated, if there's still
// no filtered projects we can say none have been found
export let noProjects = true
$: noProjects = projects.every(project => project.state !== null) && !numberOfProjects

// number of projects is passed up
export let numberOfProjects
$: numberOfProjects = filteredProjects.length
</script>

<!-- initialise the projects with the minimum number of stores, and pass the states back up to be filtered -->
{#each projects as project, index (project.i)}
<StoresForFilter bind:stores={projects[index].stores} bind:state={projects[index].state} address={project.address}>
    <Contracts />
    <RaiseProgress />
    <ProjectMetadata />
</StoresForFilter>
{/each}

<!-- take the filtered id's and match those to the main projects array that was passed up above-->
{#if filteredProjects.length}
    {#each filteredProjects as filteredProject (filteredProject.i)}
        <div>
            <Project stores={projects.find(project => project.i === filteredProject.i).stores}>
                <slot></slot>
            </Project>
        </div>
    {/each}
{/if}