<script>
import TokenIcon from './TokenIcon.svelte'

export let tokenVal
export let tokenIcon
export let tokenSymbol
export let label
export let amount

</script>

<div class="border border-gray-200 rounded-2xl font-light space-y-4 p-5 filter shadow-sm">
  {#if label}
    <div class="text-sm text-gray-500">
      {label}
    </div>
  {/if}
  <div class="flex flex-row">
      <div class="text-3xl flex-grow">{tokenVal || amount.toSignificant(8)}</div>
      <div class="flex flex-row">
        <TokenIcon currency={amount.currency} />
        <div class="text-3xl ml-2">{tokenSymbol || amount.currency.symbol}</div>
      </div>
  </div>
</div>
