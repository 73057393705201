<script>
// modules
import { getContext } from 'svelte'
import { ethers } from 'ethers'

// stores
import { signer, provider, providerSet } from '../Account.js'

// ABIs
import TRUST from '../../abi/Trust.json'
import RESERVE_TOKEN from '../../abi/ERC20.json'
import REDEEMABLE from '../../abi/RedeemableERC20.json'
import POOL from '../../abi/RedeemableERC20Pool.json'
import BPOOL from '../../abi/BPool.json'
import CRP from '../../abi/ConfigurableRightsPool.json'

let stores = getContext('trustStores')

$: ({
    contractsReadOnly : {
        trust: trustReadOnly
    },
    contractsWithSigner : {
        trust: trustWithSigner
    },
    trustAddress,
    contractAddresses,
    projectState
} = stores)

// set the trust
$: if ($providerSet && $trustAddress) {setTrust('provider', stores.contractsReadOnly)}
$: if ($signer && $trustAddress) {setTrust('signer', stores.contractsWithSigner)}

// once the trust is set, get the other contracts
$: if ($trustReadOnly && $projectState !== null) {setContracts('provider', stores.contractsReadOnly)}
$: if ($trustWithSigner && $projectState !== null) {setContracts('signer', stores.contractsWithSigner)}

async function setTrust(_signerOrProvider, stores) {
    
    // set read only contracts (connected to provider), or with signer
    if (_signerOrProvider == 'provider') {
        _signerOrProvider = $provider
    } else {
        _signerOrProvider = $signer
    }
    
    // reset all the contracts to null first, so if we've changed directly from one project
    // route to another nothing downstream works until the contracts are init
    stores.trust.set(null)
    stores.pool.set(null)
    stores.reserveToken.set(null)
    stores.redeemableToken.set(null)
    stores.bPool.set(null)
    stores.crp.set(null)

    // trust contract
    var _trustContract = new ethers.Contract($trustAddress, TRUST.abi)  
    var trust = _trustContract.connect(_signerOrProvider)
    stores.trust.set(trust)
}

async function setContracts(_signerOrProvider, stores) {

    // set read only contracts (connected to provider), or with signer
    if (_signerOrProvider == 'provider') {
        _signerOrProvider = $provider
    } else {
        _signerOrProvider = $signer
    }

    // get the remaining addresses
    $contractAddresses = await $trustReadOnly.getContracts()
 
    // redeemable erc20 pool contract
    var _poolContract = new ethers.Contract($contractAddresses.redeemableERC20Pool, POOL.abi)
    var pool = _poolContract.connect(_signerOrProvider)
    stores.pool.set(pool)

    // reserve token contract
    var _reserveTokenContract = new ethers.Contract($contractAddresses.reserveERC20, RESERVE_TOKEN.abi)  
    var reserveToken = _reserveTokenContract.connect(_signerOrProvider)
    stores.reserveToken.set(reserveToken)

    // redeemable token contract
    var _redeemableTokenContract = new ethers.Contract($contractAddresses.redeemableERC20, REDEEMABLE.abi)
    var redeemableToken = _redeemableTokenContract.connect(_signerOrProvider)
    stores.redeemableToken.set(redeemableToken)

    // balancer pool contract -  doesn't exist on-chain before the raise has started
    if ($contractAddresses.pool !== ethers.constants.AddressZero) {
        var _bPoolContract = new ethers.Contract($contractAddresses.pool, BPOOL.abi)
        var bPool = _bPoolContract.connect(_signerOrProvider)
        stores.bPool.set(bPool)
    }

    // crp contract
    var _crpContract = new ethers.Contract($contractAddresses.crp, CRP.abi)
    var crp = _crpContract.connect(_signerOrProvider)
    stores.crp.set(crp)
}

</script>


