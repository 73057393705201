<script>
	import { CurrencyAmount, Price } from '@uniswap/sdk-core';
import { getContext } from 'svelte'

// stores
import { tick } from '../Tick'
import { provider, blockNumber, providerSet } from '../Account'

// util
import { bnToFourDecimal, tokenDiv, BN, timeDifference } from '../../utils/utils'

// constants
import { constants } from '../../constants/Constants'

$: ({
    // contracts
    contractsReadOnly : {
        redeemableToken: redeemableToken,
        bPool : bPool
    },
    // reserve and redeemable currencies
    reserveCurrency,
    redeemableCurrency,
    // trust bounds
    trustBounds,
    // store we're updating
    swapData,
} = getContext('trustStores'))

let key = constants.COVALENT_KEY
let swapTopicHash = '0x908fb5ee8f16c6bc9bc3690973819f32a4d4b10188134543c88706e0e1d43378' // topic hash  

// only need to grab logs from the start of the raise
let upToDateBlock
let queryParams = {}
let fetchInProgress = false
let allPages = []

$: if ($bPool && $providerSet && $reserveCurrency && $redeemableCurrency && $trustBounds && $tick) {
    // only set the initial block for the query once
    upToDateBlock = !upToDateBlock ? $trustBounds.startBlock : upToDateBlock
    getSwapData() 
}

async function getSwapData() {
    // don't proceed if there's a fetch loop in progress
    if (!fetchInProgress) {
        // loop in progress
        fetchInProgress = true

        // Get the lastest block
        $blockNumber = await $provider.getBlockNumber()
        let currentPage = 0
        let tries = 0
        
        async function loadPage() {

            // paginate the api calls
            queryParams.startingBlock = upToDateBlock + (currentPage * constants.PAGE_LENGTH)
            queryParams.endingBlock = queryParams.startingBlock + constants.PAGE_LENGTH
            queryParams.key = key
    
            // covalent accepts 'latest'
            if (queryParams.endingBlock > $blockNumber) {
                queryParams.endingBlock = 'latest'
            }

            // create the request...
            let url = 'https://api.covalenthq.com/v1/'
                + constants.CHAIN_ID
                + '/events/topics/'
                + swapTopicHash
                + '/?starting-block=' 
                + queryParams.startingBlock
                + '&ending-block='
                + queryParams.endingBlock
                + '&sender-address='
                + $bPool.address
                + '&key='
                + queryParams.key

            // ...fetch it
            fetch(url)
                .then(response => response.json())
                .then(async (data) => {
                    if (!data.error) {
                        // create an array of the decoded logs
                        let logs = data.data.items.map(async (log) => {

                            // map what we actually need from the logs
                            let cleanLog = {
                                blockNumber : log.block_height,
                                timestamp : Date.parse(log.block_signed_at),
                                transactionHash : log.tx_hash,
                                caller : log.decoded.params[0].value,
                                tokenIn : log.decoded.params[1].value,
                                tokenOut : log.decoded.params[2].value,
                            }
                                                      
                            if (cleanLog.tokenIn.toUpperCase() == $redeemableToken.address.toUpperCase()) {
                                const tokenAmountIn = CurrencyAmount.fromRawAmount($redeemableCurrency, BN.from(log.decoded.params[3].value))
                                const tokenAmountOut = CurrencyAmount.fromRawAmount($reserveCurrency, BN.from(log.decoded.params[4].value))
                                const redeemableAvgPrice = new Price({quoteAmount: tokenAmountOut, baseAmount: tokenAmountIn})

                                cleanLog = {
                                    ...cleanLog,
                                    tokenAmountIn,
                                    tokenAmountOut,
                                    tokenAmountInNum : tokenAmountIn.toSignificant(6),
                                    tokenAmountOutNum : tokenAmountOut.toSignificant(6),
                                    tokenPrice : Number(tokenAmountOut.toSignificant(6)/tokenAmountIn.toSignificant(6)).toFixed(6),
                                    tokenInSymbol : $redeemableCurrency.symbol,
                                    tokenOutSymbol : $reserveCurrency.symbol,
                                    redeemableAvgPrice,
                                    redeemableAvgPriceNum : redeemableAvgPrice.toSignificant(6),
                                }         
                            } else {
                                const tokenAmountIn = CurrencyAmount.fromRawAmount($reserveCurrency, BN.from(log.decoded.params[3].value))
                                const tokenAmountOut = CurrencyAmount.fromRawAmount($redeemableCurrency, BN.from(log.decoded.params[4].value))
                                const redeemableAvgPrice = new Price({quoteAmount: tokenAmountIn, baseAmount: tokenAmountOut})

                                cleanLog = {
                                    ...cleanLog,
                                    tokenAmountIn,
                                    tokenAmountOut,
                                    tokenAmountInNum : tokenAmountIn.toSignificant(6),
                                    tokenAmountOutNum : tokenAmountOut.toSignificant(6),
                                    tokenPrice : Number(tokenAmountIn.toSignificant(6)/tokenAmountOut.toSignificant(6)).toFixed(6),
                                    tokenOutSymbol : $redeemableCurrency.symbol,
                                    tokenInSymbol : $reserveCurrency.symbol,
                                    redeemableAvgPrice,
                                    redeemableAvgPriceNum : redeemableAvgPrice.toSignificant(6),
                                }
                            }
                            return cleanLog
                        })  

                        // append
                        logs = await Promise.all(logs).catch(error=>console.log(error))
                        allPages = [...allPages, ...logs]

                        // if this is the final call in the fetch loop
                        if (queryParams.endingBlock === 'latest') {
                            // stop the loop and set the store
                            fetchInProgress = false
                            // update the relative time for all the logs
                            allPages = allPages.map(log => {
                                log.relativeTime = timeDifference(Date.now(), log.timestamp)
                                return log
                            })
                            $swapData = allPages
                            // store the last block we now have data for
                            upToDateBlock = allPages.length ? allPages[allPages.length - 1].blockNumber + 1 : upToDateBlock
                        } else {
                            // otherwise callback
                            currentPage++
                            loadPage()
                        }
                    } else if (data.error) {
                        console.log(data.error_message)
                        if (tries < 5) {
                            setTimeout(()=>{loadPage()}, 100)
                            tries++
                        }
                    }
                }).catch(error=>{})
        }
        // start the fetch loop
        loadPage()
    }
}
</script>
