<script>
import IconLibrary from '../icons/IconLibrary.svelte'    
export let type
export let small

$: width = small ? 20 : 40

$: textClass = small ? "pl-2 text-sm" : "pl-4 text-sm"
$: wrapperClass = small ? "px-3 py-2" : "p-4"
</script>


{#if type=="warning"}
<div class={"w-full bg-yellow-100 border border-yellow-200 rounded-xl flex flex-row items-center " + wrapperClass}>
    <div class="flex-shrink-0">
        <IconLibrary icon="alert" width={width} color="text-yellow-900"/>
    </div>
    <div class={"text-yellow-900 leading-tight " + textClass}>
        <slot></slot>
    </div>
</div>
{:else if type=="error"}
<div class={"w-full bg-red-100 border border-red-200 rounded-xl flex flex-row items-center " + wrapperClass}>
    <div class="flex-shrink-0">
        <IconLibrary icon="alert" width={width} color="text-red-900"/>
    </div>
    <div class={"text-red-900 leading-tight " + textClass}>
        <slot></slot>
    </div>
</div>
{/if}