import { ethers } from "ethers";
import Verify from '@beehiveinnovation/rain-protocol/dist/e07af1be5703ebddd8faf546df1e98f23164c253/artifacts/contracts/verify/Verify.sol/Verify.json';
import VerifyTier from '@beehiveinnovation/rain-protocol/dist/e07af1be5703ebddd8faf546df1e98f23164c253/artifacts/contracts/tier/VerifyTier.sol/VerifyTier.json';
import { provider } from '../stores/Account.js';
import { get } from 'svelte/store';
import { constants } from '../constants/Constants';
import "constants";
import '../stores/Account.js';
export let on_chain_kyc_status = 0; //KYCStates.NOT_ADDED
export let on_chain_kyc_block = 0;
export let on_chain_kyc_timestamp = 0;
export let on_chain_kyc_status_timestamp_date = '';
export const reset = () => {
    on_chain_kyc_status = 0; //KYCStates.NOT_ADDED
    on_chain_kyc_block = 0;
    on_chain_kyc_timestamp = 0;
    on_chain_kyc_status_timestamp_date = '';
};
export const KYCStates = Object.freeze({
    NOT_ADDED: 0,
    ADDED: 1,
    APPROVED: 2,
    BANNED: 3
});
export function kycContracts(signer) {
    // Verify contract
    var verifyContract = new ethers.Contract(constants.VERIFY_ADDRESS, Verify.abi);
    // VerifyTier
    var verifyTierContract = new ethers.Contract(constants.VERIFY_TIER_DEFAULT, VerifyTier.abi);
    return [verifyContract.connect(signer), verifyTierContract.connect(signer)];
}
export function uuidToBytes(uuid) {
    return (uuid.replace(/-/g, '').match(/.{2}/g) || []).map((b) => parseInt(b, 16));
}
export const getKYCStateLabel = (state) => {
    switch (state) {
        case KYCStates.NOT_ADDED: return 'Not added';
        case KYCStates.ADDED: return 'Added';
        case KYCStates.APPROVED: return 'Approved';
        case KYCStates.BANNED: return 'Banned';
    }
};
export const getContractMessageTranslation = (msg) => {
    if (msg) {
        if (msg.includes("PRIOR_ADD")) {
            return msg + ' ' + '> Wallet address is already submitted on chain';
        }
        else if (msg.includes("NOT_ADDED")) {
            return msg + ' ' + '> Wallet address is not submitted on chain yet';
        }
        else if (msg.includes("PRIOR_APPROVE")) {
            return msg + ' ' + '> Wallet address is already APPROVED';
        }
        else if (msg.includes("PRIOR_BAN")) {
            return msg + ' ' + '> Wallet address is already BANNED';
        }
        else if (msg.includes("ONLY_APPROVER")) {
            return msg + ' ' + '> Connected wallet address is not an authorised APPROVER';
        }
        else if (msg.includes("ONLY_BANNER")) {
            return msg + ' ' + '> Connected wallet address is not an authorised BANNER';
        }
        else if (msg.includes("ONLY_REMOVER")) {
            return msg + ' ' + '> Connected wallet address is not an authorised REMOVER';
        }
    }
    return msg;
};
export const getOnChainKYCStatus = async (signer, account) => {
    reset();
    let kycContract = kycContracts(signer)[0];
    if (kycContract) {
        let on_chain_kyc_state = await kycContract.state(account);
        let _provider = get(provider);
        let _currentBlock = await _provider.getBlockNumber();
        //let accountStatus = await kycContract.statusAtBlock(accountState, _currentBlock)
        if (on_chain_kyc_state.addedSince == 0) {
            on_chain_kyc_status = KYCStates.NOT_ADDED;
        }
        // Banned takes priority over everything.
        else if (on_chain_kyc_state.bannedSince <= _currentBlock) {
            on_chain_kyc_status = KYCStates.BANNED;
            on_chain_kyc_block = on_chain_kyc_state.bannedSince;
        }
        // Approved takes priority over added.
        else if (on_chain_kyc_state.approvedSince <= _currentBlock) {
            on_chain_kyc_status = KYCStates.APPROVED;
            on_chain_kyc_block = on_chain_kyc_state.approvedSince;
        }
        // Added is lowest priority.
        else if (on_chain_kyc_state.addedSince <= _currentBlock) {
            on_chain_kyc_status = KYCStates.ADDED;
            on_chain_kyc_block = on_chain_kyc_state.addedSince;
        }
        if (on_chain_kyc_block > 0) {
            const { timestamp } = await _provider.getBlock(on_chain_kyc_block);
            on_chain_kyc_timestamp = timestamp;
        }
    }
    if (on_chain_kyc_timestamp > 0) {
        on_chain_kyc_status_timestamp_date = '' + new Date(on_chain_kyc_timestamp * 1000);
    }
    return [on_chain_kyc_status, on_chain_kyc_block, on_chain_kyc_timestamp, on_chain_kyc_status_timestamp_date];
};
export const roles = [
    {
        role: 'Approver Admin',
        hash: ethers.utils.keccak256(ethers.utils.toUtf8Bytes("APPROVER_ADMIN"))
    },
    {
        role: 'Approver',
        hash: ethers.utils.keccak256(ethers.utils.toUtf8Bytes("APPROVER"))
    },
    {
        role: 'Remover Admin',
        hash: ethers.utils.keccak256(ethers.utils.toUtf8Bytes("REMOVER_ADMIN"))
    },
    {
        role: 'Remover',
        hash: ethers.utils.keccak256(ethers.utils.toUtf8Bytes("REMOVER"))
    },
    {
        role: 'Banner Admin',
        hash: ethers.utils.keccak256(ethers.utils.toUtf8Bytes("BANNER_ADMIN"))
    },
    {
        role: 'Banner',
        hash: ethers.utils.keccak256(ethers.utils.toUtf8Bytes("BANNER"))
    }
];
/**
 * Utility function that transforms a hexadecimal number from the output of the ITier contract report
 * @param report String with Hexadecimal containing the array data
 * @returns number[] Block array of the reports
 */
export function tierReport(report) {
    let parsedReport = [];
    let arrStatus = [0, 1, 2, 3, 4, 5, 6, 7]
        .map((i) => BigInt(report)
        .toString(16)
        .padStart(64, "0")
        .slice(i * 8, i * 8 + 8))
        .reverse();
    //arrStatus = arrStatus.reverse();
    for (let i in arrStatus) {
        parsedReport.push(parseInt("0x" + arrStatus[i]));
    }
    return parsedReport;
}
