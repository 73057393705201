<script>
import { getContext } from "svelte";

import FlashTooltip from './../../../components/tooltips/FlashTooltip.svelte';
import SimpleTableRow from './../../../components/simpletable/SimpleTableRow.svelte';
import Button from "../../../components/buttons/Button.svelte";
import SimpleTableHeading from "../../../components/simpletable/SimpleTableHeading.svelte";
import DisplayAddress from "../../../components/user/DisplayAddress.svelte";
import IconLibrary from '../../../components/icons/IconLibrary.svelte';
import InlineNotification from '../../../components/notifications/InlineNotification.svelte';
import LoadingAnim from "../../../components/util/LoadingAnim.svelte";

import { signer } from '../../../stores/Account';
import GenericSkeletonLoader from "../../../components/loader/GenericSkeletonLoader.svelte";
import SimpleTableCell from "../../../components/simpletable/SimpleTableCell.svelte";

let stores = getContext('trustStores')

$: ({
    // contracts
    contractsWithSigner : {
        trust : trust,
        redeemableToken : redeemableToken
    }, 
    contractAddresses,
    redeemableCurrency,
    redeemTokens,
    redeemBalances,
    getRedeemables,
    redeemCurrencies
} = stores)

let tokenAddress = ""
let tooltip, txStatus, errorMsg

function copyAddress() {
    navigator.clipboard.writeText($contractAddresses.redeemableERC20).then(()=>{
        tooltip.flash()
    })
}

function addRedeemable() {
    txStatus = 'waiting-on-signature'
    $redeemableToken.newTreasuryAsset(tokenAddress).then(async (result)=>{
        txStatus = 'verifying'
        await $signer.provider.waitForTransaction(result.hash, 2)   
        await $getRedeemables()
        txStatus = null
        tokenAddress = ''
    }).catch(error => {
        errorMsg = error.message
        if (error.data) {errorMsg = error.data.message}
        txStatus = "error"
    })
}

</script>

{#if !txStatus}
<div class="max-w-md flex flex-col space-y-4">
    <span class="text-lg font-semibold text-gray-900">Add to the reserve</span>

    <div class="text-gray-600">Already registered tokens can be added to the reserve by sending them to the {$redeemableCurrency.symbol} address:</div>
        
    <div class="relative flex-shrink self-start">
        {#if navigator.clipboard}
        <FlashTooltip bind:this={tooltip}>Copied!</FlashTooltip>
            <button on:click={copyAddress} class="bg-gray-100 text-gray-700 rounded-md flex flex-row py-1 px-2 space-x-2 items-center">
                <DisplayAddress address={$contractAddresses.redeemableERC20} />
                <IconLibrary width=15 inline=true icon="copy" color="text-gray-700" />
            </button>    
        {:else}
            <div class="bg-gray-100 text-gray-700 rounded-md flex flex-row py-1 px-2 space-x-2 items-center">
                {$contractAddresses.redeemableERC20}
            </div>
        {/if}
    </div>

    <div class="space-y-4 border-t border-gray-200 pt-4">

        <span class="font-semibold text-gray-900">Registered tokens</span>

        <div class="space-y-1">
            <GenericSkeletonLoader show={$redeemTokens && $redeemBalances} >
                <SimpleTableHeading left="TOKEN" right="RESERVE BALANCE" />
        
                {#if $redeemTokens && $redeemBalances}
                    {#each $redeemTokens as token, i}
                        <SimpleTableRow alt={i%2}>
                            <SimpleTableCell column=1 of=2>
                                {$redeemCurrencies[i]?.symbol}
                            </SimpleTableCell>
                            <SimpleTableCell column=2 of=2>
                                {$redeemBalances[i]?.toSignificant(8)}
                            </SimpleTableCell>
                        </SimpleTableRow>
                    {/each}
                {/if}
            </GenericSkeletonLoader>
        </div>
    
        <div class="flex flex-row justify-between items-center border rounded-lg border-gray-200 p-2">
            <input class="flex-grow outline-none mr-2" bind:value={tokenAddress} placeholder="Token address"/>
            <Button on:click={addRedeemable} class="text-sm" small=true label="Register new" />
        </div>

        <InlineNotification type="warning">
            Once a reserve token is registered, it can’t be unregistered. Ensure the token address is correct. There can be a maximum of eight tokens in the reserve.
        </InlineNotification>
    </div>
</div>

{:else if txStatus == "waiting-on-signature"}
  <div class="w-full flex flex-col items-center">
    <LoadingAnim />
    Waiting on signature...
  </div>
{:else if txStatus == "verifying"}
  <div class="w-full flex flex-col items-center">
    <LoadingAnim />
    Confirming Transaction...
  </div>
{:else if txStatus == "error"}
  <span class="tx-lg font-medium">Error</span>
  <InlineNotification type="error">{errorMsg}</InlineNotification>
{/if}