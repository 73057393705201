<script>
import { setContext } from 'svelte'

export let stores
setContext('trustStores', stores)

</script>


<slot></slot>
